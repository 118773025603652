
    angular.module('mso.authentication').controller('AuthenticationHeaderController',['$scope', 'ReferenceDataService', '$window', '$exceptionHandler',
    function ($scope, ReferenceDataService, $window, $exceptionHandler) {
        $scope.navbarCollapsed = true;

        // functions exposed to the view
        $scope.displayContactUs = displayContactUs;

        if (!$scope.parameters) {
            ReferenceDataService.getReferenceDataForView('#Header#').then(function (data) {
                $scope.parameters = data.parameters;
            }, function (error) {

                $exceptionHandler(error);
            });
        }

        function displayContactUs() {
            $window.open($scope.parameters.p6257, "Contact Us", "width=800,height=640,scrollbars,resizable",false);
        }

    }]);