(function () {
    'use strict';

    angular
        .module('mso.services')
        .service('FlagsService', FlagsService);

    FlagsService.$inject = ['Config', '$http', '$q'];

    /* @ngInject */
    function FlagsService(Config, $http, $q) {
        var flagsBaseUrl = Config.apiBaseHref + '/flags';
        var getAllFlagsResource = {
            href: flagsBaseUrl,
            method: "get"
        };

        var flags = [],
            flagPromise = null;

        return {
            ids: {
            },
            loadFlags: loadFlags,
            getFlag: getFlag,
            isEnabled: isEnabled
        };

        ////////////////

        function loadFlags(){
            if (flagPromise === null){
                flagPromise = $http({
                        url: getAllFlagsResource.href,
                        method: "get"
                    }).then(function (response) {
                        flags = response.data;
                    });
            }

            return flagPromise;
        }

        function getFlag(flagId){
            var flag = filterFlag(flagId);
            var deferred = $q.defer();

            if (flag === null){
                $http({
                    url: flagsBaseUrl + '/' + flagId,
                    cache: true,
                    method: "get"
                }).then(function (response) {
                    flags.push(response.data);
                    deferred.resolve(response.data);
                });
            } else {
                deferred.resolve(flag);
            }

            return deferred.promise;
        }

        function isEnabled(flagId){
            var flag = filterFlag(flagId);

            return flag === null ? false : flag.enabled;
        }

        function filterFlag(flagId) {
            var result = flags.filter(function (flag) {
                return flag.id === flagId;
            });

            return result.length === 1 ? result[0] : null;
        }
    }

})();

