(function() {
    'use strict';
    angular.module('mso').controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$scope', 'IntermediaryInfoService', 'UserPermissionsService', '$exceptionHandler', 'LockService', '$location', '$route', '$window', 'ReferenceDataService', 'LogoutService'];
    function HeaderController($scope, IntermediaryInfoService, UserPermissionsService, $exceptionHandler, LockService, $location, $route, $window, ReferenceDataService, LogoutService) {


        // functions exposed to the view
        $scope.viewIntermediaryDetails = viewIntermediaryDetails;
        $scope.viewAdministrators = viewAdministrators;
        $scope.setSecurityQuestions = setSecurityQuestions;
        $scope.logOff = logOff;
        $scope.exitCase = exitCase;
        $scope.displayContactUs = displayContactUs;

        if (!$scope.parameters) {
            ReferenceDataService.getReferenceDataForView('#Header#').then(function (data) {
                $scope.parameters = data.parameters;
            }, function (error) {

                $exceptionHandler(error);
            });
        };

        function displayContactUs() {
            $window.open($scope.parameters.p6257, "Contact Us", "width=800,height=640,scrollbars,resizable",false);
        }

        activate();

        function activate() {
            if (!angular.isDefined(window.sessionStorage.token)) {
                logOff();
                return;
            }

            $scope.userName = window.sessionStorage.username;
        }

        $scope.$on('$locationChangeSuccess', function (newLocation) {
            $scope.location = $location.path();
        });

        function viewIntermediaryDetails() {

            if ($scope.intermediary != undefined) {
                $window.location = getFullyQualifiedLocation('/sm/#!/view-details');
            } else {
                $scope.logOff();
            }
        }

        function viewAdministrators() {
            if ($scope.intermediary != undefined) {
                $window.location = getFullyQualifiedLocation('/sm/#!/set-administrators');
            } else {
                $scope.logOff();
            }
        }

        function setSecurityQuestions(){
            if ($scope.intermediary != undefined) {
                $window.location = getFullyQualifiedLocation('/sm/#!/set-security-questions');
            } else {
                $scope.logOff();
            }
        }

        function getFullyQualifiedLocation(redirectTo){
            var location = '';
            location += $location.protocol() + '://' + $location.host() + ':' + $location.port();

            if(redirectTo != undefined) {
                location += redirectTo;
            }

            return location;
        }

        function logOff() {

            LogoutService.logout().then(function () {

                        var caseId = null;
                        if ($route.current && $route.current.params && $route.current.params.caseId) {
                            caseId = $route.current.params.caseId;
                        }
                        if (caseId && angular.isDefined(window.sessionStorage.lockToken)) {
                            LockService.clearLock(window.sessionStorage.lockToken)
                                    .then(function () {

                                        // clear up any state we have about this case e.g. lock
                                        delete $window.sessionStorage.lockToken;
                                        delete $window.sessionStorage.token;
                                        delete $window.sessionStorage.intermediary;

                                        delete $window.sessionStorage.channel;
                                        delete $window.sessionStorage.brand;
                                        delete $window.sessionStorage.sourcePortal;
                                        delete $window.sessionStorage.anchorDate;

                                        delete $window.sessionStorage.resourceActions;

                                        //unlocked the case so return to log in screen
                                        $window.location = getFullyQualifiedLocation();
                                    },
                                    function (error) {
                                        $exceptionHandler(error);
                                    })
                        }
                        else {
                            // no case in context so must be on intermediary home page
                            //timeout may have occurred
                            if (angular.isDefined($window.sessionStorage.lockToken)) {
                                delete $window.sessionStorage.lockToken;
                            }
                            delete $window.sessionStorage.token;
                            delete $window.sessionStorage.intermediary;

                            delete $window.sessionStorage.channel;
                            delete $window.sessionStorage.brand;
                            delete $window.sessionStorage.sourcePortal;
                            delete $window.sessionStorage.anchorDate;

                            delete $window.sessionStorage.resourceActions;

                            $window.location = getFullyQualifiedLocation();
                        }
                    }
                ,
                function (error) {
                    $exceptionHandler(error);
                })

        }

        function exitCase() {
            var caseId = $route.current.params.caseId || null;
            if (caseId && angular.isDefined($window.sessionStorage.lockToken)) {
                if (typeof ReferenceDataService.clearCache == 'function') {
                    ReferenceDataService.clearCache();
                }
                LockService.clearLock($window.sessionStorage.lockToken)
                    .then(function () {
                        // clear up any state we have about this case e.g. lock
                        delete $window.sessionStorage.lockToken;
                        delete $window.sessionStorage.channel;
                        delete $window.sessionStorage.brand;
                        delete $window.sessionStorage.sourcePortal;
                        delete $window.sessionStorage.anchorDate;

                        delete $window.sessionStorage.resourceActions;

                        // IE11 and below contain a bug whereby if we are redirecting to the same HTML page,
                        // $window.location and $window.location.href will cause Angular to go into an infinite digest
                        // loop. See https://github.com/angular/angular.js/pull/2802

                        // We can't just unconditionally use $location.path as this will not refresh the page if we are
                        // navigating between different HTML pages (e.g. dip.html to cm.html).

                        // Figure out whether we are navigating to the same page, and if we are, use $location.path,
                        // otherwise use $window.location.href.
                        if ($location.absUrl().indexOf('/cm/#!/') !== -1) {
                            //as this controller only serves links to go to the home page (for cm.html), hard code that
                            //which should work for IE and Chrome
                            $location.search('');
                            $location.path('intermediary-home');
                        }
                        else {
                            $window.location.href = '/adviser-home-ui/';
                        }
                    },
                    function (error) {
                        $exceptionHandler(error);
                    })
            }
            else {
                //timeout may have occurred
                //as this controller only serves links to go to the home page (for cm.html), hard code that
                //which should work for IE and Chrome
                if ($location.absUrl().indexOf('/cm/#!/') !== -1) {
                    //as this controller only serves links to go to the home page (for cm.html), hard code that
                    //which should work for IE and Chrome
                    $location.path('intermediary-home');
                }
                else {
                    $window.location.href = '/adviser-home-ui/';
                }
            }
        }
    }
})();
