(function () {
    'use strict';

    angular
        .module('mso.directives')
        .filter('msoArrayObjectsToArray', msoArrayObjectsToArray);

    function msoArrayObjectsToArray() {
        return msoArrayObjectsToArrayFilter;

        ////////////////

        function msoArrayObjectsToArrayFilter(array, objectPropertyName) {
            return array.map(function (object) {
                return object[objectPropertyName];
            });
        }
    }

})();

