angular.module('mso.directives')
        .constant('showMetadataConfig',{
            'friendlyId': true,
            'applicantNames': true,
            'channel': false
        })
        .directive('msoShowMetadata', ['showMetadataConfig', function (showMetadataConfig) {
            return {
                restrict: 'EA',
                controller: 'ShowMetadataController',
                link: function(scope,elem, attrs){
                    var metadataFilter;
                    if (attrs.metadataFilter) {
                        metadataFilter = angular.extend({}, showMetadataConfig, scope.$eval(attrs.metadataFilter));
                        scope.filter = metadataFilter;
                    }
                    else{
                        scope.filter = showMetadataConfig;
                    }

                },
                template: '<span data-ng-show="applicantNames" class="nav-names">{{applicantNames}}</span>' +
                '<span data-ng-show="friendlyId" class="nav-case-id">Case ID: {{friendlyId}}'

            };
        }])
        .controller('ShowMetadataController', ['$scope', 'CaseService', '$exceptionHandler', function ($scope, CaseService, $exceptionHandler) {

                $scope.friendlyId = '';
                $scope.applicantNames = '';

                $scope.$on("case_context_changed", function () {
                    var caseId = CaseService.getCaseId();
                    CaseService.getCase(caseId).then(function (caseData) {
                        if (caseData && caseData.metadata){
                            $scope.friendlyId = caseData.metadata.friendlyId;
                            $scope.applicantNames = '';
                            if (caseData.applicants && caseData.applicants.length >0){
                                angular.forEach(caseData.applicants, function(applicant, index){
                                    if(index > 0){
                                        $scope.applicantNames += ', '
                                    }
                                    $scope.applicantNames += (applicant.forename + ' ' + applicant.surname);
                                })
                            }

                        }
                    }, function (error) {
                        $exceptionHandler(error);
                    });
                });

            }]);

