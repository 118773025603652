angular.module('mso.session').controller('SessionExpiryDialogCtrl', ['$scope', '$modalInstance', '$timeout', 'SessionExpiryService', function ($scope, $modalInstance, $timeout, SessionExpiryService) {
        $scope.title = 'Session Expiry';

        var dialogTimeout = $timeout(function() {
            expire();
        }, 30000);

        $scope.confirm = function(){
            $timeout.cancel(dialogTimeout);
            SessionExpiryService.getRefreshToken();
            SessionExpiryService.startInactivityTimer();
            SessionExpiryService.startRefreshTokenTimer();
            $modalInstance.close();
        };

        function expire() {
            SessionExpiryService.expireSession();
            $modalInstance.close();
        }
    }]);
