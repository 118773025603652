//This service is designed to deal with user permissions
angular.module('mso.services').factory('ResourceActionsService', ['$resource', 'Config', '$q', function($resource, config, $q){
    var resourceActionsApi = $resource(config.apiBaseHref + '/resourceActions', {}, {'get': {method: 'GET', isArray: true}});

    return {

        //Retrieve the resource actions that the user can undertake
        getResourceActions: function(){
            var deferred = $q.defer();
            resourceActionsApi.get().$promise.then(function (data) {
                    deferred.resolve(data);
                },
                function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }
    };
}]);
