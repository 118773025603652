
    angular.module('mso.authentication').factory('ForgottenPasswordService', ['$q', '$resource', 'Config',

    function ($q, $resource, Config) {
        var resetApi = $resource(Config.umApiBaseHref + "/forgotten-reset", null, {
            'get': {method: 'GET'}
        });
        return {
            reset: reset
        };

        function reset(emailAddress, originatingModule){
            var deferred = $q.defer();

            resetApi.get({emailAddress: emailAddress, originatingModule: originatingModule}).$promise.then(function(data){
                deferred.resolve(data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

    }]);