angular.module('mso.interceptors').factory('authenticationInterceptor', ['$rootScope', '$q', '$window', '$location', function ($rootScope, $q, $window, $location) {
    'use strict';
    return {
        request: function (config) {
            config.headers = config.headers || {};
            if ($window.sessionStorage.token) {
                config.headers.Authorization = 'Bearer ' + $window.sessionStorage.token;

                if ($window.sessionStorage.lockToken) {
                    config.headers.lockId = $window.sessionStorage.lockToken;
                }
            }
            return config;
        },
        responseError: function (rejection) {
            var location = "";
            if (rejection.status === 401) {
                // get rid of all history and locks
                delete $window.sessionStorage.token;
                delete $window.sessionStorage.user;
                delete $window.sessionStorage.intermediary;

                delete $window.sessionStorage.channel;
                delete $window.sessionStorage.brand;
                delete $window.sessionStorage.sourcePortal;
                delete $window.sessionStorage.anchorDate;

                $window.location.href = '/logout-ui/';
            }

            if(rejection.status === 403){
                location = "";
                location += '/adviser-home-ui?accessMessage=' + rejection.data;
                $window.location.href = location;
            }

            if(rejection.status === 405){
                location = "";
                location += '/case-home-ui/' +  rejection.data.caseId + '/case-home?accessMessage=' + rejection.data.message;
                $window.location.href = location;
            }

            return $q.reject(rejection);
        }
    };
}]).config(['$httpProvider', function ($httpProvider) {
    'use strict';
    $httpProvider.interceptors.push('authenticationInterceptor');
}]);

