angular.
module('mso').
factory('$exceptionHandler', ['$log', '$injector', function($log, $injector) {

    "use strict";

    return function (exception, cause) {

        /*
         Log to the console
         */
        $log.warn(exception, cause);

        /*
         Inject mandatory dependencies
         */
        var siteConfig = $injector.get('SiteConfig');
        var exceptionService = $injector.get('ExceptionService');

        /*
         Identify the type of error and ensure only 1 of each is "popped up"
         */
        var errorStatus = (exception.status) ? exception.status : cause;
        var identifier = '';
        var retryDate = '';
        var retryAfterDateTimeFormat = 'Do MMMM YYYY HH:mm';
        var retryAfterHeaderKey = 'Retry-After';
        var additionalMessageText = '';
        var popTheToaster = false;

        //Get the activity id value if one exists
        if (exception.data && exception.data.ActivityId && exception.data.ActivityId.length > 0) {
            identifier = exception.data.ActivityId;
        }
        else if (exception.ActivityId){
            identifier = exception.ActivityId;
        }

        //Get the retry value if one exists
        if (exception.config && exception.config.headers && exception.config.headers[retryAfterHeaderKey]) {

            var retryAfter = exception.config.headers[retryAfterHeaderKey];

            //Should be a number or a date
            if (typeof retryAfter === 'number') {
                retryDate = moment().add('hours', retryAfter).format(retryAfterDateTimeFormat);
            }
            else if (retryAfter instanceof Date) {
                retryDate = moment(retryAfter).format(retryAfterDateTimeFormat);
            }
            else if (typeof retryAfter === 'string') {
                retryDate = moment().add('hours', parseInt(retryAfter)).format(retryAfterDateTimeFormat);
            }
        }

        if (errorStatus) {

            if (errorStatus === 500) {
                popTheToaster = true;
            }
            else if (errorStatus === 503) {
                popTheToaster = true;
            }
            else if (!siteConfig.suppressUnexpectedAPIErrors) {
                popTheToaster = true;

                additionalMessageText = 'Status of the error message is: ' + errorStatus + '.';

                if (exception.data && exception.data.message){
                    if (additionalMessageText.length > 0) {
                        additionalMessageText = additionalMessageText + '<br/>';
                    }
                    additionalMessageText = 'Further details of this unexpected error are as follows:<br/>' + exception.data.message;
                }

                if (exception.message) {
                    if (additionalMessageText.length > 0) {
                        additionalMessageText = additionalMessageText + '<br/>';
                    }
                    additionalMessageText = 'Further details of this unexpected error are as follows:<br/>' + exception.message;
                }
            }
        }
        else if (!siteConfig.suppressUnexpectedErrors) {

            popTheToaster = true;

            if (exception.stack) {
                additionalMessageText = 'Further details of this unexpected error are as follows:<br/>' + exception.stack;
            }
        }

        /*
         If an error message is to be displayed - based on the above derivation, then do stuff...
         */
        if (popTheToaster) {

            /*
             And finally, pop the toaster with the message to be displayed
             */
            exceptionService.displayError(errorStatus, identifier, retryDate, additionalMessageText);
        }
    };
}
]);