(function () {
    'use strict';
    angular.module('mso.directives').directive('msoTradingAddressDisplay', tradingAddressDisplay);

    function tradingAddressDisplay() {
        return {
            restrict: 'EA',
            controller: tradingAddressDisplayController,
            controllerAs: 'vm',
            bindToController: true,
            template: require('/app/template/trading-address-display.html').default,
            require: '^form',
            scope: {
                tradingAddress: '=',
                tradingAddressPromise: '=',
                referenceData: '=',
                identifierPrefix: '=',
                form: '=',
                showValidation: '=',
                triggerRequiredFields: '=',
                hideBdm: '=',
                allowChange: '=',
                allowEdit: '=',
                condenseAddress: '=',
                editInProgress: '=?',
                editInProgressCallback: '&'
            },
            replace: true
        };
    }

    angular.module('mso.directives').controller('TradingAddressDisplayController', tradingAddressDisplayController);

    tradingAddressDisplayController.$inject = ['$scope', '$q', 'ReferenceDataService', 'enums'];

    function tradingAddressDisplayController($scope, $q, ReferenceDataService, enums) {
        var vm = this;

        vm.messages = $scope.vm.referenceData.messages;
        vm.parameters = $scope.vm.referenceData.parameters;
        vm.referenceData = $scope.vm.referenceData;
        vm.referenceDataServ = ReferenceDataService;
        vm.tradingAddress = $scope.vm.tradingAddress;
        vm.hideBdm = $scope.vm.hideBdm;

        $scope.$watch("tradingAddress", function (value) {
            vm.tradingAddress = value;
        });

        vm.tradingAddressIdentifierPrefix = $scope.vm.identifierPrefix;

        vm.CanShowBdm = canShowBdm;
        vm.onSaveChanges = onSaveChanges;
        vm.onCancelChanges = onCancelChanges;

        $scope.vm.editInProgressCallback({value: $scope.vm.editInProgress});

        vm.addressTypeCodes = angular.copy([
            {"uk": 1},
            {"bfpo": 2},
            {"overseas": 3}
        ]);

        vm.addressTypeCodes = angular.copy(vm.referenceData.valuesets.rdAddressTypeCode);
        if (!vm.addressTypeCodes) {
            vm.addressTypeCodes = angular.copy([{"uk": 1}]);
        }

        vm.addressTypeCodes = vm.addressTypeCodes.filter(function (value) {
            return value.value != enums.RDAddressTypeCode.bFPOAddress && value.value != enums.RDAddressTypeCode.overseasAddress;
        });

        vm.onEdit = onEdit;

        vm.tradingAddressAddressFlagsViewModel = {};
        vm.tradingAddressAddressFlagsViewModel.showAddressType = false;

        function canShowBdm() {
            if (vm.tradingAddress) {
                return !vm.hideBdm && vm.tradingAddress.bdm;
            }
            else {
                return false;
            }
        }

        function onEdit() {
            vm.condenseAddress = false;
            vm.editMode = true;
            vm.tradingAddressAddressFlagsViewModel.showEdit = true;
            vm.tradingAddressAddressFlagsViewModel.showSearch = false;
            vm.editInProgress = true;
            vm.editInProgressCallback({value: vm.editInProgress});
        }

        function onSaveChanges() {
            vm.condenseAddress = true;
            vm.editMode = false;
            vm.editInProgress = false;
            vm.editInProgressCallback({value: vm.editInProgress});
            if (vm.condenseAddress) {
                vm.condensedAddress = doCondenseAddress(vm.tradingAddress.address);
            }
        }

        function onCancelChanges() {
            vm.condenseAddress = true;
            vm.editMode = false;
            vm.editInProgress = false;
            vm.editInProgressCallback({value: vm.editInProgress});
        }

        function doCondenseAddress(address) {
            var addressArray = [];
            if (address.flat != null) addressArray.push(address.flat);
            if (address.buildingName != null) addressArray.push(address.buildingName);
            if (address.buildingNumber != null) addressArray.push(address.buildingNumber);
            if (address.street != null) addressArray.push(address.street);
            if (address.district != null) addressArray.push(address.district);
            if (address.town != null) addressArray.push(address.town);
            if (address.county != null) addressArray.push(address.county);
            if (address.postcode != null) addressArray.push(address.postcode);

            return addressArray.join(',');
        }

        vm.tradingAddressAddressPromise = tradingAddressAddressSetup;

        function tradingAddressAddressSetup() {
            var deferred = $q.defer();

            if (vm.tradingAddress === undefined) {
                deferred.resolve({});
            }
            else {
                deferred.resolve(vm.tradingAddress.address);
            }
            return deferred.promise;
        }

        if (vm.condenseAddress) {
            if (vm.tradingAddress && vm.tradingAddress.address !== undefined) {
                vm.condensedAddress = doCondenseAddress(vm.tradingAddress.address);
            }
            else {
                vm.condensedAddress = "None"
            }
        }
    }
})();

