(
    function() {
    'use strict';
    angular.module('mso.services')
        .factory('UserPermissionsService', UserPermissionsService);

    UserPermissionsService.$inject = ['$window', '$q', 'ResourceActionsService'];

    function UserPermissionsService($window, $q, ResourceActionsService) {

        return {
            getResourceActions: getResourceActions,
            mapResourceActions: mapResourceActions
        };

        function getResourceActions(vm) {

            var deferred = $q.defer();

            if (vm === undefined) {
                vm = {};
            }

            if ($window.sessionStorage.resourceActions) {
                populateModelFromSession(vm);
                deferred.resolve(true);
            }
            else {
                ResourceActionsService.getResourceActions().then(
                    function (data) {

                        var resourceActions = [];
                        angular.forEach(data, function (dataItem) {
                            resourceActions.push(dataItem);
                        });

                        $window.sessionStorage.setItem("resourceActions", JSON.stringify(resourceActions));
                        populateModelFromSession(vm);

                        deferred.resolve(true);

                    }, function (error) {
                        deferred.reject(error);
                    });
            }

            return deferred.promise;
        }

        function mapResourceActions(vm) {

            if (vm === undefined) {
                vm = {};
            }

            if ($window.sessionStorage.resourceActions) {
                populateModelFromSession(vm);
            }
        }

        function populateModelFromSession(vm){
            populateResourceActions(vm, angular.fromJson($window.sessionStorage.resourceActions));
        }

        function populateResourceActions(vm, resourceActions){

            vm.canAdministerAllBrokerCases = false;
            vm.canAdministerAssociatedBrokerCases = false;
            vm.canAdministerOwnCases = false;
            vm.canTransferAssociatedFirmCases = false;
            vm.canViewAssociatedFirmCases = false;

            if(resourceActions && vm) {
                angular.forEach(resourceActions, function(resourceAction){

                    if (resourceAction) {
                        if (resourceAction.name === 'AdministerAllBrokerCases') {
                            vm.canAdministerAllBrokerCases = true;
                        }
                        if (resourceAction.name === 'AdministerAssociatedBrokerCases') {
                            vm.canAdministerAssociatedBrokerCases = true;
                        }
                        if (resourceAction.name === 'AdministerOwnCases') {
                            vm.canAdministerOwnCases = true;
                        }
                        if (resourceAction.name === 'TransferAssociatedFirmCases') {
                            vm.canTransferAssociatedFirmCases = true;
                        }
                        if (resourceAction.name === 'ViewAssociatedFirmCases') {
                            vm.canViewAssociatedFirmCases = true;
                        }
                    }
                });
            }

            vm.canOnlyAdministerOwnCases = vm.canAdministerOwnCases && !vm.canAdministerAssociatedBrokerCases && !vm.canViewAssociatedFirmCases;
            vm.canOnlyAdministerAssociatedBrokerCases = !vm.canAdministerOwnCases && vm.canAdministerAssociatedBrokerCases && !vm.canViewAssociatedFirmCases;
            vm.canOnlyViewAssociatedFirmCases = !vm.canAdministerOwnCases && !vm.canAdministerAssociatedBrokerCases && vm.canViewAssociatedFirmCases;
        }
    }
})();