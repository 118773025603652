angular.module('mso.services').factory('UserInfoService',  ['$resource', '$q', 'Config', function($resource, $q, Config ){

        var userInfoApi = $resource(Config.apiBaseHref + '/UserInfo');

        // the one to allow people to tinker with
        var userDetails = {};

        var service = {
            getUserInfo : function() {
                var deferred = $q.defer();
                var storedUser;

                if (window.sessionStorage.user)
                    storedUser = JSON.parse (window.sessionStorage.user);

                if (storedUser && userDetails && (storedUser.id == userDetails.id)) {
                    deferred.resolve(userDetails);
                }
                else {
                    userInfoApi.get().$promise.then(function (data) {
                            userDetails = data;

                            // store for later...
                            window.sessionStorage.user = JSON.stringify(userDetails);

                            // data should now be in the cache
                            deferred.resolve(userDetails);
                        },
                        function (error) {
                            deferred.reject(error);
                        });
                }
                return deferred.promise;
            }
        };

        return service;

    }]);

