/**
* This file has been generated automatically by a tool.
* Do not manually edit.  Update the relevant EA that defines this MSO instance.
**/
angular.module('mso.services').constant('enums', {

	/**
	* Represents the reference item RDAdditionalBorrowingReasonCode.
	**/
	RDAdditionalBorrowingReasonCode: {
		repayUnsecuredDebts:1,
		otherPersonalConsumption:2,
		nonStructuralHomeImprovement:3,
		structuralHomeImprovement:4,
		buyOutNonBorrower:5,
		buyAdditionalShareInASharedOwnership:6,
		buyFinalShareInASharedOwnership:7,
		buyAShareInFreehold:8,
		buyFreeholdTitleOrNewExtendedLease:9,
		buyLandToExtendSecurity:10,
		businessPurposes:11,
		buyPropertyForMainResidenceAndLetCurrentProperty:12,
		buyLandOrPropertySeparateFromTheSecurity:13,
		payOffSecondCharge:14,
		legalFees:15,
		buyOutJointBorrower:16,
		investmentPurposes:17,
		depositForProperty:18,
		giftToRelative:19,
		essentialRepairs:20 
	},

	/**
	* Represents the reference item RDAddressTypeCode.
	**/
	RDAddressTypeCode: {
		uKAddress:1,
		bFPOAddress:2,
		overseasAddress:3 
	},

	/**
	* Represents the reference item RDAnchorDateMethods.
	**/
	RDAnchorDateMethods: {
		caseCreation:1,
		initialCreditDecision:2,
		initialApplicationSubmission:3 
	},

	/**
	* Represents the reference item RDApplicantERCPosition.
	**/
	RDApplicantERCPosition: {
		noERCToPayOnExistingMortgage:1,
		eRCToPayAndCustomerWillingToPay:2,
		eRCToPayAndCustomerUnwillingToPay:3,
		eRCToPayAndCustomerRequestsDelayedCompletionDate:4 
	},

	/**
	* Represents the reference item RDApplicantTypeCode.
	**/
	RDApplicantTypeCode: {
		firstTimeBuyer:1,
		existingBorrowerWithLender:2,
		borrowerWithOtherLender:3,
		previousBorrowerWithLender:4,
		previousBorrowerOther:5 
	},

	/**
	* Represents the reference item RDApplicationTypeCode.
	**/
	RDApplicationTypeCode: {
		newPurchase:1,
		remortgage:2,
		additionalBorrowing:3,
		rateSwitch:4,
		transferOfEquity:5,
		internalRemortgage:6 
	},

	/**
	* Represents the reference item RDAssetPropertyTypes.
	**/
	RDAssetPropertyTypes: {
		valuationReportId:22,
		caseRequirementId:26 
	},

	/**
	* Represents the reference item RDAuthoriseFundsReleaseOutcome.
	**/
	RDAuthoriseFundsReleaseOutcome: {
		approve:1,
		requestANewCOT:2,
		editCOTData:3 
	},

	/**
	* Represents the reference item RDAutomatedValuationMessageType.
	**/
	RDAutomatedValuationMessageType: {
		warning:1,
		error:2 
	},

	/**
	* Represents the reference item RDAutomatedValuationStatus.
	**/
	RDAutomatedValuationStatus: {
		acceptable:1,
		unacceptable:2,
		superseded:3,
		expired:4 
	},

	/**
	* Represents the reference item RDBDMRelationship.
	**/
	RDBDMRelationship: {
		telephone:1,
		faceToFace:2 
	},

	/**
	* Represents the reference item RDBuildingFloors.
	**/
	RDBuildingFloors: {
		basement:1,
		ground:2,
		first:3,
		second:4,
		third:5,
		fourth:6,
		fifth:7,
		sixth:8,
		seventh:9,
		eighth:10,
		ninth:11,
		tenthToNineteenth:12,
		twentiethOrAbove:13 
	},

	/**
	* Represents the reference item RDBuyToLetKillerQuestions.
	**/
	RDBuyToLetKillerQuestions: {
		bTLQuestionOne:1,
		bTLQuestionTwo:2,
		bTLQuestionThree:3,
		bTLQuestionFour:4,
		bTLQuestionFive:5 
	},

	/**
	* Represents the reference item RDCaseCancellationReason.
	**/
	RDCaseCancellationReason: {
		personalCircumstancesChanged:1,
		betterRateOrProductSecuredElsewhere:2,
		applicationNotProceeding:3,
		unhappyWithService:4,
		other:5 
	},

	/**
	* Represents the reference item RDCaseNoteCategory.
	**/
	RDCaseNoteCategory: {
		caseNotes:1,
		reviewDIP:2,
		requestCaseRequirement:3,
		verifyIncomingDocument:4,
		manuallyAssessCase:5,
		reviewCaseForFraud:6,
		reviewValuationReport:7,
		reviewValuationExpiry:8,
		reviewReinspectionReport:9,
		manageDecline:10,
		additionalInformation:11,
		caseDataUpdate:12,
		cancelCase:13,
		suspendCase:14,
		resumeCase:15,
		returnOfControl:16,
		manageCaseRequirement:17,
		escalateTask:18,
		declineCase:19,
		preContractVariation:20,
		channelSwitch:21,
		lifestyleChanges:22,
		contactTask:23,
		fees:24,
		manageUserMaxLTV:25,
		cOTAssessment:26,
		packageAndAssess:105,
		noteToSeller:116,
		callToAction:117,
		offer:126,
		valuations:127,
		fundsRelease:137,
    limitedCompany:138
	},

	/**
	* Represents the reference item RDCaseNoteImportanceFilterLevels.
	**/
	RDCaseNoteImportanceFilterLevels: {
		all:3,
		normal:0,
		high:1 
	},

	/**
	* Represents the reference item RDCaseRequirementAppliesTo.
	**/
	RDCaseRequirementAppliesTo: {
		applicant:1,
		application:2 
	},

	/**
	* Represents the reference item RDCaseRequirementDeliveryMode.
	**/
	RDCaseRequirementDeliveryMode: {
		document:1,
		information:2 
	},

	/**
	* Represents the reference item RDCaseRequirementSatisfyStage.
	**/
	RDCaseRequirementSatisfyStage: {
		offer:2,
		fundsRelease:3 
	},

	/**
	* Represents the reference item RDCaseRequirementStatus.
	**/
	RDCaseRequirementStatus: {
		created:1,
		requested:2,
		received:4,
		satisfied:8,
		waived:16,
		cancelled:32 
	},

	/**
	* Represents the reference item RDCaseStage.
	**/
	RDCaseStage: {
		created:1,
		illustrationGenerated:2,
		decisionInPrinciple:3,
		productSelection:4,
		fullMortgageApplication:5,
		preSubmission:6,
		preOffer:7,
		offer:8,
		postOffer:9,
		completion:10,
		uploaded:11,
		mortgageLive:12,
		applicationClosed:13,
		productRecommendation:14,
		needsAndCircumstances:15,
        rateSwitch:16
	},

	/**
	* Represents the reference item RDCaseStatus.
	**/
	RDCaseStatus: {
		active:1,
		completed:2,
		suspended:3,
		cancelled:4,
		declined:5,
		preContractVariations:6 
	},

	/**
	* Represents the reference item RDCentralHeating.
	**/
	RDCentralHeating: {
		none:0,
		gas:1,
		electric:2,
		gasElectric:3,
		oil:4,
		gasOil:5,
		electricOil:6,
		solidFuel:8,
		gasSolidFuel:9,
		electricSolidFuel:10,
		oilSolidFuel:12,
		partial:128,
		full:256 
	},

	/**
	* Represents the reference item RDCertificateOfTitleStatus.
	**/
	RDCertificateOfTitleStatus: {
		requested:1,
		approved:2,
		rejected:3 
	},

	/**
	* Represents the reference item RDChannelCode.
	**/
	RDChannelCode: {
		intermediary:0,
		backoffice:6 
	},

	/**
	* Represents the reference item RDCommittedExpenditureTypeCode.
	**/
	RDCommittedExpenditureTypeCode: {
		personalLoansOrHirePurchase:1,
		securedLoans:2,
		studentLoans:3,
		cSAOrMaintenance:4,
		creditCards:5,
		deferredLoan:6,
		hirePurchase:7,
		chargeCard:8,
		mailOrder:9,
		overdraft:10,
		buyNowPayLater:11,
		interestFreeLoan:12,
		personalLoans:13 
	},

	/**
	* Represents the reference item RDContactType.
	**/
	RDContactType: {
		applicant:1,
		employer:2,
		accountant:3,
		intermediaryIndividual:4,
		solicitor:5,
		valuer:6,
		landlord:7,
		mortgageConsultant:8,
		staff:9,
		powerOfAttorney:10,
		other:11,
		otherCharge:32 
	},

	/**
	* Represents the reference item RDConveyancerAllocationStatus.
	**/
	RDConveyancerAllocationStatus: {
		allocated:1,
		cancelled:2,
		rejected:3,
		verified:4,
		failedVerification:5 
	},

	/**
	* Represents the reference item RDConveyancerAllocationType.
	**/
	RDConveyancerAllocationType: {
		customer:1,
		lender:2 
	},

	/**
	* Represents the reference item RDCOTReviewOutcomeType.
	**/
	RDCOTReviewOutcomeType: {
		approve:1,
		requestANewCOT:2,
		editCOTData:3 
	},

	/**
	* Represents the reference item RDCountryCode.
	**/
	RDCountryCode: {
		afghanistan:4,
		alandIslands:248,
		albania:8,
		algeria:12,
		americanSamoa:16,
		andorra:20,
		angola:24,
		anguilla:660,
		antarctica:10,
		antiguaAndBarbuda:28,
		argentina:32,
		armenia:51,
		aruba:533,
		australia:36,
		austria:40,
		azerbaijan:31,
		bahamas:44,
		bahrain:48,
		bangladesh:50,
		barbados:52,
		belarus:112,
		belgium:56,
		belize:84,
		benin:204,
		bermuda:60,
		bhutan:64,
		bolivia:68,
		bonaire:535,
		bosniaAndHerzegovina:70,
		botswana:72,
		bouvetIsland:74,
		brazil:67,
		britishIndianOceanTerritory:86,
		bruneiDarussalam:96,
		bulgaria:100,
		burkinaFaso:854,
		burundi:108,
		cambodia:116,
		cameroon:120,
		canada:124,
		capeVerde:132,
		caymanIslands:136,
		centralAfricanRepublic:140,
		chad:148,
		chile:152,
		china:156,
		christmasIsland:162,
		cocosIslands:166,
		colombia:170,
		comoros:174,
		congo:178,
		congodemocratic:180,
		cookIslands:184,
		costaRica:188,
		ivoryCoast:384,
		croatia:191,
		cuba:192,
		curaçao:531,
		cyprus:196,
		czechRepublic:203,
		denmark:208,
		djibouti:262,
		dominica:212,
		dominicanRepublic:214,
		ecuador:218,
		egypt:818,
		elSalvador:222,
		equatorialGuinea:226,
		eritrea:232,
		estonia:233,
		ethiopia:231,
		falklandIslands:238,
		faroeIslands:234,
		fiji:242,
		finland:246,
		france:250,
		frenchGuiana:254,
		frenchPolynesia:258,
		frenchSouthernTerritories:260,
		gabon:266,
		gambia:270,
		georgia:268,
		germany:276,
		ghana:288,
		gibraltar:292,
		greece:300,
		greenland:304,
		grenada:308,
		guadeloupe:312,
		guam:316,
		guatemala:320,
		guernsey:831,
		guinea:324,
		guyana:328,
		haiti:332,
		heardIslandAndMcdonaldIslands:334,
		holySee:336,
		honduras:340,
		hongKong:344,
		hungary:348,
		iceland:352,
		india:356,
		indonesia:360,
		iran:364,
		iraq:368,
		ireland:372,
		isleOfMan:833,
		israel:376,
		italy:380,
		jamaica:388,
		japan:392,
		jersey:832,
		jordan:400,
		kazakhstan:398,
		kenya:404,
		kiribati:296,
		southKorea:408,
		northKorea:410,
		kuwait:414,
		kyrgyzstan:417,
		lao:418,
		latvia:428,
		lebanon:422,
		lesotho:426,
		liberia:430,
		libya:434,
		liechtenstein:438,
		lithuania:440,
		luxembourg:442,
		macau:446,
		macedonia:807,
		madagascar:450,
		malawi:454,
		malaysia:458,
		maldives:462,
		mali:466,
		malta:470,
		marshallIslands:584,
		martinique:474,
		mauritania:478,
		mauritius:480,
		mayotte:175,
		mexico:484,
		micronesia:583,
		moldova:498,
		monaco:492,
		mongolia:496,
		montenegro:499,
		montserrat:500,
		morocco:504,
		mozambique:508,
		myanmar:104,
		namibia:516,
		nauru:520,
		nepal:524,
		netherlands:528,
		newCaledonia:540,
		newZealand:554,
		nicaragua:558,
		niger:562,
		nigeria:566,
		niue:570,
		norfolkIsland:574,
		northernMarianaIslands:580,
		norway:578,
		oman:512,
		pakistan:586,
		palau:585,
		palestinian:275,
		panama:591,
		papuaNewGuinea:598,
		paraguay:600,
		peru:604,
		philippines:608,
		pitcairn:6112,
		poland:616,
		portugal:620,
		puertoRico:630,
		qatar:634,
		réunion:638,
		romania:642,
		russianFederation:643,
		rwanda:646,
		saintBarthelemy:652,
		saintHelenaAscensionAndTristanDaCunha:654,
		saintKittsAndNevis:659,
		saintLucia:662,
		saintMartin:663,
		saintPierreAndMiquelon:666,
		saintVincentAndTheGrenadines:670,
		samoa:882,
		sanMarino:674,
		saoTomeAndPrincipe:678,
		saudiArabia:682,
		senegal:686,
		serbia:688,
		seychelles:690,
		sierraLeone:694,
		singapore:702,
		sintMaarten:534,
		slovakia:703,
		slovenia:705,
		solomonIslands:90,
		somalia:706,
		southAfrica:710,
		southGeorgiaAndTheSouthSandwichIslands:239,
		southSudan:728,
		spain:724,
		sriLanka:144,
		sudan:729,
		suriname:740,
		svalbardAndJanMayen:744,
		swaziland:748,
		sweden:752,
		switzerland:756,
		syrianArabRepublic:760,
		taiwan:158,
		tajikistan:762,
		tanzania:834,
		thailand:764,
		timor:626,
		togo:768,
		tokelau:772,
		tonga:776,
		trinidadAndTobago:780,
		tunisia:788,
		turkey:792,
		turkmenistan:795,
		turksAndCaicosIslands:796,
		tuvalu:798,
		uganda:800,
		ukraine:804,
		unitedArabEmirates:784,
		unitedKingdom:826,
		unitedStates:840,
		unitedStatesMinorOutlyingIslands:581,
		uruguay:848,
		uzbekistan:860,
		vanuatu:548,
		venezuela:862,
		vietnam:704,
		virginIslandsBritish:92,
		viringIslandsUS:850,
		wallisAndFutuna:876,
		westernSahara:732,
		yemen:887,
		zambia:894,
		zimbabwe:716 
	},

	/**
	* Represents the reference item RDCreditHistoryType.
	**/
	RDCreditHistoryType: {
		declaredBankrupt:1,
		propertyRepossessed:2,
		countyCourtJudgement:3 
	},

	/**
	* Represents the reference item RDCriticalDataSets.
	**/
	RDCriticalDataSets: {
		none:0,
		additionalBorrowingSet:1,
		applicantAddressSet:2,
		applicantIdentitySet:4,
		contactDetailsSet:8,
		conveyancingSet:16,
		cOTSet:32,
		creditHistorySet:64,
		depositDetailsSet:128,
		existingMortgagesSet:256,
		expenditureSet:512,
		financialDependentsSet:1024,
		futureChangesSet:2048,
		incomeSet:4096,
		loanDetailsSet:8192,
		nationalitySet:16384,
		offerSet:32768,
		repaymentStrategiesSet:65536,
		valuationResubmissionSet:131072,
		mandatoryEIRValuationSet:262144,
		mandatoryIIRValuationSet:524288,
		mandatoryRIRValuationSet:1048576 
	},

	/**
	* Represents the reference item RDDebtConsolidation.
	**/
	RDDebtConsolidation: {
		saveMoneyOnMonthlyRepayments:1,
		simplifyFinancialManagementOrAlignPaymentDates:2,
		improveCreditScore:3,
		annualCardFeesTooHigh:4,
		other:6 
	},

	/**
	* Represents the reference item RDDeclineReason.
	**/
	RDDeclineReason: {
		fraudDecline:1,
		creditDecisionDecline:2,
		manualAssessmentDecline:3 
	},

	/**
	* Represents the reference item RDDefaultKillerQuestions.
	**/
	RDDefaultKillerQuestions: {
		defaultKillerQuestion1:1,
		defaultKillerQuestion2:2,
		defaultKillerQuestion3:3,
		defaultKillerQuestion4:4,
		defaultKillerQuestion5:5 
	},

	/**
	* Represents the reference item RDDIPDecisionCode.
	**/
	RDDIPDecisionCode: {
		accept:1,
		decline:2,
		refer:3 
	},

	/**
	* Represents the reference item RDDIPDecisionReason.
	**/
	RDDIPDecisionReason: {
		acceptedApplicationWithinPolicy:1,
		acceptedOriginalDecisionOverridden:2,
		acceptedOriginalDecisionMarginal:3,
		acceptedReputationalRisk:4,
		acceptedPositiveLendingDiscretionApplied:5,
		acceptedPositiveLendingExistingRelationship:6,
		declinedAffordability:100,
		declinedIDStatus:101,
		declinedFailedCreditScore:102,
		declinedAdverseCredit:103,
		declinedInsufficientIncome:104,
		declinedInsufficientEmploymentHistory:105,
		declinedMaximumLTVExceeded:106,
		declinedMortgageOrRentArrears:107,
		declinedPropertyUnsuitableForMortgage:108,
		declinedProfileOrProposalConcerns:109 
	},

	/**
	* Represents the reference item RDDIPFinalDecisionCode.
	**/
	RDDIPFinalDecisionCode: {
		accept:1,
		decline:2,
		refer:3,
		subjectiveAccept:4,
		subjectiveDecline:5 
	},

	/**
	* Represents the reference item RDDocumentRejectionReasons.
	**/
	RDDocumentRejectionReasons: {
		documentIsAssociatedWithTheWrongRequirement:2,
		documentIsAssociatedWithTheWrongCase:3,
		documentIsIllegibleRemove:4,
		cannotIdentifyDocumentPurposeRemove:5,
		documentIsNotSufficientToSatisfyTheRequirement:6 
	},

	/**
	* Represents the reference item RDEmploymentContractTypeCode.
	**/
	RDEmploymentContractTypeCode: {
		permanent:1,
		fixedTermContract:2,
		subContractorFixedTerm:3,
		subContractorOpenEnded:4,
		temporary:5,
		zeroHoursContract:6,
		pieceWorker:7 
	},

	/**
	* Represents the reference item RDEmploymentIncomeType.
	**/
	RDEmploymentIncomeType: {
		employed:1,
		selfEmployedPartner:2,
		selfEmployedSoleTrader:3,
		directorOrShareholderLessThanOrEqualTwentyPercentShare:4,
		directorOrShareholderGreaterThanTwentyPercentShare:5 
	},

	/**
	* Represents the reference item RDEmploymentStatusCode.
	**/
	RDEmploymentStatusCode: {
		employed:1,
		selfEmployedPartner:2,
		selfEmployedSoleTrader:3,
		directorOrShareholderLessThanOrEqualTwentyPercentShare:4,
		directorOrShareholderGreaterThanTwentyPercentShare:5,
		retired:6,
		homeMaker:7,
		student:8,
		notEmployed:9 
	},

	/**
	* Represents the reference item RDEnergyEfficiencyRating.
	**/
	RDEnergyEfficiencyRating: {
		a:1,
		b:2,
		c:3,
		d:4,
		e:5,
		f:6,
		g:7,
		unknown:8 
	},

	/**
	* Represents the reference item RDEquityShareTypeCode.
	**/
	RDEquityShareTypeCode: {
		buildersEquityShare:1,
		houseAssociationOrLocalAuthority:2,
		homebuyDirect:3,
		helpToBuy:4,
		helpToBuyScotland:5,
		helpToBuyWales:6,
		helpToBuyLondon:7,
		northernIrelandCoownershipHousingAssociation:8 
	},

	/**
	* Represents the reference item RDEscalationReasonType.
	**/
	RDEscalationReasonType: {
		insufficientMandate:0,
		insufficientExperience:1 
	},

	/**
	* Represents the reference item RDExistingMortgageProviderCode.
	**/
	RDExistingMortgageProviderCode: {
		abbeyNational:1,
		affirmativeFinanceLimited:15,
		ahliUnitedBankUK:16,
		aIBGroupUK:17,
		aldermoreBank:18,
		allianceAndLeicester:2,
		alliedIrishBank:19,
		aTOM:20,
		axis:21,
		bankOfChina:22,
		bankOfIreland:23,
		bankOfScotlandHbos:24,
		barclays:3,
		barnsleyBS:25,
		bathBS:26,
		beverleyBS:27,
		birminghamMidshires:28,
		brittania:29,
		buckinghamshireBS:30,
		cambridgeBS:31,
		capitalCreditUnion:32,
		centralHomeloansLimited:33,
		chelseaBS:34,
		cheltenhamAndGloucester:4,
		cheshireBS:35,
		chorleyAndDistrictBS:36,
		clydesdaleBank:5,
		couttsBank:37,
		coventryBS:6,
		crocusHomeLoansLimited:38,
		cumberlandBS:39,
		danskBank:40,
		darlingtonBS:41,
		dBUKBankLimited:42,
		derbyshireBS:43,
		dragonFlyPropertyFinance:44,
		dudleyBS:45,
		dunfermlineBS:46,
		earlShiltonBS:47,
		ecologyBS:48,
		familyBS:49,
		firstTrustBank:50,
		fleetMortgages:51,
		foundationHomeLoans:52,
		furnessBS:53,
		gEMoney:54,
		godivaMortgages:55,
		halifax:56,
		hanleyEconomicBS:57,
		harpendenBS:58,
		hBOS:59,
		hinkleyAndRugbyBS:60,
		holmsdaleBS:61,
		hSBC:7,
		inelligentFinance:62,
		investec:63,
		ipswichBS:64,
		islamicBank:65,
		kensingtonMortgages:66,
		kentReliance:67,
		leedsBS:68,
		leekUnitedBS:69,
		lloydsBank:70,
		loughboroughBS:71,
		magellanHomeloans:72,
		manchesterBS:73,
		mansfieldBS:74,
		marketHarboroughBS:75,
		marsdenBS:76,
		mBSLending:77,
		meltonMowbrayBS:78,
		metroBank:79,
		monmouthshireBS:80,
		morganStanleyBankInternational:81,
		nationalCountiesBS:82,
		nationwide:8,
		natwest:9,
		newburyBS:83,
		newcastleBS:84,
		norwichAndPeterboroughBS:85,
		nottinghamBS:86,
		paragonMortgageTrust:87,
		penrithBS:88,
		pepperUKLimited:89,
		platform:90,
		principalityBS:91,
		progressiveBS:92,
		royalBankOfScotland:10,
		safronBS:93,
		santander:11,
		scottishBS:94,
		scottishWidowsBank:95,
		shawbrook:96,
		skiptonBS:97,
		skiptonInternational:98,
		staffordRailwayBS:99,
		stateBankOfIndiaUK:100,
		swanseaBS:101,
		swift:102,
		teachersBS:103,
		tescoBank:104,
		theCoOperativeBank:105,
		theMortgageWorks:106,
		tiptonAndCoselyBS:107,
		tSBBank:108,
		tSBIntermediaries:109,
		uBSLimited:110,
		ulsterBank:111,
		vernonBS:112,
		virginMoney:12,
		westBromwichBS:113,
		yorkshireBank:114,
		yorkshireBS:13,
		other:14 
	},

	/**
	* Represents the reference item RDFeeAdjustmentType.
	**/
	RDFeeAdjustmentType: {
		discount:1,
		increase:2 
	},

	/**
	* Represents the reference item RDFeePayableHow.
	**/
	RDFeePayableHow: {
		addToLoan:1,
		creditDebitCard:2,
		deductFromAdvance:3 
	},

	/**
	* Represents the reference item RDFeePayableTo.
	**/
	RDFeePayableTo: {
		lender:1,
		conveyancer:2,
		broker:3 
	},

	/**
	* Represents the reference item RDFeePayableWhen.
	**/
	RDFeePayableWhen: {
		onCancellationOrDecline:1,
		redemption:2,
		beforeLoanStarts:3,
		priorToOffer:4,
		onApplication:5,
		completion:6,
		atInterview:7,
		cancellationPostOffer:8,
		postOffer:9 
	},

	/**
	* Represents the reference item RDFeeRefundable.
	**/
	RDFeeRefundable: {
		no:1,
		yesAmount:2,
		yesPercentage:3 
	},

	/**
	* Represents the reference item RDFeeStatus.
	**/
	RDFeeStatus: {
		cancelled:1,
		estimated:2,
		paid:3,
		refundDue:4,
		refunded:5,
		unpaid:6,
		waived:7 
	},

	/**
	* Represents the reference item RDFeeTransactionStatus.
	**/
	RDFeeTransactionStatus: {
		completed:1,
		failed:2,
		pending:3,
		waived:4,
		cancelled:5 
	},

	/**
	* Represents the reference item RDFeeTransactionType.
	**/
	RDFeeTransactionType: {
		payment:1,
		refund:2 
	},

	/**
	* Represents the reference item RDFeeType.
	**/
	RDFeeType: {
		aVM:1,
		bTLValuation:2,
		buildingSurvey:3,
		completion:4,
		finalRepayment:5,
		homebuyerSurvey:6,
		intermediary:7,
		landRegistry:8,
		mortgageValuation:9,
		postOfferReinspection:10,
		preOfferReinspection:11,
		product:12,
		solicitor:13,
		transcription:14,
		withdrawal:15,
		application:16,
		procuration:17,
		upgradeToBuildingSurvey:18,
		upgradeToHomebuyersSurvey:19,
		productSwitch:20,
		additionalBorrowing:21,
		transferOfEquity:22,
		rateSwitchFee:23,
		reinspection:24,
		bTLBuildingSurvey:25,
		bTLHomebuyer:26,
		upgradeToBTLBuildingSurvey:27,
		upgradeToBTLHomebuyer:28,
        externalInspection:29
    },

	/**
	* Represents the reference item RDForLimitedOverpaymentsWithoutERCs.
	**/
	RDForLimitedOverpaymentsWithoutERCs: {
		other:1 
	},

	/**
	* Represents the reference item RDFraudReferralSourceType.
	**/
	RDFraudReferralSourceType: {
		monitoredBroker:1,
		automatedFraudReferral:2,
		manualFraudReferral:3 
	},

	/**
	* Represents the reference item RDFraudReviewOutcomeType.
	**/
	RDFraudReviewOutcomeType: {
		approve:1,
		decline:2 
	},

	/**
	* Represents the reference item RDFrequencyCode.
	**/
	RDFrequencyCode: {
		weekly:1,
		fourWeekly:2,
		monthly:3,
		quarterly:4,
		annually:5 
	},

	/**
	* Represents the reference item RDGenderTypeCode.
	**/
	RDGenderTypeCode: {
		female:1,
		male:2 
	},

	/**
	* Represents the reference item RDGuaranteeScheme.
	**/
	RDGuaranteeScheme: {
		unknown:0,
		nHBC:1,
		buildzone:2,
		other:3,
		professionalConsultantCertificate:4,
		noWarranty:5 
	},

	/**
	* Represents the reference item RDHouseholdExpenditureTypeCode.
	**/
	RDHouseholdExpenditureTypeCode: {
		childCare:1,
		schoolFees:2,
		councilTax:3,
		sharedOwnershipRent:4,
		equityShareholderCharge:5,
		groundRentOrServiceCharge:6,
		essentialTravel:7,
		buildingsInsurance:8,
		utilities:9,
		housekeeping:10,
		tVPhoneMobileBroadband:13,
		otherInsurance:12,
		otherExpenditure:11,
		maintenance:14,
		tenancyRental:15,
		secondHomeRunningCosts:16,
		serviceCharge:17,
		groundRent:18 
	},

	/**
	* Represents the reference item RDIAndETimeframe.
	**/
	RDIAndETimeframe: {
		withinSixMonths:1,
		withinOneYear:2,
		withinTwoYears:3,
		withinFiveYears:4 
	},

	/**
	* Represents the reference item RDIncomeOrExpenditure.
	**/
	RDIncomeOrExpenditure: {
		income:1,
		expenditure:2 
	},

	/**
	* Represents the reference item RDIncomeTypeCode.
	**/
	RDIncomeTypeCode: {
		employmentIncome:1,
		otherIncome:2 
	},

	/**
	* Represents the reference item RDIncreaseOrDecrease.
	**/
	RDIncreaseOrDecrease: {
		increase:1,
		decrease:2 
	},

	/**
	* Represents the reference item RDIndividualTitleCode.
	**/
	RDIndividualTitleCode: {
		mr:1,
		mrs:2,
		miss:3,
		ms:4,
		dr:5,
		dame:6,
		lady:7,
		lord:8,
		professor:9,
		reverend:10,
		sir:11,
		mx:12 
	},

	/**
	* Represents the reference item RDIntermediaryCaseHomeNextSteps.
	**/
	RDIntermediaryCaseHomeNextSteps: {
	    applicationSubmittedNoOutstandingCaseRequirements: 1,
	    applicationSubmittedOutstandingCaseRequirements: 2,
	    caseCreated: 3,
	    dIPCompletedWithAcceptDecision: 4,
	    dIPCompletedWithDeclineDecisionNoRemodel: 5,
	    dIPCompletedWithDeclineDecisionWithRemodel: 6,
	    dIPCompletedWithReferDecision: 7,
	    illustrationsDone: 8,
	    partiallyCompletedDIP: 9,
	    partiallyCompletedFMA: 10,
	    partiallyCompletedProductSelection: 11,
	    productSelectionComplete: 12,
	    caseCancelled: 13,
	    caseLocked: 14,
	    paymentsOutstanding: 15,
	    paymentSuccessfulApplicationNotYetSubmitted: 16,
	    dIPExpired: 17,
	    selectedProductHasBeenWithdrawn: 18,
	    payOutstandingFeesByDate: 19,
	    caseDeclined: 20,
	    partiallyCompletedNAC: 21,
	    partiallyCompletedProductRecommendation: 22,
	    nACComplete: 23,
	    productRecommendationComplete: 24,
	    reviewPendingFollowingDecisionAppeal: 25,
	    applicationNotYetSubmittedNoPaymentsNeeded: 26,
	    fatalProductWithdraw: 27,
	    partiallyCompletedRateSwitch: 28,
	    mSOApplicantDataHasBeenRefreshedFromCRM: 29
	},

	/**
	* Represents the reference item RDIntermediaryCaseHomeNextStepsButtons.
	**/
	RDIntermediaryCaseHomeNextStepsButtons: {
		continueDecisionInPrinciple:1,
		continueFullMortgageApplication:2,
		continueSelectingProducts:3,
		continueToFullMortgageApplication:4,
		getADecision:5,
		getANewDecision:6,
		getAnIllustration:7,
		promoteToDecisionInPrinciple:8,
		selectProduct:9,
		makeAPayment:10,
		submitApplication:11 
	},

	/**
	* Represents the reference item RDIntermediaryType.
	**/
	RDIntermediaryType: {
		directlyAuthorised:1,
		appointedRepresentative:2 
	},

	/**
	* Represents the reference item RDLettingType.
	**/
	RDLettingType: {
		singleOccupancySingleAssuredShortholdTenancy:1,
		singleOccupancySingleShortAssuredTenancy:2,
		singleOccupancyStatutoryPeriodicTenancy:3,
		singleOccupancyPrivateTenancyCompliantWithRentOrder:4,
		singleOccupancyCorporateLet:5,
		hMOSingleAssuredShortholdTenancy:6,
		hMOSingleShortAssuredTenancy:7,
		hMOPrivateTenancyCompliantWithRentOrder:8,
		hMOMultipleAssureShortholdTenancies:9,
		hMOStatutoryPeriodicTenancy:10,
		unknown:11 
	},

	/**
	 * Represents the reference item RDLevelOfService.
	 **/
	RDLevelOfService: {
		adviceGiven:1,
		noAdviceGiven:2,
		executionOnlyRejectedAdvice:3,
		executionOnlyHNWCustomer:4,
		executionOnlyMortgageProfessional:5,
		executionOnlyNonInteractiveSale:6
	},

	/**
	* Represents the reference item RDManualAssessmentOutcomeType.
	**/
	RDManualAssessmentOutcomeType: {
		approve:1,
		decline:2,
		suspend:3 
	},

	/**
	* Represents the reference item RDManualAssessmentRuleStatus.
	**/
	RDManualAssessmentRuleStatus: {
		success:1,
		failure:2,
		overridden:3,
		cancelled:4 
	},

	/**
	* Represents the reference item RDManualAssessmentType.
	**/
	RDManualAssessmentType: {
		caseAssessment:1,
		packaging:2,
		propertyInternalInspection:3,
		propertyExternalInspection:4,
		reinspection:5,
		certificateOfTitle:6 
	},

	/**
	* Represents the reference item RDManualConditionAction.
	**/
	RDManualConditionAction: {
		modifyCondition:1,
		removeCondition:2 
	},

	/**
	* Represents the reference item RDMaritalStatus.
	**/
	RDMaritalStatus: {
		single:1,
		marriedcivilPartner:2,
		divorceddissolvedcivilpartnership:3,
		widowedsurvivingCivilPartner:4,
		separated:5,
		cohabiting:6,
		notDisclosed:7 
	},

	/**
	* Represents the reference item RDMemorableQuestions.
	**/
	RDMemorableQuestions: {
		questiona:1,
		questionb:2,
		questionc:3,
		questiond:4,
		questione:5,
		questionf:6,
		questiong:7,
		questionh:8 
	},

	/**
	* Represents the reference item RDMonthCode.
	**/
	RDMonthCode: {
		january:1,
		february:2,
		march:3,
		april:4,
		may:5,
		june:6,
		july:7,
		august:8,
		september:9,
		october:10,
		november:11,
		december:12 
	},

	/**
	* Represents the reference item RDMortgageDepositSourceCode.
	**/
	RDMortgageDepositSourceCode: {
		savings:1,
		loan:2,
		builderCashback:3,
		vendorCashbackOrIncentive:4,
		equity:5,
		gift:6,
		lSAPOrFHTB:7,
		housingAssociationRSL:8,
		tenantsIncentiveScheme:9 
	},

	/**
	* Represents the reference item RDMortgageGuaranteeTypeCode.
	**/
	RDMortgageGuaranteeTypeCode: {
		helpToBuy:1,
		mINewHome:2,
		newbuy:3 
	},

	/**
	* Represents the reference item RDMortgagePreferenceOptions.
	**/
	RDMortgagePreferenceOptions: {
		strongPreference:1,
		preference:2,
		noPreference:3 
	},

	/**
	* Represents the reference item RDNationalityCode.
	**/
	RDNationalityCode: {
		british:1,
		austrian:2,
		belgian:3,
		bulgarian:4,
		cypriot:5,
		czech:6,
		danish:7,
		dutch:8,
		estonian:9,
		finnish:10,
		french:11,
		german:12,
		greek:13,
		hungarian:14,
		icelandic:15,
		irish:16,
		italian:17,
		latvian:18,
		liechtensteiner:19,
		lithuanian:20,
		luxembourger:21,
		maltese:22,
		norwegian:23,
		polish:24,
		portuguese:25,
		romanian:26,
		slovakian:27,
		slovenian:28,
		spanish:29,
		swedish:30,
		swiss:31,
		otherOutsideEEA:32 
	},

	/**
	* Represents the reference item RDNewPurchaseKillerQuestions.
	**/
	RDNewPurchaseKillerQuestions: {
		newPurchaseQuestionOne:1,
		newPurchaseQuestionTwo:2,
		newPurchaseQuestionThree:3,
		newPurchaseQuestionFour:4,
		newPurchaseQuestionFive:5 
	},

	/**
	* Represents the reference item RDOccupancyStatusCode.
	**/
	RDOccupancyStatusCode: {
		ownerOccupier:1,
		privateTenant:2,
		rentingFromHousingAssociation:3,
		councilTenant:4,
		livingWithFriendsOrRelations:5,
		providedByEmployer:6 
	},

	/**
	* Represents the reference item RDOccupationTypeCode.
	**/
	RDOccupationTypeCode: {
		accountant:1,
		actor:2,
		administrationAssistant:3,
		analyst:4,
		architect:5,
		armedForces:6,
		artist:7,
		bailiff:8,
		banker:9,
		barWorker:10,
		barber:11,
		beautician:12,
		bookmaker:13,
		bricklayer:14,
		broker:15,
		builder:16,
		busDriver:17,
		businessConsultant:18,
		butcher:19,
		callCentreOperator:20,
		carDealer:21,
		careWorker:22,
		caretaker:23,
		carpenter:24,
		carpetFitter:25,
		cashier:26,
		caterer:27,
		charteredSurveyor:28,
		chauffeur:29,
		chef:30,
		chiropodist:31,
		chiropractor:32,
		civilServant:33,
		cleaner:34,
		clericalWorker:35,
		collegeLecturer:36,
		consultant:37,
		contractor:38,
		cook:39,
		councilWorker:40,
		customerServiceAdvisor:41,
		deliveryDriver:42,
		dentist:43,
		director:44,
		doctor:45,
		drivingInstructor:46,
		electrician:47,
		farmer:48,
		farrier:49,
		firefighter:50,
		fitnessInstructor:51,
		forestRanger:52,
		gardener:53,
		government:54,
		hairdresser:55,
		hGVDriver:56,
		jeweller:57,
		judge:58,
		lawyer:59,
		lecturer:60,
		librarian:61,
		management:62,
		managingDirector:63,
		manualWorker:64,
		marketTrader:65,
		model:66,
		musician:67,
		nurse:68,
		paramedic:69,
		photographer:70,
		physiotherapist:71,
		plumber:72,
		policeForce:73,
		projectManager:74,
		propertyDeveloper:75,
		publicRelationsPerson:76,
		publican:77,
		shopAssistant:78,
		socialWorker:79,
		softwareEngineer:80,
		solicitor:81,
		sportsTrainer:82,
		taxiDriver:83,
		teacher:84,
		technologyManager:85,
		vet:86,
		technologySystemsDeveloper:87,
		treeSurgeon:88,
		windowCleaner:89,
		other:90,
		iTContractorOrConsultant:91
	},

	/**
	* Represents the reference item RDOfferConditionType.
	**/
	RDOfferConditionType: {
		automated:1,
		manual:2 
	},

	/**
	* Represents the reference item RDOfferExpiryOptions.
	**/
	RDOfferExpiryOptions: {
		extendOfferGracePeriod:1,
		cancelCase:2 
	},

	/**
	* Represents the reference item RDOfferStatus.
	**/
	RDOfferStatus: {
		latest:1,
		invalid:2,
		superseded:3,
		expired:4 
	},

	/**
	* Represents the reference item RDOfferType.
	**/
	RDOfferType: {
		newOffer:1,
		revisedOffer:2 
	},

	/**
	* Represents the reference item RDOwnerOccupationKillerQuestions.
	**/
	RDOwnerOccupationKillerQuestions: {
		ownerOccupationQuestionOne:1,
		ownerOccupationQuestionTwo:2,
		ownerOccupationQuestionThree:3,
		ownerOccupationQuestionFour:4,
		ownerOccupationQuestionFive:5 
	},

	/**
	* Represents the reference item RDPayeeType.
	**/
	RDPayeeType: {
		solicitor:1,
		applicant:2,
		secondChargeHolder:3,
		other:4 
	},

	/**
	* Represents the reference item RDPaymentMethodType.
	**/
	RDPaymentMethodType: {
		bACS:1,
		cHAPS:2,
		fasterPayment:3 
	},

	/**
	* Represents the reference item RDPaymentRouteType.
	**/
	RDPaymentRouteType: {
		mortgageClub:1,
		network:2,
		distributor:3 
	},

	/**
	* Represents the reference item RDPCVReason.
	**/
	RDPCVReason: {
		changeOfProperty:1,
		changeOfProduct:2 
	},

	/**
	* Represents the reference item RDPCVReasonDIP.
	**/
	RDPCVReasonDIP: {
		pCVDIPReasonA:1,
		pCVDIPReasonB:2,
		pCVDIPReasonC:3,
		pCVDIPReasonD:4,
		pCVDIPReasonE:5,
		pCVDIPReasonF:6,
		pCVDIPReasonG:7,
		pCVDIPReasonH:8 
	},

	/**
	* Represents the reference item RDPCVReasonFMA.
	**/
	RDPCVReasonFMA: {
		pCVFMAReasonA:1,
		pCVFMAReasonB:2,
		pCVFMAReasonC:3,
		pCVFMAReasonD:4 
	},

	/**
	* Represents the reference item RDPhysicalValuationType.
	**/
	RDPhysicalValuationType: {
		externalInspectionReport:1,
		internalInspectionReport:2,
		reinspectionReport:3,
		transcriptionReport:4 
	},

	/**
	* Represents the reference item RDPolicySet.
	**/
	RDPolicySet: {
		manualAssessment:1,
		manualPackaging:2,
		iIRPolicy:3,
		eIRPolicy:4,
		cOTPolicy:5,
		rIRPolicy:6 
	},

	/**
	* Represents the reference item RDPreferredActionIfOverBudget.
	**/
	RDPreferredActionIfOverBudget: {
		increaseTheirBudget:1,
		increaseTheirMortgageTerm:2 
	},

	/**
	* Represents the reference item RDPreferredActionIfUnderBudget.
	**/
	RDPreferredActionIfUnderBudget: {
		keepToTheirPreferredMortgageTerm:1,
		reduceTheMortgageTerm:2 
	},

	/**
	* Represents the reference item RDPreferredContactMethod.
	**/
	RDPreferredContactMethod: {
		homeTelephone:1,
		workTelephone:2,
		mobileTelephone:3,
		email:4 
	},

	/**
	* Represents the reference item RDPreferredTermReason.
	**/
	RDPreferredTermReason: {
		payOffMortgageBeforeRetirement:1,
		longestTermAvailableToMinimiseRepayments:2,
		matchTheTermOfExistingLoan:3,
		payOffTheMortgageAsSoonAsPossible:4,
		inLineWithTheirBudget:5,
		byACertainDate:6,
		inLineWithRepaymentVehicle:7,
		beyondRetirementAge:8,
		keepExistingTerm:9,
		other:10 
	},

	/**
	* Represents the reference item RDProductFeatures.
	**/
	RDProductFeatures: {
		cashbackPercentage:1,
		cashbackFixedAmount:2,
		freeLegals:3,
		freeValuation:4,
		custom:99
	},

	/**
	* Represents the reference item RDProductSortOptions.
	**/
	RDProductSortOptions: {
		description:1,
		code:2,
		initialinterestrate:3,
		initialmonthlypayment:4 
	},

	/**
	* Represents the reference item RDPropertyBuildType.
	**/
	RDPropertyBuildType: {
		standard:0,
		newBuild:1,
		selfBuild:2,
		newSelfBuild:3,
		barnConversion:4,
		newlyConverted:8,
		newBarnConversion:12,
		newlyRefurbished:16 
	},

	/**
	* Represents the reference item RDPropertyExpenditureTypeCode.
	**/
	RDPropertyExpenditureTypeCode: {
		councilTax:3,
		sharedOwnershipRent:4,
		equityShareholderCharge:5,
		groundRentOrServiceCharge:6,
		buildingsInsurance:8,
		utilities:9,
		serviceCharge:17,
		groundRent:18 
	},

	/**
	* Represents the reference item RDPropertyOwnershipTypeCode.
	**/
	RDPropertyOwnershipTypeCode: {
		standard:1,
		sharedOwnership:2,
		rightToBuy:3,
		equityShare:4,
		mortgageGuarantee:5,
		purchaseFromFamilyMember:6 
	},

	/**
	* Represents the reference item RDPropertyPurposeTypeCode.
	**/
	RDPropertyPurposeTypeCode: {
		ownerOccupation:1,
		secondProperty:2,
		buyToLet:3 
	},

	/**
	* Represents the reference item RDPropertyTenureCode.
	**/
	RDPropertyTenureCode: {
		commonhold:1,
		freehold:2,
		leasehold:3,
		ownershipScotland:4 
	},

	/**
	* Represents the reference item RDPropertyTypeCode.
	**/
	RDPropertyTypeCode: {
		terracedHouse:1,
		semiDetachedHouse:2,
		detachedHouse:3,
		terracedBungalow:4,
		semiDetachedBungalow:5,
		detachedBungalow:6,
		convertedFlatOrMaisonette:7,
		purposeBuiltFlatOrMaisonette:8,
		selfContainedFlatOrMaisonette:9,
		selfContainedStudioFlat:10,
		other:11 
	},

	/**
	* Represents the reference item RDReasonForCashbackProduct.
	**/
	RDReasonForCashbackProduct: {
		other:1 
	},

	/**
	* Represents the reference item RDReasonForFixedPayments.
	**/
	RDReasonForFixedPayments: {
		easierToBudget:1,
		youngFamily:2,
		firstMortgage:3,
		other:4 
	},

	/**
	* Represents the reference item RDReasonForNoERCsPayable.
	**/
	RDReasonForNoERCsPayable: {
		other:1 
	},

	/**
	* Represents the reference item RDReasonForPortableProduct.
	**/
	RDReasonForPortableProduct: {
		other:1 
	},

	/**
	* Represents the reference item RDReasonForRepaymentBasis.
	**/
	RDReasonForRepaymentBasis: {
		guaranteeMortgageIsPaidOff:1,
		customerHasExistingRepaymentVehiclesInPlace:2,
		other:3 
	},

	/**
	* Represents the reference item RDReasonToAddFeesToLoan.
	**/
	RDReasonToAddFeesToLoan: {
		noSavingsAvailable:1,
		other:2 
	},

	/**
	* Represents the reference item RDReasonToAvoidProductFee.
	**/
	RDReasonToAvoidProductFee: {
		noSavingsAvailable:1,
		other:2 
	},

	/**
	* Represents the reference item RDReasonToBenefitFromRateReduction.
	**/
	RDReasonToBenefitFromRateReduction: {
		savingsAvailable:1,
		futureIncomeIncrease:2,
		other:3 
	},

	/**
	* Represents the reference item RDReasonToPayFeesUpfront.
	**/
	RDReasonToPayFeesUpfront: {
		savingsAvailable:1,
		doesntWantToPayInterestOnTheFee:2,
		other:3 
	},

	/**
	* Represents the reference item RDReasonToVaryRepaymentAmountOrTakeRepaymentHolidays.
	**/
	RDReasonToVaryRepaymentAmountOrTakeRepaymentHolidays: {
		other:1 
	},

	/**
	* Represents the reference item RDRejectRegistrationRequestReason.
	**/
	RDRejectRegistrationRequestReason: {
		rejectionReasonA:1,
		rejectionReasonB:2,
		rejectionReasonC:3,
		rejectionReasonD:4,
		rejectionReasonE:5,
		other:6 
	},

	/**
	* Represents the reference item RDRemittanceStatus.
	**/
	RDRemittanceStatus: {
		pending:1,
		cancelled:2,
		uploaded:3,
		released:4,
		returned:5,
		authorised:8 
	},

	/**
	* Represents the reference item RDRemittanceType.
	**/
	RDRemittanceType: {
		initialAdvance:1,
		incentive:2 
	},

	/**
	* Represents the reference item RDRemortgageKillerQuestions.
	**/
	RDRemortgageKillerQuestions: {
		remortgageQuestionOne:1,
		remortgageQuestionTwo:2,
		remortgageQuestionThree:3,
		remortgageQuestionFour:4,
		remortgageQuestionFive:5 
	},

	/**
	* Represents the reference item RDRepaymentStrategyType.
	**/
	RDRepaymentStrategyType: {
		iSA:1,
		endowmentPolicy:2,
		pensionPlan:3,
		capitalFromTrustFunds:4,
		premiumBonds:5,
		uKFTSEListedSecuritiesAndShares:6,
		unitTrusts:7,
		investmentTrusts:8,
		savings:9,
		saleOfOtherPropertyInTheUK:10,
		saleOfForeignProperty:11,
		saleOfTheSecurityProperty:12,
		stocksAndShares:13 
	},

	/**
	* Represents the reference item RDReturnOfControlReason.
	**/
	RDReturnOfControlReason: {
		completionDateUpdate:1,
		requestNewCOT:2,
		caseDataUpdate:3,
		suspendCase:4,
		cancelCase:5 
	},

	/**
	* Represents the reference item RDSearchSummaryStages.
	**/
	RDSearchSummaryStages: {
		opencases:1,
		preoffer:2,
		postoffer:3,
		awaitingsubmission:4,
		completedinlastdays:5 
	},

	/**
	* Represents the reference item RDSecondPropertyKillerQuestions.
	**/
	RDSecondPropertyKillerQuestions: {
		secondPropertyQuestionOne:1,
		secondPropertyQuestionTwo:2,
		secondPropertyQuestionThree:3,
		secondPropertyQuestionFour:4,
		secondPropertyQuestionFive:5 
	},

	/**
	* Represents the reference item RDSolarPanelFitment.
	**/
	RDSolarPanelFitment: {
		no:1,
		yesTheyAreLeased:2,
		yesTheyAreOwnedWithNoLeaseInPlace:3,
		yesOwnershipOfPanelsNotKnown:4 
	},

	/**
	* Represents the reference item RDSourceOfCurrentOtherIncomeCode.
	**/
	RDSourceOfCurrentOtherIncomeCode: {
		investmentIncome:1,
		maintenanceIncome:2,
		rentalIncomeFromAnUnencumberedProperty:3,
		pensionIncome:4,
		stateBenefits:5,
		stateBenefitsForTheDisabled:6,
		pensionCredit:7,
		childBenefit:8,
		universalCreditOrTaxCredits:9,
		dividendIncome:10,
		annuityIncome:11,
		mortgageSubsidy:12,
		shiftAllowance:13,
		additionalDutyHours:14,
		nursingBank:15,
		attendanceAllowance:16,
		childTaxCredit:17,
		fosterCareAllowance:18,
		guardiansAllowance:19,
		employmentAndSupportAllowance:20,
		industrialInjuriesDisablementBenefit:21,
		carersAllowance:22,
		adoptionAllowance:23,
		bursary:24,
		colleagueFlexibleBenefit:25,
		constantAttendanceAllowance:26,
		flightPayAllowance:27,
		pensionState:28,
		pensionWarPensionScheme:29,
		pensionWarWidow:30,
		personalIndependencePayment:31,
		stipend:32,
		widowedParentsAllowance:33,
		pensionPrivate:34 
	},

	/**
	* Represents the reference item RDSourceOfFutureIncomeCode.
	**/
	RDSourceOfFutureIncomeCode: {
		investmentIncome:1,
		maintenanceIncome:2,
		rentalIncomeFromAnUnencumberedProperty:3,
		pensionIncome:4,
		stateBenefits:5,
		stateBenefitsForTheDisabled:6,
		pensionCredit:7,
		childBenefit:8,
		universalCreditOrTaxCredits:9,
		dividendIncome:10,
		annuityIncome:11,
		mortgageSubsidy:12,
		shiftAllowance:13,
		additionalDutyHours:14,
		nursingBank:15,
		attendanceAllowance:16,
		childTaxCredit:17,
		fosterCareAllowance:18,
		guardiansAllowance:19,
		employmentAndSupportAllowance:20,
		industrialInjuriesDisablementBenefit:21,
		carersAllowance:22,
		adoptionAllowance:23,
		bursary:24,
		colleagueFlexibleBenefit:25,
		constantAttendanceAllowance:26,
		flightPayAllowance:27,
		pensionState:28,
		pensionWarPensionScheme:29,
		pensionWarWidow:30,
		personalIndependencePayment:31,
		stipend:32,
		widowedParentsAllowance:33,
		pensionPrivate:34 
	},

	/**
	* Represents the reference item RDSpecialCorrespondenceTypeCode.
	**/
	RDSpecialCorrespondenceTypeCode: {
		none:0,
		audio:1,
		braille:2,
		largePrint:3 
	},

	/**
	* Represents the reference item RDSuspendCaseReasonsCode.
	**/
	RDSuspendCaseReasonsCode: {
		propertyFallenThrough:1,
		customerOrIntermediaryRequestedCaseBePutOnHold:2,
		other:3,
		productUnacceptable:4,
		propertyPostcodeUnacceptable:5,
		pCVWithChangeOfProperty:6,
		pCVOnSameProperty:7 
	},

	/**
	* Represents the reference item RDTaskState.
	**/
	RDTaskState: {
		created:1,
		available:2,
		owned:4,
		active:8,
		paused:16,
		suspended:32,
		cancelled:64,
		completed:128,
		completionPending:256 
	},

	/**
	* Represents the reference item RDTaskType.
	**/
	RDTaskType: {
		captureCOTDetails:1,
		manuallyAssessCOT:2,
		reviewDIPDecision:3,
		investigateSystemError:4,
		requestCaseRequirement:5,
		chaseCaseRequirement:6,
		finalChaseCaseRequirement:7,
		verifyIncomingDocument:8,
		processFees:9,
		manuallyAssessCase:10,
		investigateFraudReferral:11,
		reviewOfferExpiry:12,
		brokerOrFirmUnderFraudReview:14,
		reviewUnsolicitedCot:15,
		inputValuationReport:17,
		reviewInternalInspectionReport:19,
		reviewValuationExpiry:20,
		instructValuation:22,
		reviewValuationStatus:23,
		authoriseFundsRelease:25,
		manuallyUploadCase:26,
		reviewConveyancer:27,
		reissueOffer:45,
		cancelValuationReport:46,
		reviewSuspendedCase:47,
		callToAction:48,
		manuallyPackageCase:51 
	},

	/**
	* Represents the reference item RDTenancyLength.
	**/
	RDTenancyLength: {
		bandA:1,
		bandB:2,
		bandC:3,
		bandD:4 
	},

	/**
	* Represents the reference item RDUKCountryCode.
	**/
	RDUKCountryCode: {
		england:1,
		scotland:2,
		northernIreland:3,
		wales:4 
	},

	/**
	* Represents the reference item RDUpgradeReason.
	**/
	RDUpgradeReason: {
		unknown:0,
		outsideLenderCriteria:1,
		insufficientAccess:2,
		conditionWarrantsUpgrade:3 
	},

	/**
	* Represents the reference item RDValidDocUploadExts.
	**/
	RDValidDocUploadExts: {
		pDF:1,
		tIF:2,
		tIFF:3,
		jPG:4,
		jPEG:5 
	},

	/**
	* Represents the reference item RDValuationReviewOutcomeType.
	**/
	RDValuationReviewOutcomeType: {
		approve:1,
		requestARevisedValuation:2,
		securityPropertyUnacceptableSuspendCase:3,
		preOfferReinspection:4,
		postOfferReinspection:5 
	},

	/**
	* Represents the reference item RDValuationRevisionReasonsCode.
	**/
	RDValuationRevisionReasonsCode: {
		specialistReportsReceived:1,
		addressRequiresAmendment:2,
		tenureOrTermIncorrect:4,
		propertyDescriptionSectionRequiresAmendment:5,
		propertyConstructionSectionRequiresAmendment:6,
		supportingCommentsRequireClarification:7,
		newPropertiesSectionRequiresAmendment:8,
		reportValuesIncorrect:9,
		multipleAmendmentsRequired:10,
		valuerRequestedAmendment:11,
		other:12 
	},

	/**
	* Represents the reference item RDValuationStatus.
	**/
	RDValuationStatus: {
		notInstructed:0,
		instructed:1,
		scheduled:2,
		onHold:3,
		received:4,
		underReview:5,
		approved:6,
		upgradeRequired:7,
		revisionRequired:8,
		revisionInstructed:9,
		cancelled:10,
		expired:11,
		revalidationRequired:12,
		unsolicited:13,
		rejected:14,
		superseded:15,
		reinspectionRequired:16 
	},

	/**
	* Represents the reference item RDValuationType.
	**/
	RDValuationType: {
		mortgageValuationReport:1,
		mortgageValuationReportWithHomebuyersReport:2,
		mortgageValuationReportWithBuildingSurvey:3,
		transcriptionOfScottishHomeReport:4,
		noUpgradeRequired:5,
		upgradeToBuildingSurvey:6,
		upgradeToHomebuyersReport:7,
		buyToLetValuation:8,
		postOfferReinspection:9,
		preOfferReinspection:10,
		aVM:11,
		reinspection:12,
		buyToLetBuildingsSurvey:14,
		buyToLetHomebuyersReport:15,
		upgradeToBuyToLetBuildingsSurvey:16,
		upgradeToBuyToLetHomebuyersReport:17,
        externalValuationReport:18,
        noUpgradeRequiredFromEIR:19,
		initialPhysicalValuationTypes:99 
	},

	/**
	* Represents the reference item RDValuerQualification.
	**/
	RDValuerQualification: {
		fRICS:1,
		mRICS:2,
		assocRICS:3,
		other:4 
	},

	/**
	* Represents the reference item RDWhatWillHappenToExistingMortgage.
	**/
	RDWhatWillHappenToExistingMortgage: {
		mortgageToBeRepaid:1,
		mortgageToBeRetained:2 
	},
	/**
	 * Represents the reference item RDBuildingStandardsIndemnitySchemes.
	 **/
	RDBuildingStandardsIndemnitySchemes: {
		none:1,
		other:2,
		nHBC:3,
		premierGuarantee:4,
		lABCNewHomeWarrantyOrCompletedHomesWarranty:5,
		buildingsLifePlans:6,
		castleTen:7,
		cRL:8,
		consultantMonitored:9
	}
});
