'use strict';

angular.module('mso.directives')
.service('saveDirtyFormService', ['$rootScope', '$modal', '$location', '$window', function ($rootScope, $modal, $location, $window) {
    var forms = [];
    var eventsRegistered = [angular.noop];

    this.init = function (formContrl) {
        forms.push(formContrl);
        var sidebarNavEvent = $rootScope.$on('sidebarNav:click', function($event, navbarItem) {
            $rootScope.$broadcast("reEvaluateFields");
            if (isFormDirty()) {
                if (isFormInvalid()) {
                    var modalInstance = showModalConfirm('mso-dirty-form-confirm.html');
                    modalInstance.result.then(function () {
                        $rootScope.$broadcast('saveDirtyForm', [navbarItem]);
                    }, function () {
                        discardFormChanges();
                        $rootScope.$broadcast('dirtyFormDiscarded', navbarItem);
                        unattachEvents();
                        $location.path(navbarItem.link);

                    });
                }
                else{
                    $rootScope.$broadcast('saveDirtyForm', [navbarItem]);
                }
            } else {
                unattachEvents();
                $location.path(navbarItem.link);
            }
        });


        var dirtyEvent = $rootScope.$on('dirtyCheck:click', function($event, navigationItem) {
            if (isFormDirty()) {

                var modalInstance = showModalConfirm('mso-save-dirty-form-v2.template.html');
                modalInstance.result.then(function () {
                    $rootScope.$broadcast('handleDirtyForm', [navigationItem]);
                }, function (dismissResult) {
                    if (dismissResult.cancel == true) {
                        // Do nothing if the user cancels
                    } else {
                        discardFormChanges();
                        $rootScope.$broadcast('dirtyFormDiscarded', navigationItem);
                        unattachEvents();
                        $window.location.href = navigationItem.link;
                    }
                });

            } else {
                unattachEvents();
                $location.path(navigationItem.link);
            }
        });



        eventsRegistered.push(sidebarNavEvent);

        eventsRegistered.push(dirtyEvent);



    };

    function isFormDirty(){
        var dirty = false;
        angular.forEach(forms, function(item){
            if (item.$dirty){
                dirty = true;
            }
        });
        return dirty;
    }

    function isFormInvalid(){
        var invalid = false;
        angular.forEach(forms, function(item){
            if (item.$invalid){
                invalid = true;
            }
        });
        return invalid;
    }

    function discardFormChanges(){
        angular.forEach(forms, function(item){
            if (item.$dirty){
                item.$setPristine();
            }
        });
        return false;
    }

    function showModalConfirm(templateName){
        return $modal.open({
            template: require(`/app/template/${templateName}`).default,
            /*controller: 'saveDirtyFormCtrl',*/
            backdrop: 'static'
        });
    }

    this.removeForm = function(form) {
        var idx = forms.indexOf(form);
        if (idx === -1) return;

        forms.splice(idx, 1);
        if (forms.length === 0) unattachEvents();
    };

    function unattachEvents() {
        angular.forEach(eventsRegistered, function(fn) {
            fn();
        });
    }
}])

/*.controller('saveDirtyFormCtrl',['$scope', '$modalInstance', function($scope, $modalInstance){
    $scope.saveDirtyForm = function(){
        $modalInstance.close();
    };

    $scope.discardDirtyForm = function(){
        $modalInstance.dismiss();
    };

}])*/

.directive('saveDirtyForm',['saveDirtyFormService', function(saveDirtyFormService){
    return {
        require: 'form',
        link: function (scope, formElement, attrs, formCtrl) {

            saveDirtyFormService.init(formCtrl);

            scope.$on('$destroy', function() {
                saveDirtyFormService.removeForm(formCtrl);
            });

        }
    }

}]);


