
    angular.module('mso.authentication')
        .constant('ReferenceDataMap', [
            {
                when: '',
                valuesets: [],
                messages: ['4271', '4419', '4420', '4421', '5029'],
                parameters: ['p6084', 'p6030', 'p6845']
            },
            {
                when: '/',
                valuesets: [],
                messages: ['4271', '4419', '4420', '4421', '5029'],
                parameters: ['p6084', 'p6030', 'p6845']
            },
            {
                when: ':token',
                valuesets: [],
                messages: ['4271', '4419', '4420', '4421', '5029'],
                parameters: ['p6084', 'p6030', 'p6845']
            },
            {
                when: 'forgotten-user-name',
                valuesets: [],
                messages: ['3001', '4337', '4338', '4339', '3025'],
                parameters: ['p6084','p6259', 'p6260', 'p6261']
            },
            {
                when: 'forgotten-password',
                valuesets: [],
                messages: ['3001', '3009', '4282','4273','4272', '3025'],
                parameters: ['p6084','p6259', 'p6260', 'p6261']
            },
            { when:'#Header#',
                valuesets : [],
                messages : [ ],
                parameters : ['p6256', 'p6257']
            },
            {
                when: 'session-expiry-service',
                valuesets: [],
                messages: [ ],
                parameters: ['p3055']
            },
            {
                when: 'timeout',
                valuesets: [],
                messages: [ ],
                parameters: []
            }
        ]
    );


