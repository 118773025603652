(function () {
    'use strict';
    angular.module('mso.authentication')
        .controller('ForgottenUserNameCtrl', ForgottenUserNameCtrl);

    ForgottenUserNameCtrl.$inject = ['ForgottenUserNameService', '$exceptionHandler', 'referenceData', '$location', '$window'];
    function ForgottenUserNameCtrl(ForgottenUserNameService, $exceptionHandler, referenceData, $location, $window) {

        var vm = this;

        vm.messages = referenceData.messages;
        vm.parameters = referenceData.parameters;

        // Email address validation parameters. P6259 - pValidMSOEmailFormat, P6260 - pMinEmailAddressLength, P6261 - pMaxEmailAddressLength.
        vm.emailAddressRegEx = new RegExp(vm.parameters.p6259);
        vm.emailMinCharacterLength = vm.parameters.p6260;
        vm.emailMaxCharacterLength = vm.parameters.p6261;

        // Functions exposed to the view
        vm.forgottenUserName = forgottenUserName;
        vm.backToLogin = backToLogin;

        function forgottenUserName(form) {
            vm.forgottenUserNameRequestError = false;
            vm.forgottenUserNameRequestSubmitted = false;

            if (form.$invalid) {
                vm.showValidation = true;
            }
            else {
                ForgottenUserNameService.forgottenUserName(vm.emailAddress)
                    .then(function () {
                        vm.forgottenUserNameRequestSubmitted = true;
                    }, function (error) {
                        if(error.status == '400') {
                            vm.forgottenUserNameRequestError = true;
                        }
                        else{
                            $exceptionHandler(error);
                        }
                    });
            }
        }

        function backToLogin() {
            var location = '';
            location += $location.protocol() + '://' + $location.host() + ':' + $location.port();
            location += '/index.html';

            $window.location.href = location;
        }
    }
})();