(function() {
    'use strict';
    angular.module('mso.directives')
        .directive('headerNav', headerNav);

    function headerNav() {
        var directive = {
            restrict: 'EA',
            controller: headerNavCtrl,
            controllerAs: 'vm',
            template: require('/app/template/header-nav.template.html').default,
        };

        headerNavCtrl.$inject = ['$scope', 'CaseService', '$window'];
        return directive;

        function headerNavCtrl($scope, CaseService, $window) {
            var vm = this;
            $scope.$on("case_context_changed", function () {
                vm.caseId = CaseService.getCaseId();
                vm.currentPageHref = $window.location.href.replace('#', '%23');
            });
        }
    }
})();
