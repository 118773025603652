(function() { 'use strict';
    angular.module('mso.directives')
        .directive('securityQuestion', securityQuestion);

    function securityQuestion() {
        var directive = {
            restrict: 'EA',
            controller: securityQuestionController,
            controllerAs: 'vm',
            template: require('/app/template/security-question.template.html').default,
            scope: {
                viewmodel: '=',
                referenceData: '=',
                showValidation: '=',
                chosenSecurityQuestions: '=',
                suffix: '='
            }
        };

        securityQuestionController.$inject = ['$scope'];
        return directive;

        function securityQuestionController($scope) {
            var vm = this;

            vm.messages = $scope.referenceData.messages;
            vm.viewmodel = $scope.viewmodel;
            vm.chosenSecurityQuestions = $scope.chosenSecurityQuestions;
            vm.securityQuestionTokens = ['2', '999', 'characters'];

            $scope.$watchCollection('vm.chosenSecurityQuestions', filterSecurityQuestions);

            function filterSecurityQuestions() {
                $scope.memorableQuestions = $scope.referenceData.valuesets.rdMemorableQuestions.filter(function (item) {
                    return (vm.chosenSecurityQuestions.indexOf(item.value) === -1 ||
                    vm.viewmodel.securityQuestion === item.value);
                });
            }
        }
    }
})();
