angular.module('mso.directives')
    .directive('msoAddressEditor', ['$parse', function ($parse) {
        return {
            restrict: 'EA',
            controller: 'AddressEditorController',
            template: require('/app/template/mso-address-editor2.html').default,
            require: '^form',
            scope: {
                addressViewModel: '=viewModel',
                flagsViewModel: '=flagsViewModel',
                addressTypesToIgnore: '=',
                identifierPrefix: '=',
                form: '='
            },
            link: function (scope, el, attr, formCtrl) {
                var parentViewModel = $parse(attr.viewModel);
                scope.form = formCtrl;
                scope.readOnly = attr.readOnly || false;
                scope.$watch(scope.addressViewModel, function (value) {
                    if (value) {
                        parentViewModel = value;
                    }
                }, true);

                //for validation messages
                var showValidationMessages = $parse(attr.showValidationMessages);
                scope.showValidation = false;
                scope.$parent.$watch(showValidationMessages, function (value) {
                    scope.showValidation = value;
                });

            },
            replace: true
        };
    }])

    .controller('AddressEditorController', ['$scope', '$log', 'AddressSearchService', 'ReferenceDataService', '$timeout', '$exceptionHandler', function ($scope, $log, AddressSearchService, ReferenceDataService, $timeout, $exceptionHandler) {
            $scope.referenceDataServ = ReferenceDataService;
            $scope.addressSearchServ = AddressSearchService;

            $scope.$parent.$watch('showValidation', function(newValue){
                $scope.showValidation = newValue;
            });

            $scope.criticalAddressDataCaptured = function criticalAddressDataCaptured(address){
                if (address !== undefined) {
                    switch(address.addressType){
                        case 1:
                            return ((address.buildingName || address.buildingNo) && address.street &&
                                address.town && address.postcode);
                        case 2:
                            return (address.serviceNumber && address.rank && address.careOfSurname && address.unit &&
                                address.operationName && address.bfpoNumber);
                        case 3:
                            return (address.line1 && address.line2 && address.line3 &&
                                address.line4 && address.countryTypeCode);
                    }
                }
                return false;
            };

            var criticalAddressDataCaptured = $scope.criticalAddressDataCaptured($scope.addressViewModel);

            if (!$scope.flagsViewModel) {
                $scope.flagsViewModel = {};
            }

            $scope.flagsViewModel.showEdit = !criticalAddressDataCaptured;
            $scope.flagsViewModel.showDisplay = criticalAddressDataCaptured || false;
            $scope.flagsViewModel.showSearch = !criticalAddressDataCaptured;
            if($scope.flagsViewModel.showGoBack === undefined) {
                $scope.flagsViewModel.showGoBack = false;
            }

            $scope.flagsViewModel.showDisplayEdit = true;
            if ($scope.flagsViewModel.showAddressType === undefined) {
                $scope.flagsViewModel.showAddressType = true;
            }

            $scope.searchViewModel = {
                buildingNameNo: null,
                postcode: null,
                enterManually: false,
                selected: false,
                searchError: false,
                formatError: false,
                noResultsFound: false,
                results: null,
                searching: false,
                selectedAddress: null

            };

            $scope.addressTypes = { "uk": 1,
                "bfpo": 2,
                "overseas": 3};

            var addressEditorReferenceData = ReferenceDataService.getReferenceDataForView("directives/mso-address-editor");
            if (addressEditorReferenceData) {
                addressEditorReferenceData.then(function (data) {
                    $scope.addressEditorVs = data.valuesets;
                    $scope.addressEditorParameters = data.parameters;
                    $scope.addressEditorMessages = data.messages;
                    $scope.addressTypeCodes = $scope.addressEditorVs.rdAddressTypeCode;
                    if($scope.addressTypesToIgnore !== undefined || $scope.addressTypesToIgnore != null) {
                        var tempArray = [];
                        $scope.addressTypeCodes.forEach(function (type) {
                            if ($scope.addressTypesToIgnore.indexOf(type.value) === -1) {
                                tempArray.push(type);
                            }
                        });

                        $scope.addressTypeCodes = tempArray;
                    }

                }, function (error) {

                    $exceptionHandler(error);
                });
            }

            $scope.flatTokens = ['50', 'characters'];
            $scope.buildingNameTokens = ['35', 'characters'];
            $scope.buildingNoTokens = ['5', 'characters'];
            $scope.streetTokens = ['70', 'characters'];
            $scope.districtTokens = ['70', 'characters'];
            $scope.townTokens = ['30', 'characters'];
            $scope.countyTokens = ['50', 'characters'];
            $scope.postcodeTokens = ['10', 'characters'];
            $scope.serviceNumberTokens = ['50', 'characters'];
            $scope.rankTokens = ['50', 'characters'];
            $scope.careOfSurnameTokens = ['60', 'characters'];
            $scope.unitTokens = ['50', 'characters'];
            $scope.operationNameTokens = ['30', 'characters'];
            $scope.bfpoNumberTokens = ['8', 'characters'];
            $scope.overseasAddressLineTokens = ['50', 'characters'];

            $scope.searchAddress = function () {

                if (!$scope.searchViewModel.postcode) {

                    // If postcode is undefined, set variable to invalid.
                    $scope.showPostcodeValidation = true;

                    return;
                }

                $scope.showPostcodeValidation = false;

                $scope.searchViewModel.searchError = false;
                $scope.searchViewModel.formatError = false;
                $scope.searchViewModel.noResultsFound = false;
                $scope.searchViewModel.results = null;
                $scope.searchViewModel.searching = true;
                $scope.searchViewModel.selected = false;
                $scope.searchViewModel.newSearch = false;
                $scope.searchViewModel.enterManually = false;

                var data = $scope.addressSearchServ.searchAddresses($scope.searchViewModel.postcode, $scope.searchViewModel.buildingNameNo);
                data.then(function (data) {
                    $scope.searchViewModel.searching = false;

                    if (data.length > 0) {
                        $scope.searchViewModel.results = data;
                        if (data.length == 1) {
                            $scope.selectAddress(angular.copy(data[0]));
                        }
                    }
                    else {
                        $scope.searchViewModel.noResultsFound = true;
                    }
                }, function (err) {
                    $scope.searchViewModel.searching = false;
                    $scope.searchViewModel.searchError = true;
                    $log.error(err);
                });
            };

            $scope.addressSelected = function(event){
                if (event && event.currentTarget && event.currentTarget[0] && event.currentTarget[0].selected) {
                    // "Please select..." is still selected, so ignore
                } else {
                    $scope.selectAddress($scope.searchViewModel.selectedAddress);
                }
            };

            $scope.selectAddress = function (selectedAddress) {
                if (!selectedAddress) return;
                if (selectedAddress.formattedAddress != null) {
                    // We already have the formatted address, so use it
                    selectFormattedAddress(selectedAddress.formattedAddress);
                } else {
                    // We need to fetch the formatted address using the address Id
                    var formatPromise = $scope.addressSearchServ.formatAddress(selectedAddress.addressId);

                    formatPromise.then(function (data) {
                        selectFormattedAddress(angular.copy(data));
                    }, function (err) {
                        $scope.searchViewModel.formatError = true;
                        $scope.flagsViewModel.showDisplay = false;
                        $scope.flagsViewModel.showEdit = true;
                        $scope.flagsViewModel.showSearch = false;
                        $scope.searchViewModel.enterManually = true;
                        $log.error(err);
                    });
                }
            };

            function selectFormattedAddress(formattedAddress) {
                $scope.addressViewModel.addressType = $scope.addressTypes.uk;
                $scope.addressViewModel.buildingNo = formattedAddress.buildingNumber;
                $scope.addressViewModel.buildingName = formattedAddress.buildingName;
                $scope.addressViewModel.flat = formattedAddress.flat;
                $scope.addressViewModel.street = formattedAddress.street;
                $scope.addressViewModel.district = formattedAddress.district;
                $scope.addressViewModel.town = formattedAddress.town;
                $scope.addressViewModel.county = formattedAddress.county;
                $scope.addressViewModel.postcode = formattedAddress.postcode;

                //If missing mandatory data, show format error
                if (!$scope.criticalAddressDataCaptured($scope.addressViewModel)) {
                    $scope.searchViewModel.formatError = true;
                    $scope.flagsViewModel.showDisplay = false;
                    $scope.flagsViewModel.showEdit = true;
                    $scope.flagsViewModel.showSearch = false;
                    $scope.searchViewModel.enterManually = true;
                } else {
                    $scope.searchViewModel.selected = true;
                    $scope.flagsViewModel.showDisplay = true;
                    $scope.flagsViewModel.showEdit = false;
                    $scope.flagsViewModel.showSearch = false;
                }
            }

            $scope.onEnterManually = function () {

                $scope.searchViewModel.enterManually = true;

                //new
                $scope.flagsViewModel.showDisplay = false;
                $scope.flagsViewModel.showEdit = true;
                $scope.flagsViewModel.showSearch = ($scope.addressViewModel.addressType != $scope.addressTypes.uk);
                $scope.searchViewModel.selected = false;

                var elementIdToFocus = $scope.identifierPrefix + 'flat';
                if ($scope.addressViewModel.addressType == $scope.addressTypes.overseas) {
                    elementIdToFocus = $scope.identifierPrefix + 'serviceNumber';
                }
                else if ($scope.addressViewModel.addressType == $scope.addressTypes.bfpo) {
                    elementIdToFocus = $scope.identifierPrefix + 'addressline1';
                }

                var element = document.getElementById(elementIdToFocus);
                if(element) {
                    $timeout(function(){
                        element.focus();
                    },0);
                }

            };

            $scope.onGoBack = function () {
                $scope.flagsViewModel.showDisplay = true;
                $scope.flagsViewModel.showEdit = false;
                $scope.flagsViewModel.showSearch = false;
            };

            $scope.onSearchAgain = function () {
                $scope.searchViewModel.selected = false;
                $scope.searchViewModel.enterManually = false;
                $scope.addressViewModel.addressType = 1;
                $scope.searchViewModel.results = null;
                $scope.searchViewModel.formatError = false;

                $scope.flagsViewModel.showDisplay = false;
                $scope.flagsViewModel.showEdit = true;
                $scope.flagsViewModel.showSearch = true;

                var element = document.getElementById($scope.identifierPrefix + 'searchBuildingNameNo');
                if(element) {
                    $timeout(function(){
                        element.focus();
                    },0);
                }
            };
        }]);
