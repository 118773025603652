angular.module('mso.services').factory('IntermediaryInfoService',  ['$resource', '$q', 'Config', '$window', '$exceptionHandler', function($resource, $q, Config, $window, $exceptionHandler ) {

    var intermediaryInfoApi = $resource(Config.apiBaseHref + '/IntermediaryInfo', null, {'save': {method: 'PATCH'}});
    var brokerInfoApi = $resource(Config.apiBaseHref + '/IntermediaryInfo/:brokerId', null, {brokerId:'@brokerId'});

    // the one to allow people to tinker with

    function getBrokerInfo (brokerId){
        var deferred = $q.defer();
        var storedIntermediary;
        if (angular.isDefined($window.sessionStorage.intermediary)) {
            storedIntermediary = JSON.parse($window.sessionStorage.intermediary);
        }

        if (angular.isDefined(storedIntermediary) && angular.isDefined(storedIntermediary.impersonatedUser) &&
            storedIntermediary.impersonatedUser.id === brokerId) {
            deferred.resolve(storedIntermediary.impersonatedUser);
        }
        else {
            brokerInfoApi.get({brokerId: brokerId}).$promise.then(function (data) {
                if (angular.isDefined(storedIntermediary)) {
                    storedIntermediary.impersonatedUser = data;
                    $window.sessionStorage.intermediary = JSON.stringify(storedIntermediary);
                }

                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        }
        return deferred.promise;
    }

    var service = {
        getIntermediaryInfo: function () {
            var deferred = $q.defer();
            var storedIntermediary;

            if ($window.sessionStorage.intermediary)
                storedIntermediary = JSON.parse($window.sessionStorage.intermediary);

            if (angular.isDefined(storedIntermediary)) {
                deferred.resolve(storedIntermediary);
            }
            else {
                intermediaryInfoApi.get().$promise.then(function (data) {
                        var intermediaryDetails = data;

                        function administerForUser(administeredBrokers, brokerId){
                            var found = false;
                            for(var i = 0; i < administeredBrokers.length; i++){
                                if(administeredBrokers[i].id === brokerId){
                                    found = true;
                                    break;
                                }
                            }
                            return found;
                        }

                        if(angular.isDefined(storedIntermediary) && angular.isDefined(storedIntermediary.impersonatedUser) &&
                            angular.isDefined(intermediaryDetails.administeredBrokers) &&
                            administerForUser(intermediaryDetails.administeredBrokers, storedIntermediary.impersonatedUser.id)){
                            getBrokerInfo(storedIntermediary.impersonatedUser.id).then(function(data){
                                intermediaryDetails.impersonatedUser = data;
                                $window.sessionStorage.intermediary = JSON.stringify(intermediaryDetails);
                                deferred.resolve(intermediaryDetails);
                            }, function (error) {
                                deferred.reject(error);
                                $exceptionHandler(error);
                            });
                        }
                        else {
                            // store for later...
                            $window.sessionStorage.intermediary = JSON.stringify(intermediaryDetails);

                            // data should now be in the cache
                            deferred.resolve(intermediaryDetails);
                        }
                    },
                    function (error) {
                        deferred.reject(error);
                        $exceptionHandler(error);
                    });
            }
            return deferred.promise;

        },
        getBrokerInfo: getBrokerInfo
    };

    return service;



}]);