angular.module('mso').factory('LoadingInterceptor', ['$q', '$rootScope', function ($q, $rootScope) {

    var inProgressCounter = 0;

    return {
        request: function (config) {

            // Ignore requests that have asked for the spinner to be hidden
            if(!config || !config.hideLoading) {
                inProgressCounter++;
                $rootScope.$broadcast("show_loading");
            }

            return config || $q.when(config)

        },
        response: function (response) {

            // Ignore requests that have asked for the spinner to be hidden
            if (!response.config || !response.config.hideLoading) {
                inProgressCounter--;
            }

            if (inProgressCounter === 0) {
                // All real requests complete.
                $rootScope.$broadcast("hide_loading");
            }

            return response || $q.when(response);

        },
        responseError: function (response) {

            // Ignore requests that have asked for the spinner to be hidden
            if (!response.config || !response.config.hideLoading) {
                inProgressCounter--;
            }

            if (!inProgressCounter) {
                // All real requests complete.
                $rootScope.$broadcast("hide_loading");
            }

            return $q.reject(response);
        }
    };
}])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('LoadingInterceptor');
    }]);
