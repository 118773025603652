angular.module('mso.directives')
    .directive('msoLinkMessage', function () {
        return {
            restrict: 'EA',
            controller: 'MsoLinkController',
            link: function (scope, el, attr) {

                var urlString;
                var removeLink = false;

                if (attr.nulllink != undefined){
                    removeLink = true;
                } else if (attr.targetpage != undefined){
                    urlString = "#/" + scope.getLink(attr['targetpage']);
                } else if (attr.targeturl != undefined){
                    urlString = attr['targeturl'];
                } else {
                    el[0].outerHTML = "<span>Either the targetPage or targetUrl attributes must be supplied</span>";
                }

                if (attr['messageid']) {

                    var text = scope.getMessageText(attr['messageid']);

                    if (removeLink){
                        scope.link = text.replace(/href=["'].*["']/i, '' );
                    }
                    else {
                        scope.link = scope.formatString(text, urlString)
                    }

                    el[0].outerHTML = scope.link;
                } else {
                    el[0].outerHTML = "<span>The messageId attribute was not supplied</span>";
                }
            },
            replace: true
        };
    })
    .controller('MsoLinkController', ['$scope', 'DipContextService', 'ReferenceDataService', '$filter',
         function ($scope, DipContextService, ReferenceDataService, $filter) {

            $scope.context = DipContextService;
            $scope.refData = ReferenceDataService;

            $scope.getLink = function(pageName){
                return $scope.context.getLink(pageName);
            }

            $scope.formatString = function(message, urlString){

                return $filter('format')(message, urlString);
            }

            $scope.getMessageText = function(messageId){
                return $scope.refData.resolveMessageText(messageId)
            }

        }]);
