(function(){
    'use strict';

    angular.module('mso.session').factory('SessionExpiryService', SessionExpiryService);

    SessionExpiryService.$inject = ['$window', '$q', '$location', '$interval', '$timeout', '$modal', '$http', '$rootScope', 'ReferenceDataService'];

        function SessionExpiryService($window, $q, $location, $interval, $timeout, $modal, $http, $rootScope, ReferenceDataService){
        var started = false;
        var refreshTokenTimerInterval = 900000; // default to 15 minutes.;
        var inactivityTimerInterval = 1200000; // default to 20 minutes.;
        var refreshTokenTimer;
        var inactivityTimer;
        var doNotTreatAsActivityUrls = [];
        var refreshTokenUrl;

        function activity(url) {
            if (!started) {
                start(3);
            }
            else if (treatUrlAsActivity(url)) {
                restartInactivityTimer();
            }
        }

        function start(retryAttempts) {
            if ($window.sessionStorage.token) {
                started = true;

                ReferenceDataService.getReferenceDataForView('session-expiry-service').then(function(referenceData) {
                    refreshTokenUrl = getRefreshTokenUrl();

                    doNotTreatAsActivityUrls = [refreshTokenUrl];

                    // Parameter P3055 - pSalesInactivityTimeoutInMinutes
                    var parameterValue = referenceData.parameters.p3055;

                    if (parameterValue && parameterValue > 0) {
                        // Convert minutes to milliseconds.
                        inactivityTimerInterval = parameterValue * 60000;
                    }

                    startInactivityTimer();
                    startRefreshTokenTimer();
                }, function(error) {
                    started = false;
                });
            }
            else {
                if (retryAttempts && retryAttempts >= 0) {
                    $timeout(function() {
                        start(retryAttempts - 1);
                    }, 2000);
                }
            }
        }

        function getRefreshTokenUrl() {
            return 'authentication/api/msov1/authenticate/refresh';
        }

        function treatUrlAsActivity(url) {
            return doNotTreatAsActivityUrls.indexOf(url) === -1
                && url.indexOf('session-expiry-dialog.html' === -1
                && url.indexOf('timeout.html') === -1);
        }

        function restartInactivityTimer() {
            startInactivityTimer();
        }

        function stopRefreshTokenTimer() {
            if (refreshTokenTimerInterval) {
                $interval.cancel(refreshTokenTimer);
            }
        }

        function stopInactivityTimer() {
            if (inactivityTimerInterval) {
                $timeout.cancel(inactivityTimer);
            }
        }

        function startRefreshTokenTimer() {
            if (refreshTokenTimerInterval && $window.sessionStorage.token) {
                $interval.cancel(refreshTokenTimer);

                refreshTokenTimer = $interval(function () {
                    getRefreshToken();
                }, refreshTokenTimerInterval);
            }
        }

        function startInactivityTimer(doNotRetry) {
            if (inactivityTimerInterval && $window.sessionStorage.token) {
                $timeout.cancel(inactivityTimer);

                inactivityTimer = $timeout(function () {
                    stopRefreshTokenTimer();
                    stopInactivityTimer();
                    showSessionExpiryWarning();
                }, inactivityTimerInterval);
            }
            else {
                if (!doNotRetry) {
                    // Delayed retry for cases where the token is still being persisted (i.e. immediately after login).
                    $timeout(function() {
                        startInactivityTimer(true);
                    }, 1000);
                }
            }
        }

        function expireSession() {
            stopInactivityTimer();
            stopRefreshTokenTimer();
            started = false;
            delete $window.sessionStorage.user;
            delete $window.sessionStorage.token;
            delete $window.sessionStorage.username;
            delete $window.sessionStorage.lockToken;
            delete $window.sessionStorage.intermediary;
            delete $window.sessionStorage.advisorName;
            delete $window.sessionStorage.roles;

            delete $window.sessionStorage.channel;
            delete $window.sessionStorage.brand;
            delete $window.sessionStorage.sourcePortal;
            delete $window.sessionStorage.anchorDate;

            $window.location.href= '/timeout-ui';
        }

        function getRefreshToken() {
            if ($window.sessionStorage.token) {
                $http({ url: refreshTokenUrl, method: 'GET', data: null, hideLoading: true }).then(function (response) {
                    $window.sessionStorage.token = response.data.token;
                });
            }
        }

        function showSessionExpiryWarning() {
            stopRefreshTokenTimer();
            stopInactivityTimer();

            if ($window.sessionStorage.token) {
                $modal.open({
                    template: require('/app/template/session-expiry-dialog.html').default,
                    controller: 'SessionExpiryDialogCtrl',
                    backdrop: 'static',
                    size: 'sm',
                    windowClass: 'session-expiry-dialog'
                });
            }
        }

        return {
            activity: activity,
            startRefreshTokenTimer: startRefreshTokenTimer,
            startInactivityTimer: startInactivityTimer,
            getRefreshToken: getRefreshToken,
            expireSession: expireSession
        };
    };
})();