angular.module('mso.services').factory('LockService', ['$resource', '$http', '$q', 'Config', '$window', function ($resource, $http, $q, Config, $window) {
    var lockApi = $resource('/cm/api/msov1' + '/lock/:lockId/remove', {lockId:'@lockId'});
    return{
        clearLock: function(lockId){
            var deferred = $q.defer();

            lockApi.save({lockId:lockId}).$promise.then(function(){
                deferred.resolve();
            }, function(error){
                deferred.reject(error);
            })
            return deferred.promise;
        }
    };
}]);
