angular.module('mso.directives')
    .directive('msoCapitaliseInput', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, modelCtrl) {
            var capitalise = function(inputValue) {
                if (inputValue) {
                    var capitalised = inputValue.toUpperCase();
                    if (capitalised !== inputValue) {
                        modelCtrl.$setViewValue(capitalised);
                        modelCtrl.$render();
                    }
                    return capitalised;
                }
                return inputValue;
            }
            modelCtrl.$parsers.push(capitalise);
            capitalise(scope[attrs.ngModel]);
        }
    };
});