'use strict';
angular.module('mso.services')
    .factory('RuleService', ['enums', 'featureToggleService', function (enums, featureToggleService) {
    var rulesService = {

        /* CM */
        r5191PopulatePaymentRoutes: function (intermediary) {
            var paymentRoutes = [];
            if (intermediary && intermediary.intermediaryFirms) {
                for (var i = 0; i < intermediary.intermediaryFirms.length; i++) {
                    if (intermediary.intermediaryFirms[i].paymentRoutes) {
                        for (var j = 0; j < intermediary.intermediaryFirms[i].paymentRoutes.length; j++) {
                            paymentRoutes.push(intermediary.intermediaryFirms[i].paymentRoutes[j]);
                        }
                    }
                }
            }
            return paymentRoutes;
        },

        r5194NotConfirmedUseOfMyInfoCondition: function (value) {
            return !value;
        },

        r5198DefaultPaymentRoute: function (paymentRoutes) {
            if (paymentRoutes && paymentRoutes.length == 1) {
                return paymentRoutes[0];
            }
        },

        r5196ApplicantAlreadyOnCase: function (applicants, applicant) {
            //If the Applicant has already been assigned to the case Return True.
            if (applicants && applicant) {
                for (var i = 0; i < applicants.length; i++) {
                    if (angular.lowercase(applicants[i].forename) == angular.lowercase(applicant.forename) &&
                        angular.lowercase(applicants[i].surname) == angular.lowercase(applicant.surname) &&
                        applicants[i].dateOfBirth._i == applicant.dateOfBirth._i) {
                        return true;
                    }
                }
            }
            return false;
        },

        r5195NoCaseApplicants: function (applicants) {
            // If No Applicants selected or listed yet Return True.
            return !(applicants && applicants.length > 0);
        },

        r5203MaxNoApplicants: function (applicants, p0046) {
            // If number of applicants added to case is equal to p0046 - pMaxApplicants return True
            return (applicants && applicants.length >= p0046);
        },

        r5204MinNoApplicants: function (applicants, p0045) {
            // If number of applicants added to case is less than p0045 - pMinApplicants return True
            return ((!applicants) || applicants.length < p0045);
        },

        r5809IsPCVAvailable: function (p6143, applicationType, caseStatus, caseStage) {
            //Returns true if PCV is available
            return ((applicationType === enums.RDApplicationTypeCode.newPurchase || applicationType === enums.RDApplicationTypeCode.remortgage) && (caseStatus === enums.RDCaseStatus.active || caseStatus === enums.RDCaseStatus.suspended) &&
            (caseStage === enums.RDCaseStage.preOffer || caseStage === enums.RDCaseStage.offer || caseStage === enums.RDCaseStage.postOffer ) && (p6143 === 'true'));
        },

        /* DIP */
        dAdditionalBorrowingMaxLtv: function (additionalBorrowingAmount) {
            /// <summary>Calculate max ltv for additional borrowing</summary>
            /// <param name="options" type="numeric">additional Borrowing Amount</param>
            return dAdditionalBorrowingMaxLtv(additionalBorrowingAmount);
        },

        dAdditionalBorrowingReasonMaxLtv: function (reason) {
            /// <summary>Calculate max ltv for additional borrowing reason</summary>
            /// <param name="reason" type="numeric">additional borrowing reason</param>
            if (reason === enums.RDAdditionalBorrowingReasonCode.payOffSecondCharge) {
                return 0.80;
            }

            return 0.85;
        },

        calculateLtv: function (applicationType, propertyOwnershipType, totalLoanAmount, feeAmount, currentEstimatedValue, purchasePrice, fullMarketValue, deposits, additionalBorrowing, afterWorkValue) {
            /// <summary>Calculate standard max ltv</summary>
            /// <param name="applicationType" type="numeric">application type</param>
            /// <param name="propertyOwnershipType" type="numeric">property ownership type</param>
            /// <param name="totalLoanAmount" type="numeric">total loan amount</param>
            /// <param name="feeAmount" type="numeric">fee amount</param>
            /// <param name="currentEstimatedValue" type="numeric">current estimated value</param>
            /// <param name="purchasePrice" type="numeric">purchase price</param>
            var loanAmount,
                propertyValue,
                currentLtv = 0,
                at = parseInt(applicationType),
                pot = parseInt(propertyOwnershipType),
                tla = parseInt(totalLoanAmount),
                fa = parseInt(feeAmount || 0),
                cev = parseInt(currentEstimatedValue),
                pp = parseInt(purchasePrice),
                fmv = parseInt(fullMarketValue),
                adb = parseInt(additionalBorrowing),
                awv = parseInt(afterWorkValue);

            //Set Loan Amount
            loanAmount = tla + fa;

            //Determine Declared Property Value
            propertyValue = getCustomerValue(at, pot, cev, pp, fmv, adb, awv);

            //TODO - Determine Valuation Value

            //Adjust the property value if cashback is being provided (builder cashback or vender cashback)
            propertyValue = adjustPropertyValue(propertyValue, at, deposits, awv);

            //TODO - Set ES Property Value for product eligibility

            if (propertyValue > 0 && loanAmount > 0) {
                currentLtv = loanAmount / propertyValue;
            }

            return currentLtv;
        },

        getCustomerValue: function (applicationType, propertyOwnershipType, currentEstimatedValue, purchasePrice, fullMarketValue, additionalBorrowing, afterWorkValue) {
            return getCustomerValue(applicationType,
                propertyOwnershipType,
                currentEstimatedValue,
                purchasePrice,
                fullMarketValue,
                additionalBorrowing,
                afterWorkValue);
        },

        adjustPropertyValue: function (propertyValue, applicationType, deposits, afterWorksValueIsApplicable, afterWorkValue) {
            return adjustPropertyValue(propertyValue,
                applicationType,
                deposits,
                afterWorksValueIsApplicable,
                afterWorkValue);
        },

        dStandardMaxLtv: function (totalLoanAmount) {
            /// <summary>Calculate standard max ltv</summary>
            /// <param name="options" type="numeric">total loan amount</param>

            return dStandardMaxLtv(totalLoanAmount);
        },

        dSchemeTypeMaxLtv: function (propertyOwnershipType) {
            /// <summary>Calculate max ltv for schema type</summary>
            /// <param name="propertyOwnershipType" type="numeric">property ownership type</param>
            return dSchemeTypeMaxLtv(propertyOwnershipType);
        },

        dPropertyTypeMaxLtv: function (propertyType) {
            return dPropertyTypeMaxLtv(propertyType);
        },

        getValuationValue: function (validValuation, valuationType) {
            var valuationValue = 0;
            if (validValuation === true) {
                //Implement valuation here
            }
            return valuationValue;
        },

        calculateEquityShareProductLtv: function (totalLoanAmount, feeAmount, fullMarketValue) {
            /// <summary>The system calculate the Equity Share Product LTV percentage</summary>
            /// <param name="totalLoanAmount" type="numeric">Total loan amount</param>
            /// <param name="feeAmount" type="numeric">fee amount</param>
            /// <param name="fullMarketValue" type="numeric">full market value</param>
            var loanAmount,
                esProductLtv = 0;

            loanAmount = (parseInt(totalLoanAmount || 0)) + (parseInt(feeAmount || 0));

            var fmv = parseInt(fullMarketValue);
            if (fmv > 0) {
                esProductLtv = loanAmount / fmv;
            }
            return esProductLtv;
        },

        r0043CalculateMaxLtvV2: function (parameters, applicationType, propertyOwnership, totalLoanAmount, currentMortgageRedemptionAmount, propertyType, propertyPurpose, newBuildIndicator, applicants, interestOnlyLoanAmount, lenderMaxLtv, currentModule) {

            // P6282 - pUseLenderMaxLTV determines whether to use the lenderMaxLtv or the MSO calculated Max Ltv.
            if(parameters.p6282 !== null && parameters.p6282.toLowerCase() === 'true' && lenderMaxLtv !== null)
            {
                // We only want to use the lender Max Ltv value if we are not currently in the PM or DIP modules.
                if(currentModule.toLowerCase() !== 'mso.pm' && currentModule.toLowerCase() !== 'mso.dip')
                {
                    // Format the Max LTV before it is used in the calculation, e.g. 85 needs to be 0.85
                    return lenderMaxLtv / 100;
                }
            }

            var maxPropertyPurposeLtv = this.r5988_MaxPropertyPurposeLtv(parameters, propertyPurpose);

            var maxPropertyOwnershipLtv = this.r5989_MaxPropertyOwnershipLtv(parameters, propertyOwnership);

            var maxLoanAmountLtv = this.r5990_MaxLoanAmountLtv(parameters, totalLoanAmount);

            var maxNewBuildLtv = this.r5991_MaxNewBuildLtv(parameters, propertyType, propertyPurpose, newBuildIndicator);

            var maxNationalityLtv = this.r5992_MaxNationalityLtv(parameters, applicants);

            var maxInterestOnlyLtv = this.r5993_MaxInterestOnlyLtv(parameters, interestOnlyLoanAmount);

            var maxApplicationTypeLtv = this.r6307_MaxApplicationTypeLtv(parameters, totalLoanAmount, applicationType, currentMortgageRedemptionAmount);

            var allMaxLtvs = [];
            if (maxPropertyPurposeLtv > 0) { allMaxLtvs.push(maxPropertyPurposeLtv); }
            if (maxPropertyOwnershipLtv > 0) { allMaxLtvs.push(maxPropertyOwnershipLtv); }
            if (maxLoanAmountLtv > 0) { allMaxLtvs.push(maxLoanAmountLtv); }
            if (maxNewBuildLtv > 0) { allMaxLtvs.push(maxNewBuildLtv); }
            if (maxNationalityLtv > 0) { allMaxLtvs.push(maxNationalityLtv); }
            if (maxInterestOnlyLtv > 0) { allMaxLtvs.push(maxInterestOnlyLtv); }
            if (maxApplicationTypeLtv > 0) { allMaxLtvs.push(maxApplicationTypeLtv); }

            // Set the max LTV to be the lowest of all Max LTVs determined.
            var maximumLtv = Math.min.apply(Math, allMaxLtvs);

            return maximumLtv;
        },

        r5988_MaxPropertyPurposeLtv: function (parameters, propertyPurpose) {
            switch(propertyPurpose){
                case enums.RDPropertyPurposeTypeCode.buyToLet:
                    return parameters.p6183;
                case enums.RDPropertyPurposeTypeCode.ownerOccupation:
                    return parameters.p6367;
                case enums.RDPropertyPurposeTypeCode.secondProperty:
                    return parameters.p6368;
                default:
                    return 0;
            }
        },

        r5989_MaxPropertyOwnershipLtv: function (parameters, propertyOwnership) {
            if (propertyOwnership)
            {
                if (propertyOwnership == enums.RDPropertyOwnershipTypeCode.sharedOwnership)
                {
                    return parameters.p6056;
                }
                else if (propertyOwnership == enums.RDPropertyOwnershipTypeCode.rightToBuy)
                {
                    return parameters.p6055;
                }
                else if (propertyOwnership == enums.RDPropertyOwnershipTypeCode.equityShare)
                {
                    return parameters.p6057;
                }
                else if (propertyOwnership == enums.RDPropertyOwnershipTypeCode.mortgageGuarantee)
                {
                    return parameters.p6058;
                }
                else if (propertyOwnership == enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember)
                {
                    return parameters.p6192;
                }
            }

            return parameters.p6059;
        },

        r5990_MaxLoanAmountLtv: function (parameters, totalLoanAmount) {
            //band1
            if (totalLoanAmount) {

                var tla = parseInt(totalLoanAmount);

                if (tla <= parseInt(parameters.p6185))
                {
                    return parameters.p6188;
                }

                //band2
                if (tla <= parseInt(parameters.p6186) && tla > parseInt(parameters.p6185))
                {
                    return parameters.p6189;
                }

                //band3
                if (tla <= parseInt(parameters.p6187) && tla > parseInt(parameters.p6186))
                {
                    return parameters.p6190;
                }

                //over band 3
                if (tla > parseInt(parameters.p6187))
                {
                    return parameters.p6191;
                }
            }

            return 0;
        },

        r5991_MaxNewBuildLtv: function (parameters, propertyType, propertyPurpose, newBuildIndicator) {
            if (newBuildIndicator)
            {
                if (this.r5315IsPropertyFlatOrMaisonette(propertyType))
                {
                    if (propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet)
                    {
                        return parameters.p6195;
                    }
                    else
                    {
                        return parameters.p6193;
                    }
                }
                else
                {
                    if (propertyPurpose == enums.RDPropertyPurposeTypeCode.buyToLet)
                    {
                        return parameters.p6196;
                    }
                    else
                    {
                        return parameters.p6194;
                    }
                }
            }

            return 0;
        },

        r5992_MaxNationalityLtv: function (parameters, applicants) {

            var found = false;

            angular.forEach(applicants, function (applicant) {
                if (applicant.permanentUkResidence && applicant.nationality === enums.RDNationalityCode.otherOutsideEEA
                    && applicant.indefiniteLeave === false && applicant.tier1Visa === false){
                    found = true;
                }
            });

            if (found) {
                return parameters.p6197;
            }

            return 0;
        },

        r5993_MaxInterestOnlyLtv: function (parameters, interestOnlyLoanAmount) {
            if (interestOnlyLoanAmount && interestOnlyLoanAmount > 0)
            {
                return parameters.p6198;
            }

            return 0;
        },

        r6307_MaxApplicationTypeLtv: function(parameters, totalLoanAmount, applicationType, currentMortgageRedemptionAmount){
            switch(applicationType){
                case enums.RDApplicationTypeCode.newPurchase:
                    return parameters.p6369;
                case enums.RDApplicationTypeCode.remortgage:
                    return this.r3006RemortgageAdditionalBorrowingCalculation(totalLoanAmount, applicationType, currentMortgageRedemptionAmount) === 0 ? parameters.p6370 : parameters.p6377;
                default:
                    return 0;
            }
        },

        r0036CustomerGteMinAgeAtApplication: function (birthDate, parameters, propertyPurpose) {
            if (birthDate !== undefined && birthDate !== null) {
                var minimumAge = parseInt(this.r6073MinAgeAtApplicationParameter(propertyPurpose,
                    parameters));
                if (moment(birthDate).add('years', minimumAge).diff(moment()) <= 0) {
                    return true;
                }
            }
            return false;
        },

        r6073MinAgeAtApplicationParameter: function (propertyPurpose, parameters){
            switch(propertyPurpose){
                case enums.RDPropertyPurposeTypeCode.ownerOccupation:
                    return parameters.p6272;
                case enums.RDPropertyPurposeTypeCode.buyToLet:
                    return parameters.p6267;
                case enums.RDPropertyPurposeTypeCode.secondProperty:
                    return parameters.p6268;
                default:
                    return parameters.p0038;
            }
        },

        r0037CustomerLtMaxAgeAtApplication: function (birthDate, p0039) {
            if (birthDate !== undefined && birthDate !== null) {
                if (moment(birthDate).add('years', parseInt(p0039)).diff(moment()) > 0) {
                    return true;
                }
            }
            return false;
        },

        r6301BuyToLetApplicantIsFirstTimeBuyerStop: function(propertyPurpose, applicantType, pBuyToLetFirstTimeBuyerAllowed){
            return pBuyToLetFirstTimeBuyerAllowed.toLowerCase() === 'false' &&
                propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet &&
                applicantType === enums.RDApplicantTypeCode.firstTimeBuyer;
        },

        r0041CalculateSalesLTV: function (applicationType, ownershipType, totalLoanAmount, currentEstimatedValue, purchasePrice,
                                          fullMarketValue, depositBuildersCashback, depositVendorsCashback, p3115, avmValuation) {

            var propertyValue = calculatePropertyValueExcludingDeposit(applicationType, ownershipType, currentEstimatedValue, purchasePrice, fullMarketValue, avmValuation);

            var maxBuilderCashbackExceeded = r6024MaxBuilderCashbackExceeded(depositBuildersCashback, p3115, applicationType, ownershipType, currentEstimatedValue, purchasePrice, fullMarketValue, avmValuation);

            if (maxBuilderCashbackExceeded) {
                var buildersCashback = safeInt(depositBuildersCashback);
                var pMaxBuildersCashbackPercentage = parseFloat(p3115) / 100;
                var maxAllowedCashback = Math.round(propertyValue * pMaxBuildersCashbackPercentage);
                propertyValue = propertyValue - (buildersCashback - maxAllowedCashback);
            }

            var vendorCashback = safeInt(depositVendorsCashback);
            if (vendorCashback > 0) {
                propertyValue = propertyValue - vendorCashback;
            }

            if (!propertyValue || propertyValue === 0) {
                return 0;
            } else {
                return parseInt(totalLoanAmount) / propertyValue;
            }
        },

        r6024MaxBuilderCashbackExceeded: function (depositBuildersCashback, p3115, applicationType, ownershipType, currentEstimatedValue, purchasePrice, fullMarketValue, avmValuation) {
            return r6024MaxBuilderCashbackExceeded(depositBuildersCashback, p3115, applicationType, ownershipType, currentEstimatedValue, purchasePrice, fullMarketValue, avmValuation);
        },

        r0049SaleOfForeignPropertyIsValid: function (repaymentPropertyValue, outstandingMortgageBalance, repaymentStrategyAmount, p0051) {
            /// <summary>check the sale of a foreign property is allowed</summary>
            /// <param name="estimatedValueOfOtherProperty" type="numeric">repayment property value</param>
            /// <param name="outstandingMortgageBalance" type="numeric">outstanding mortgage balance</param>
            /// <param name="interestOnlyAmountSatisfied" type="numeric">repayment strategy amount - Interest only amount satisfied</param>
            /// <param name="p0051" type="numeric">pRS2ndPropertyForeignEquityRequired</param>
            var a = repaymentPropertyValue - outstandingMortgageBalance;
            var b = a * p0051;

            return repaymentStrategyAmount <= b;
        },

        r0050SaleOfOtherUkPropertyIsValid: function (repaymentPropertyValue, outstandingMortgageBalance, repaymentStrategyAmount, p0052) {
            /// <summary>Check the sale of a UK property is allowed</summary>
            /// <param name="estimatedValueOfOtherProperty" type="numeric">repayment property value</param>
            /// <param name="outstandingMortgageBalance" type="numeric">outstanding mortgage balance</param>
            /// <param name="interestOnlyAmountSatisfied" type="numeric">repayment strategy amount</param>
            /// <param name="p0052" type="numeric">pRS2ndPropertyUKEquityRequired</param>
            var a = repaymentPropertyValue - outstandingMortgageBalance;
            var b = a * p0052;

            return repaymentStrategyAmount <= b;
        },

        r0063PercentageSharedOwnershipAllowed: function (applicationType, percentagePropertyMortgaged, p0116, p0044) {
            /// <summary>This calculates whether the amount of equity being mortgaged is above the configurable minimum use in Capture Property Details</summary>
            /// <summary>This rule also covers off r0063 for remortgage cases</summary>
            /// <param name="applicationType" type="numeric">application type</param>
            /// <param name="percentagePropertyMortgaged" type="numeric">percentage property mortgaged</param>
            /// <param name="p0116" type="numeric">pRemortgageMinSharedOwnershipPercentage</param>
            /// <param name="p0044" type="numeric">pPurchaseMinSharedOwnershipPercentage</param>
            var allowed = false,
                at = parseInt(applicationType),
                ppm = parseFloat(percentagePropertyMortgaged),
                f0116 = parseFloat(p0116),
                f0044 = parseFloat(p0044);

            if (at === enums.RDApplicationTypeCode.newPurchase && ppm >= f0044) {
                allowed = true;
            }

            if (at === enums.RDApplicationTypeCode.remortgage && ppm >= f0116) {
                allowed = true;
            }

            return allowed;
        },

        r0071ContractTimeAcceptable: function (timeContractingMonths, timeRemainingMonths, p0070, p0071) {
            /// <summary>Determine whether or not the stated contract period is acceptable use in Capture Income Details</summary>
            /// <param name="timeContractingMonths" type="numeric">time contracting months</param>
            /// <param name="timeRemainingMonths" type="numeric">time remaining months</param>
            /// <param name="p0070" type="numeric">pMinMonthsContracting</param>
            /// <param name="p0071" type="numeric">pMinMonthsRemainingOnContract</param>
            if (timeContractingMonths >= p0070 || timeRemainingMonths >= p0071) {
                return true;
            }
            return false;
        },

        r2003IsUnconfirmedLTVGreaterThanMaxLTV: function (unconfirmedLTV, maxLTV) {
            return parseFloat(unconfirmedLTV) > parseFloat(maxLTV);
        },

        r6297_CalculateSalesLtvIncFeesAddedToLoan: function(loanRequirement, p3115, avmValuation, fees){

            var totalLoanAmountIncFeesAddedToLoan = loanRequirement.totalLoanAmount + feesAddedToLoan(fees);
            var depositBuildersCashBack = getDepositAmount(enums.RDMortgageDepositSourceCode.builderCashback,
                loanRequirement.depositDetails);
            var depositVendorsCashBack = getDepositAmount(enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive,
                loanRequirement.depositDetails);

            return this.r0041CalculateSalesLTV(loanRequirement.applicationType, loanRequirement.propertyOwnership, totalLoanAmountIncFeesAddedToLoan,
                loanRequirement.currentEstimatedValue, loanRequirement.purchasePrice, loanRequirement.fullMarketValue,
                depositBuildersCashBack, depositVendorsCashBack, p3115, avmValuation);
        },

        r6308_IsUnconfirmedLtvGreaterThanCeilingMaxLtv: function(parameters, r2003UnconfirmedLtvGreaterThanMaxLtv, fees, r6297SalesLtvIncFeesAddedToLoan){
            return !r2003UnconfirmedLtvGreaterThanMaxLtv
                && parameters.p6208.toLowerCase() === 'false'
                && feesAddedToLoan(fees) > 0
                && r6297SalesLtvIncFeesAddedToLoan > parameters.p6371;
        },

        r2005BusinessPurposeAmountExceedsMax: function (reason, purposeAmount, p0056) {
            /// <summary>This calculates the amount of money that is being borrowed for business purposes use in UC1218 Capture Additional Borrowing Reasons</summary>
            /// <param name="reason" type="numeric">reason</param>
            /// <param name="purposeAmount" type="numeric">purpose amount</param>
            /// <param name="p0056" type="numeric">pMaxBusinessCaptialRaisingAmount</param>

            reason = parseInt(reason);
            purposeAmount = parseInt(purposeAmount);

            if (reason === enums.RDAdditionalBorrowingReasonCode.businessPurposes && purposeAmount > p0056) {
                return true;
            }

            return false;
        },

        r2006AgeAtEndOfMortgageTermExceedsMaximumAllowed: function (birthday, mortgageTermMonths, parameters, propertyPurpose) {
            /// <summary>
            ///     This determines whether any of the customers on the case are over
            ///     the maximum (configurable) age at the end of the mortgage term use in UC1219 Capture Loan Requirements
            /// </summary>
            /// <param name="birthday" type="string">customer birthday</param>
            /// <param name="mortgageTermMonths" type="numeric">Mortgage term in months</param>
            /// <param name="p0106" type="numeric">pMaxApplicantAgeAtTerm</param>
            if (birthday) {
                var isIsoFormat = (moment(birthday, moment.ISO_8601).isValid());
                var moBirthday = isIsoFormat ? moment(birthday) :  moment(birthday, 'L');
                //Date at the end of the mortgage term
                var mortgageTermEndDate = this.r3016CalculateMortgageTermEndDate(mortgageTermMonths);
                //Age at end of mortgage term
                var ageAtEndOfMortgageTerm = mortgageTermEndDate.diff(moBirthday, 'years');
                var maximumAge = this.r6076MaxAgeAtEndOfTermParameter(parameters, propertyPurpose);
                //Compare ageAtEndOfMortgageTerm with limitation maximumAge
                if (ageAtEndOfMortgageTerm <= parseInt(maximumAge)) {
                    return false;
                }
            }

            return true;
        },

        r6076MaxAgeAtEndOfTermParameter: function (parameters, propertyPurpose){
            switch(propertyPurpose){
                case enums.RDPropertyPurposeTypeCode.ownerOccupation:
                    return parameters.p6271;
                case enums.RDPropertyPurposeTypeCode.buyToLet:
                    return parameters.p6269;
                case enums.RDPropertyPurposeTypeCode.secondProperty:
                    return parameters.p6270;
                default:
                    return parameters.p0106;
            }
        },

        r2007AdditionalBorrowingNotAllowed: function (applicationType, ownershipType, totalAdditionalBorrowingAmount, rtbAdditionalBorrowingAmount, customerBirthdays, p2009) {
            /// <summary>rule check additional borrowing is acceptable, use in Capture Loan Requirements</summary>
            /// <param name="applicationType" type="numeric">customer birthday</param>
            /// <param name="propertyOwnershipType" type="numeric">property ownership type</param>
            /// <param name="totalAdditionalBorrowingAmount" type="numeric">total additional borrowing amount</param>
            /// <param name="rtbAdditionalBorrowingAmount" type="numeric">right to buy additional borrowing amount</param>
            /// <param name="customerBirthdays" type="array">customers birthday</param>
            /// <param name="p2009" type="numeric">MaxAgeAllowedAdditionalBorrowing</param>
            var notAllowed = true;
            //Is Application Type Additional Borrowing?
            if ((applicationType === enums.RDApplicationTypeCode.newPurchase &&
                totalAdditionalBorrowingAmount !== 0)
                || (applicationType === enums.RDApplicationTypeCode.remortgage && ownershipType === enums.RDPropertyOwnershipTypeCode.rightToBuy
                && rtbAdditionalBorrowingAmount !== 0)) {
                for (var i = 0; i < customerBirthdays.length; i += 1) {
                    var age = moment().diff(customerBirthdays[i], 'years');
                    if (age >= p2009) {
                        break;
                    }
                    notAllowed = false;
                }
            }

            return notAllowed;
        },

        r2016AggregateBorrowingExceedsMax: function (continuingBalance, totalLoanAmount, p2013) {
            /// <summary>
            ///     This checks whether the aggregated mortgage debt (including this new mortgage) exceeds the maximum mortgage debt allowed
            ///     this rule use in UC1210 - Capture Existing Mortgage Details
            /// </summary>
            /// <param name="continuingBalance" type="numeric">continuing balance</param>
            /// <param name="totalLoanAmount" type="numeric">total loan amount</param>
            /// <param name="p2013" type="numeric">pMaxAggregateLoanAmount</param>
            var aggregateBorrowing = continuingBalance + totalLoanAmount;
            if (aggregateBorrowing > p2013) {
                return true;
            }
            return false;
        },

        r2086PercentageEquityShareIsBelowMinimum: function (equityShareScheme, percentagePropertyMortgaged, p2152, p2153, p2154) {
            /// <summary>
            ///     This calculates whether the amount of equity being mortgaged is below the configurable minimum
            ///     this rule use in UC1217 - Capture Property Details
            /// </summary>
            /// <param name="equityShareScheme" type="numeric">equity share scheme</param>
            /// <param name="percentagePropertyMortgaged" type="numeric">percentage property mortgaged</param>
            /// <param name="p2152" type="numeric">pMinimumPercentageOwnershipBuilders</param>
            /// <param name="p2153" type="numeric">pMinimumPercentageOwnershipHousingAssociation</param>
            /// <param name="p2154" type="numeric">pMinimumPercentageOwnershipHomebuy</param>
            var ppmv = parseFloat(percentagePropertyMortgaged),
                equityShareSchemeValue = parseInt(equityShareScheme);

            if (equityShareSchemeValue === enums.RDEquityShareTypeCode.buildersEquityShare && ppmv < parseFloat(p2152)) {
                return true;
            }
            if (equityShareSchemeValue === enums.RDEquityShareTypeCode.houseAssociationOrLocalAuthority && ppmv < parseFloat(p2153)) {
                return true;
            }
            return ppmv < parseFloat(p2154);
        },

        r2100FreeValuationHasBeenUsed: function (p0064) {
            /// <summary>This calculates whether the free valuation has been used</summary>
            /// <param name="p0064" type="numeric">pTimeBeforeWhichValFeeisRefundable</param>
            if (moment().diff(p0064, 'days') < 0) {
                return true;
            }

            return false;
        },

        r2101EndDateOfLoanTermExceedsRetirement: function (birthday, mortgageTermYear, mortgageTermMonth, expectedRetirementAge, p2179) {
            /// <summary>
            ///     This calculates if the loan ends after the customers retirement date
            ///     this rule use in UC1207 - Capture Retirement Income
            /// </summary>
            /// <param name="birthday" type="string">birthday</param>
            /// <param name="mortgageTermYear" type="numeric">mortgage term year</param>
            /// <param name="mortgageTermMonth" type="numeric">mortgage term month</param>
            /// <param name="expectedRetirementAge" type="numeric">expected retirement age</param>
            /// <param name="p2179" type="numeric">pMaxRetirementAge</param>

            var frombirthday2P2179 = moment(birthday).add('years', p2179);
            var frombirthday2ExpectedRetirement = moment(birthday).add('years', expectedRetirementAge);

            var frombirthdate2Endmortgageterm = moment().add('years', mortgageTermYear).add('months', mortgageTermMonth);

            var endmortgagetermVsP2179 = frombirthdate2Endmortgageterm.diff(frombirthday2P2179, 'days');
            var endmortgagetermVsExpectedRetirement = frombirthdate2Endmortgageterm.diff(frombirthday2ExpectedRetirement, 'days');

            if (endmortgagetermVsP2179 > 0 || endmortgagetermVsExpectedRetirement > 0) {
                return true;
            }
            return false;

        },

        r3000DetermineOverallCustomerType: function (customerTypes) {
            /// <summary>
            ///     This calculates over all customer type
            ///     this rule use in R3003 & R3004 rule
            /// </summary>
            /// <param name="customerTypes" type="numeric">list of customer types</param>
            return r3000DetermineOverallCustomerType(customerTypes);
        },

        // considered obsolete: use r3006RemortgageAdditionalBorrowingCalculation with json object
        r3006RemortgageAdditionalBorrowingCalc: function (totalLoanAmount, amountTransferredFromOtherLender) {
            /// <summary>
            ///     This calculates remortgage additional borrowing
            ///     this rule use in Capture Additional Borrowing
            /// </summary>
            /// <param name="totalLoanAmount" type="numeric">total loan amount</param>
            /// <param name="currentMortgageRedemptionAmount" type="numeric">amount transferred from other lender</param>
            if (totalLoanAmount === undefined ||
                amountTransferredFromOtherLender === undefined ||
                amountTransferredFromOtherLender === null ||
                amountTransferredFromOtherLender < 0) {
                return 0;
            }

            var result = totalLoanAmount - amountTransferredFromOtherLender;
            if (result < 0) {
                result = 0;
            }
            return result;
        },


        r3009CalculatePercentageMortgaged: function (applicationType, purchasePrice, fullMarketValue, currentEstimatedValue) {
            /// <summary>
            ///     This calculates the percentage of the full market value of the property being mortgaged
            ///     It use in UC1217 - Capture Property Details
            /// </summary>
            /// <param name="applicationType" type="numeric">application type</param>
            /// <param name="purchasePrice" type="numeric">purchase price</param>
            /// <param name="fullmarketValue" type="numeric">full market value</param>
            /// <param name="currentEstimatedValue" type="numeric">current estimated value</param>
            var at = parseInt(applicationType),
                pp = parseInt(purchasePrice),
                fmv = parseInt(fullMarketValue),
                cev = parseInt(currentEstimatedValue);

            if (at === enums.RDApplicationTypeCode.newPurchase && fmv > 0) {
                return (pp / fmv) * 100;
            }
            if (at === enums.RDApplicationTypeCode.remortgage && fmv > 0) {
                return (cev / fmv) * 100;
            }
            return 0;
        },


        r5085DetermineAdditionalBorrowingReasons: function (applicationType, propertyOwnershipType, percentagePropertyMortgaged,
                                                            propertyPurpose, interestOnlyLoanAmount, parameters) {
            /// <summary>
            ///     determines which additional borrowing reasons are applicable for the case
            ///     It is used in UC1218 - Capture Additional Borrowing Reasons
            ///     In EA, R5085 points to R5154 - DetermineValidAdditionalBorrowingReasons
            /// </summary>
            /// <param name="applicationType" type="numeric">application type</param>
            /// <param name="propertyOwnershipType" type="numeric">property ownership type</param>
            /// <param name="percentagePropertyMortgaged" type="numeric">percentage property mortgaged</param>

            if (parameters.iP0001 === '2.6')
                return r5085DetermineAdditionalBorrowingReasons2_6(applicationType, propertyOwnershipType, percentagePropertyMortgaged,
                    propertyPurpose, interestOnlyLoanAmount, parameters);

            applicationType = parseInt(applicationType);
            propertyOwnershipType = parseInt(propertyOwnershipType);
            propertyPurpose = parseInt(propertyPurpose);

            return this.r5154DetermineValidAdditionalBorrowingReasons(
                applicationType, propertyOwnershipType, percentagePropertyMortgaged,
                propertyPurpose, interestOnlyLoanAmount, parameters);

            function r5085DetermineAdditionalBorrowingReasons2_6 (applicationType, propertyOwnershipType, percentagePropertyMortgaged,
                                        propertyPurpose, interestOnlyLoanAmount, parameters){
                applicationType = parseInt(applicationType);
                propertyOwnershipType = parseInt(propertyOwnershipType);
                propertyPurpose = parseInt(propertyPurpose);

                var loanPurpose = enums.RDAdditionalBorrowingReasonCode;
                var additionalBorrowingReason = [loanPurpose.nonStructuralHomeImprovement, loanPurpose.structuralHomeImprovement];
                if ((applicationType === enums.RDApplicationTypeCode.newPurchase) &&
                    (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.rightToBuy ||
                    propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember)) {
                    additionalBorrowingReason.push(loanPurpose.legalFees);
                }

                if (applicationType === enums.RDApplicationTypeCode.remortgage) {
                    switch (propertyOwnershipType) {
                        case enums.RDPropertyOwnershipTypeCode.standard:
                            additionalBorrowingReason.push(loanPurpose.repayUnsecuredDebts, loanPurpose.otherPersonalConsumption,
                                loanPurpose.buyOutNonBorrower, loanPurpose.buyAShareInFreehold,
                                loanPurpose.buyFreeholdTitleOrNewExtendedLease, loanPurpose.buyLandToExtendSecurity,
                                loanPurpose.businessPurposes, loanPurpose.buyPropertyForMainResidenceAndLetCurrentProperty,
                                loanPurpose.buyLandOrPropertySeparateFromTheSecurity,
                                loanPurpose.payOffSecondCharge, loanPurpose.buyOutJointBorrower);
                            break;
                        case enums.RDPropertyOwnershipTypeCode.sharedOwnership:
                            if (percentagePropertyMortgaged === 1.0) {
                                additionalBorrowingReason.push(loanPurpose.buyFinalShareInASharedOwnership);
                            } else {
                                additionalBorrowingReason.push(loanPurpose.buyOutNonBorrower,
                                    loanPurpose.buyAdditionalShareInASharedOwnership, loanPurpose.buyOutJointBorrower);
                            }
                            break;
                        case enums.RDPropertyOwnershipTypeCode.rightToBuy:
                            additionalBorrowingReason.push(loanPurpose.buyOutNonBorrower, loanPurpose.buyAShareInFreehold,
                                loanPurpose.buyFreeholdTitleOrNewExtendedLease, loanPurpose.buyLandToExtendSecurity,
                                loanPurpose.buyOutJointBorrower);
                            break;
                        case enums.RDPropertyOwnershipTypeCode.equityShare:
                            // p6302 - pRemoveABReasonBuyFreeholdShare
                            if (parameters.p6302.toLowerCase() === 'false')
                                additionalBorrowingReason.push(loanPurpose.buyAShareInFreehold);
                            additionalBorrowingReason.push(loanPurpose.buyOutNonBorrower, loanPurpose.buyFreeholdTitleOrNewExtendedLease,
                                loanPurpose.buyLandToExtendSecurity, loanPurpose.payOffSecondCharge, loanPurpose.buyOutJointBorrower);
                            break;
                        default:
                            break;
                    }

                    // p6220 - pRepayUnsecuredDebtsOnRemortgageBTLApplicable
                    // p6221 - pRepayUnsecuredDebtsOnRemortgageInterestOnlyApplicable
                    if ((parameters.p6220 && parameters.p6220.toLowerCase() === 'false' &&
                        propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet) ||
                        (parameters.p6221 && parameters.p6221.toLowerCase() === 'false' &&
                        r5087AllOrPartInterestOnly(interestOnlyLoanAmount))) {
                        // Remove repayUnsecuredDebts
                        additionalBorrowingReason.splice(additionalBorrowingReason.indexOf(loanPurpose.repayUnsecuredDebts), 1);
                    }
                }

                return additionalBorrowingReason;
            }
        },

        r5154DetermineValidAdditionalBorrowingReasons: function (applicationType, propertyOwnership, percentagePropertyMortgaged,
                                                                 propertyPurpose, interestOnlyLoanAmount, parameters) {
            var filteredEnums = [];

            for (var property in enums.RDAdditionalBorrowingReasonCode) {
                if (enums.RDAdditionalBorrowingReasonCode.hasOwnProperty(property)) {
                    filteredEnums.push(enums.RDAdditionalBorrowingReasonCode[property]);
                }
            }

            if (applicationType === enums.RDApplicationTypeCode.newPurchase ||
                (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    (propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee)
                )
            ) {
                removeItemsFromArray(filteredEnums, [
                    enums.RDAdditionalBorrowingReasonCode.repayUnsecuredDebts,
                    enums.RDAdditionalBorrowingReasonCode.otherPersonalConsumption,
                    enums.RDAdditionalBorrowingReasonCode.businessPurposes,
                    enums.RDAdditionalBorrowingReasonCode.buyPropertyForMainResidenceAndLetCurrentProperty
                ]);
            }

            if (applicationType === enums.RDApplicationTypeCode.newPurchase ||
                (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership &&
                    percentagePropertyMortgaged === 1.0
                )
            ) {
                removeItemsFromArray(filteredEnums, [
                    enums.RDAdditionalBorrowingReasonCode.buyOutNonBorrower,
                    enums.RDAdditionalBorrowingReasonCode.buyOutJointBorrower
                ]);
            }

            if (applicationType === enums.RDApplicationTypeCode.newPurchase ||
                (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    (propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee)
                )
            ) {
                removeItemsFromArray(filteredEnums, [
                    enums.RDAdditionalBorrowingReasonCode.buyAShareInFreehold,
                    enums.RDAdditionalBorrowingReasonCode.buyFreeholdTitleOrNewExtendedLease,
                    enums.RDAdditionalBorrowingReasonCode.buyLandToExtendSecurity
                ]);
            }
            if (applicationType === enums.RDApplicationTypeCode.newPurchase ||
                (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    (propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee)
                )
            ) {
                removeItemFromArray(filteredEnums,
                    enums.RDAdditionalBorrowingReasonCode.payOffSecondCharge);
            }
            if (applicationType === enums.RDApplicationTypeCode.remortgage) {
                removeItemFromArray(filteredEnums,
                    enums.RDAdditionalBorrowingReasonCode.legalFees);
            }
            if (applicationType === enums.RDApplicationTypeCode.newPurchase ||
                (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    (propertyOwnership === enums.RDPropertyOwnershipTypeCode.standard ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee)
                ) || (applicationType === enums.RDApplicationTypeCode.remortgage &&
                propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership &&
                percentagePropertyMortgaged === 1.0)
            ) {
                removeItemFromArray(filteredEnums,
                    enums.RDAdditionalBorrowingReasonCode.buyAdditionalShareInASharedOwnership);
            }
            if (applicationType === enums.RDApplicationTypeCode.newPurchase ||
                (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    (propertyOwnership === enums.RDPropertyOwnershipTypeCode.standard ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee)
                ) || (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership &&
                    percentagePropertyMortgaged < 1
                )
            ) {
                removeItemFromArray(filteredEnums,
                    enums.RDAdditionalBorrowingReasonCode.buyFinalShareInASharedOwnership);
            }

            if (applicationType === enums.RDApplicationTypeCode.newPurchase ||
                (applicationType === enums.RDApplicationTypeCode.remortgage &&
                    (propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee) ||
                    (parameters.p6301.toLowerCase() === 'true' &&
                    (propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy ||
                    propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare))
                )
            ) {
                removeItemFromArray(filteredEnums,
                    enums.RDAdditionalBorrowingReasonCode.buyLandOrPropertySeparateFromTheSecurity);
            }

            if (propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare ||
                propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee ||
                propertyOwnership === enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember ||
                propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership){
                removeItemsFromArray(filteredEnums, [
                    enums.RDAdditionalBorrowingReasonCode.investmentPurposes,
                    enums.RDAdditionalBorrowingReasonCode.depositForProperty,
                    enums.RDAdditionalBorrowingReasonCode.giftToRelative
                ]);
            }

            if (applicationType === enums.RDApplicationTypeCode.remortgage &&
                (parameters.p6220.toLowerCase() === "false" &&
                propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet ||
                parameters.p6221.toLowerCase() === "false" &&
                this.r5087AllOrPartInterestOnly(interestOnlyLoanAmount))) {
                removeItemFromArray(filteredEnums, enums.RDAdditionalBorrowingReasonCode.repayUnsecuredDebts);
            }

            if (propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare &&
                parameters.p6302.toLowerCase() === 'true')
            {
                removeItemFromArray(filteredEnums, enums.RDAdditionalBorrowingReasonCode.buyAShareInFreehold);
            }

            if(propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare &&
                parseFloat(parameters.iP0001) > 2.8)
            {
                removeItemFromArray(filteredEnums, enums.RDAdditionalBorrowingReasonCode.payOffSecondCharge);
            }

            return filteredEnums;
        },


        r5086DeterminePropertyExpenditureTypes: function (householdExpenditureTypes, propertyOwnershipType) {

            var isEquityShare = rulesService.r7009IsEquityShare(propertyOwnershipType);
            var isSharedOwnership = rulesService.r7013IsSharedOwnership(propertyOwnershipType);
            var equityShareIndex = null;
            var sharedOwnershipRentIndex = null;

            angular.forEach(householdExpenditureTypes, function (expenditureType, index) {
                if (parseInt(expenditureType.value) === enums.RDPropertyExpenditureTypeCode.equityShareholderCharge)
                    equityShareIndex = index;

                if (parseInt(expenditureType.value) === enums.RDPropertyExpenditureTypeCode.sharedOwnershipRent)
                    sharedOwnershipRentIndex = index;
            });

            if (isEquityShare !== true)
                householdExpenditureTypes.splice(equityShareIndex, 1);

            if (isSharedOwnership !== true)
                householdExpenditureTypes.splice(sharedOwnershipRentIndex, 1);

            return householdExpenditureTypes;
        },

        r5087AllOrPartInterestOnly: function (interestOnlyLoanAmount) {
            return r5087AllOrPartInterestOnly(interestOnlyLoanAmount);
        },

        r3019CalculateTotalRepaymentAmount: function (totalLoanAmount, totalInterestOnlyAmount) {
            /// <summary>
            ///     This calculates the total repayment amount for this case
            ///     It is used in UC1219 - Capture Loan Requirements
            /// </summary>
            /// <param name="totalLoanAmount" type="numeric">total loan amount</param>
            /// <param name="interestOnlyLoanAmount" type="numeric">total interest only amount</param>
            var totalRepaymentAmount = totalLoanAmount - totalInterestOnlyAmount;
            if (totalRepaymentAmount < 0) {
                return 0;
            }
            return totalRepaymentAmount;
        },

        r5097DetermineDepositTypes: function (deposits, depositSourceTypes, propertyPurpose, existingDeposit) {
            var result = angular.copy(depositSourceTypes);

            for (var i = 0; i < deposits.length; i++) {
                if (deposits[i].source === enums.RDMortgageDepositSourceCode.builderCashback) {
                    removeItemFromValueset(result, enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive);
                    if (existingDeposit !== null &&
                        existingDeposit !== undefined &&
                        existingDeposit.source !== enums.RDMortgageDepositSourceCode.builderCashback ||
                        existingDeposit === null)
                        removeItemFromValueset(result, enums.RDMortgageDepositSourceCode.builderCashback);
                }

                if (deposits[i].source === enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive) {
                    removeItemFromValueset(result, enums.RDMortgageDepositSourceCode.builderCashback);
                    if (existingDeposit !== null &&
                        existingDeposit !== undefined &&
                        existingDeposit.source !== enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive ||
                        existingDeposit === null)
                        removeItemFromValueset(result, enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive);
                }
            }

            if (propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet){
                removeItemFromValueset(result, enums.RDMortgageDepositSourceCode.tenantsIncentiveScheme);
                removeItemFromValueset(result, enums.RDMortgageDepositSourceCode.lSAPOrFHTB);
                removeItemFromValueset(result, enums.RDMortgageDepositSourceCode.housingAssociationRSL);
            }

            return result;
        },

        r5416MaxEquityShareFullMarketValue: function (equityShareSchemeType, parameters) {
            /// <summary>
            ///     Determines the maximum value a property's Full Market Value can be for an Equity Share case.
            //      The returned value depends on the type of Equity Share scheme attributed to the case.
            /// </summary>
            /// <param name="equityShareSchemeType" type="numeric">Equity Share Scheme Type</param>
            /// <param name="parameters" type="object">parameters 0026, 0027, 0028, 0029, 0030,6060, 6061</param>
            equityShareSchemeType = parseInt(equityShareSchemeType);
            switch (equityShareSchemeType) {
                case enums.RDEquityShareTypeCode.buildersEquityShare:
                    return parameters.p6060;
                case enums.RDEquityShareTypeCode.helpToBuy:
                    return parameters.p0026;
                case enums.RDEquityShareTypeCode.helpToBuyScotland:
                    return parameters.p0027;
                case enums.RDEquityShareTypeCode.helpToBuyWales:
                    return parameters.p0028;
                case enums.RDEquityShareTypeCode.homebuyDirect:
                    return parameters.p0029;
                case enums.RDEquityShareTypeCode.houseAssociationOrLocalAuthority:
                    return parameters.p6061;
                case enums.RDEquityShareTypeCode.northernIrelandCoownershipHousingAssociation:
                    return parameters.p0030;
            }

            return parameters.p2243;
        },

        r3014EmploymentHistoryIsValid: function (employmentType, contractTermType, timeContractingYears, timeContractingMonths, p2257) {
            /// <summary>
            ///     This validates whether a full employment history has been captured
            ///     It use in UC1206 - Capture Income Details
            /// </summary>
            /// <param name="employmentType" type="numeric">employment status</param>
            /// <param name="contractTermType" type="numeric">employment type</param>
            /// <param name="timeContractingYears" type="numeric">contracting time years</param>
            /// <param name="timeContractingMonths" type="numeric">contracting time months</param>
            /// <param name="p2257" type="numeric">pMinimumEmploymentHistoryPeriodMonths</param>
            if (employmentType === enums.RDEmploymentStatusCode.employed &&
                (contractTermType === enums.RDEmploymentContractTypeCode.fixedTermContract || contractTermType === enums.RDEmploymentContractTypeCode.subContractorFixedTerm)
                && (timeContractingMonths + timeContractingYears * 12 < p2257)) {
                return false;
            }

            if ((employmentType === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare
                || employmentType === enums.RDEmploymentStatusCode.selfEmployedSoleTrader
                || employmentType === enums.RDEmploymentStatusCode.selfEmployedPartner) && (timeContractingMonths + timeContractingYears * 12 < p2257)) {
                return false;
            }

            if ((employmentType === enums.RDEmploymentContractTypeCode.permanent
                || employmentType === enums.RDEmploymentContractTypeCode.subContractorOpenEnded
                || employmentType === enums.RDEmploymentContractTypeCode.directorOrShareholderLessThanOrEqualTwentyPercentShare) && (timeContractingMonths + timeContractingYears * 12 < p2257)) {
                return false;
            }

            if (contractTermType === enums.RDEmploymentContractTypeCode.temporary && (timeContractingMonths + timeContractingYears * 12 < p2257)) {
                return false;
            }

            return true;
        },

        r3015CalculateRetirementDate: function (birthday, plannedRetirementAge) {
            /// <summary>
            ///     This calculates the date at which the customer is expected to retire
            ///     It use in UC1207 - Capture Retirement Income
            /// </summary>
            /// <param name="birthday" type="string">contracting time years</param>
            /// <param name="plannedRetirementAge" type="numeric">planned retirement age</param>
            if (birthday !== undefined && birthday !== null &&
                plannedRetirementAge !== undefined && plannedRetirementAge !== null && plannedRetirementAge !== "") {
                return moment(birthday, 'L', true).add('year', plannedRetirementAge);
            }
            return null;
        },

        r3016CalculateMortgageTermEndDate: function (mortgateTermInMonths) {
            /// <summary>
            ///     This calculates the end date of the mortgage
            ///     It use in UC1207 - Capture Retirement Income
            /// </summary>
            /// <param name="mortgateTermInMonths" type="numeric">Total mortgate term in months</param>
            if (mortgateTermInMonths == null || mortgateTermInMonths == undefined)
                return null;

            var firstDay = moment().add('months', 1).startOf('month');
            return moment(firstDay, 'L', true).add('months', mortgateTermInMonths).subtract('days', 1);
        },

        r3017AfterWorksValueIsApplicable: function (reason) {
            /// <summary>
            ///     This validates if the current case is applicable for after works
            ///     It use in UC1218 - Capture Additional Borrowing Reasons
            /// </summary>
            /// <param name="reason" type="string">reason</param>
            var applicable = false;
            if (reason === enums.RDAdditionalBorrowingReasonCode.structuralHomeImprovement ||
                reason === enums.RDAdditionalBorrowingReasonCode.nonStructuralHomeImprovement ||
                reason === enums.RDAdditionalBorrowingReasonCode.buyFreeholdTitleOrNewExtendedLease ||
                reason === enums.RDAdditionalBorrowingReasonCode.buyLandToExtendSecurity) {
                applicable = true;
            }
            return applicable;
        },

        r3018MinimumEquityGiftSavingsDeposit: function (depositDetails, propertyOwnershipType, purchasePrice, p2251) {
            /// <summary>
            ///     determines whether the amount of deposit from this source meets the minimum percentage of the total deposit amount required
            ///     It use in UC1203 - Capture Deposit Details
            /// </summary>
            /// <param name="depositDetails" type="array">reason</param>
            /// <param name="propertyOwnershipType" type="numeric">property ownership type</param>
            /// <param name="p2251" type="numeric">pEquityGiftSavingsDepositMin</param>
            if (depositDetails) {
                var sumDepositSourceAmount = 0;
                for (var i = 0; i < depositDetails.length; i += 1) {
                    if (depositDetails[i].source === enums.RDMortgageDepositSourceCode.savings
                        || depositDetails[i].source === enums.RDMortgageDepositSourceCode.equity
                        || depositDetails[i].source === enums.RDMortgageDepositSourceCode.gift) {
                        sumDepositSourceAmount += depositDetails[i].amount;
                    }
                }

                if (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.equityShare && (sumDepositSourceAmount < (p2251 / 100 * purchasePrice))) {
                    return true;
                }
            }
            return false;
        },

        r3001StandardOwnershipAvailability: function () {
            return true;
        },

        r3002RtbOwnershipAvailability: function (applicationType, propertyPurpose, customerTypes) {
            /// <summary>Return True or False value to determines whether 'Right to buy ownership' property is availability or not </summary>
            /// <param name="applicationType" type="numeric">Application Type</param>
            /// <param name="propertyPurpose" type="numeric">Property Purpose</param>
            /// <param name="customerTypes" type="numeric">List of customer types</param>
            var applicantType = r3000DetermineOverallCustomerType(customerTypes);
            if (applicationType === enums.RDApplicationTypeCode.newPurchase) {
                if (propertyPurpose === enums.RDPropertyPurposeTypeCode.secondProperty
                    && (applicantType === enums.RDApplicantTypeCode.firstTimeBuyer || applicantType === enums.RDApplicantTypeCode.previousBorrowerWithLender)) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (propertyPurpose === enums.RDPropertyPurposeTypeCode.ownerOccupation
                    && (applicantType === enums.RDApplicantTypeCode.firstTimeBuyer || applicantType === enums.RDApplicantTypeCode.previousBorrowerWithLender)) {
                    return false;
                } else {
                    return true;
                }
            }
        },

        r3011NewBuyOwnershipAvailability: function (applicationType, customerTypes) {
            /// <summary>Return True or False value to determines whether 'New Buy Ownership' property is availability or not </summary>
            /// <param name="applicationType" type="numeric">Application Type</param>
            /// <param name="customerTypes" type="numeric">List of customer types</param>
            if (applicationType === enums.RDApplicationTypeCode.newPurchase
                && r3000DetermineOverallCustomerType(customerTypes) === enums.RDApplicantTypeCode.firstTimeBuyer) {
                return true;
            }
            return false;
        },

        r3023CalculateReservationExpiryDate: function (reservationDate, p0010) {
            /// <summary>
            ///     Calculates the expiry date of the product(s) reservation.
            /// </summary>
            /// <param name="reservationDate" type="date">The date of reservation</param>
            /// <param name="p0010" type="integer">pPackageReservationValidityPeriodDays</param>
            /// <return type="date">
            ///     The expiry date
            /// </return>
            return moment(reservationDate).add('days', p0010);
        },

        r3024CalculateRefundAmount: function (productsInSameFamily, totalPaidBookingFeeAmount, totalPaidProductFeeAmount, totalProposedBookingFeeAmount, totalProposedProductFeeAmount) {
            /// <summary>
            ///     Calculates the refund amount due when a product reservation has been changed.
            /// </summary>
            /// <param name="productsInSameFamily" type="boolean">Indicates the selected products are from the same family</param>
            /// <param name="totalPaidBookingFeeAmount" type="double">The total amount of paid booking fee</param>
            /// <param name="totalPaidProductFeeAmount" type="double">The total amount of paid product fee</param>
            /// <param name="proposedBookingFeeAmount" type="double">The amount of proposed booking fee</param>
            /// <param name="proposedProductFeeAmount" type="double">The amount of proposed product fee</param>
            /// <return type="double">
            ///     The refund amount
            /// </return>

            var refundAmount = 0;
            var bookingFeePaid = totalPaidBookingFeeAmount > 0;
            var productFeePaid = totalPaidProductFeeAmount > 0;

            if (productsInSameFamily && bookingFeePaid && (totalPaidBookingFeeAmount !== totalProposedBookingFeeAmount)) {
                refundAmount += totalPaidBookingFeeAmount;
            }

            if (productFeePaid && (totalPaidProductFeeAmount !== totalProposedProductFeeAmount)) {
                refundAmount += totalPaidProductFeeAmount;
            }

            return refundAmount;
        },

        r5054IsFutureIncomeDetailNeeded: function (caseData, applicantIndex, p6209) {

            if ((!caseData) || (!caseData.loanRequirement) || (!caseData.loanRequirement.mortgageTermMonths) || (caseData.loanRequirement.mortgageTermMonths <= 0) ||
                (!caseData.applicants) || (!caseData.applicants[applicantIndex]) || (!caseData.applicants[applicantIndex].retirementAge) ||
                (!caseData.applicants[applicantIndex].dob)) {

                return false;
            }

            var _notRetired = r5052IsApplicantNotRetired(caseData.applicants[applicantIndex]);
            var _mortgageTermEndDate = rulesService.r3016CalculateMortgageTermEndDate(caseData.loanRequirement.mortgageTermMonths);
            var _retirementDate = rulesService.r3015CalculateRetirementDate(caseData.applicants[applicantIndex].dob, caseData.applicants[applicantIndex].retirementAge);

            return (
                _notRetired && (_mortgageTermEndDate.diff(_retirementDate, 'days', true) >= p6209)
            );
        },

        r5056CalculateFutureIncomeUserInstructionMessage: function (msg2115, forename, surname) {
            /// <summary>
            ///     This rule defines the contents of the message used to direct the user on the Future Income screen.
            ///     This screen is at applicant level and so the instance of Applicant to be used will be the one having their data
            ///     captured in the current pass through the Income screens.
            /// </summary>
            /// <param name="msg2115" type="string">Message catalog text for message 2115</param>
            /// <param name="forename" type="string"Applicant forename</param>
            /// <param name="surname" type="string">Applicant surname</param>
            /// <return type="string">
            ///     User message text
            /// </return>
            return (msg2115.replace('$0', forename + " " + surname));
        },

        r5917ConsumerBTLDisplayDoYouCurrentlyOwnOtherLetProperties: function (firstTimeLandlord) {
            if (firstTimeLandlord === false) {
                return true;
            }
            return false;
        },

        r5918ConsumerBTLDisplayInheritedOrBeenGiftedProperty: function (firstTimeLandlord, ownOrLetProperty) {
            if (firstTimeLandlord === true || ownOrLetProperty === true) {
                return true
            }
            return false;
        },

        r5919ConsumerBTLDisplayDoApplicantsOrImmediateRelativesLiveInProperty: function (applicationType, inheritedGiftedProperty, isConsumerBTLRestricted) {
            if (parseInt(applicationType) === enums.RDApplicationTypeCode.newPurchase || isConsumerBTLRestricted || (inheritedGiftedProperty === false && !isConsumerBTLRestricted)) {
                return true;
            }
            return false;
        },
        /* DIP - Current Income */
        r5065IsOccupationDescriptionNeeded: function (occupation) {
            return (occupation === enums.RDOccupationTypeCode.other);
        },

        r5066IsProbationPeriodNeeded: function (startYear, startMonth, p2254, status, contract, p2114) {

            var isEmployedType = false;
            if ((status === enums.RDEmploymentIncomeType.employed && (
                contract === enums.RDEmploymentContractTypeCode.permanent ||
                contract === enums.RDEmploymentContractTypeCode.subContractorOpenEnded ||
                contract === enums.RDEmploymentContractTypeCode.pieceWorker)) ||
                (status === enums.RDEmploymentIncomeType.directorOrShareholderLessThanOrEqualTwentyPercentShare)) {
                isEmployedType = true;
            }

            var withinPeriod = false;
            if (startYear && startMonth) {
                var enteredDate = moment({year: startYear, month: parseInt(startMonth) - 1, day: 1});

                if (enteredDate.isValid) {
                    var cutOff = (moment().subtract('months', p2254));
                    withinPeriod = (enteredDate > cutOff);
                }
            }
            return withinPeriod && (p2114 === 'true') && isEmployedType;
        },

        r5063IsProfessionalPracticeNeeded: function (startYear, startMonth, p2253, status, p2113) {
            var result = false;
            if (startYear && startMonth) {
                var enteredDate = moment({year: startYear, month: startMonth - 1, day: 1});

                if (enteredDate.isValid) {
                    var cutOff = (moment().subtract('months', p2253));
                    result = enteredDate > cutOff;
                }
            }
            return result && status === enums.RDEmploymentStatusCode.selfEmployedPartner && (p2113 === 'true');
        },

        r5080ZeroEarningsEntered: function (basic, bonus, overtime, commission) {
            var totalAmount = 0;
            totalAmount += (basic ? parseInt(basic) : 0);
            totalAmount += (bonus ? parseInt(bonus) : 0);
            totalAmount += (overtime ? parseInt(overtime) : 0);
            totalAmount += (commission ? parseInt(commission) : 0);
            return totalAmount > 0;
        },

        r5060IsBasicSalaryNeeded: function (contract, employedForTaxPurposes, status) {
            // Call central rule
            return r5060IsBasicSalaryNeeded(contract, employedForTaxPurposes, status);
        },

        r5061IsFixedTermContract: function (contract) {
            return (contract === enums.RDEmploymentContractTypeCode.fixedTermContract || contract === enums.RDEmploymentContractTypeCode.subContractorFixedTerm);
        },

        r6187ContractRenewalQuestionIsApplicable :function(p6320, p6357, employmentContract) {
            return p6320 === 'true' && (parseInt(employmentContract) === enums.RDEmploymentContractTypeCode.fixedTermContract ||
                (p6357 === 'true' && parseInt(employmentContract) === enums.RDEmploymentContractTypeCode.subContractorFixedTerm));
        },

        r5067IsSalaryIncludingDividendsNeeded: function (status) {
            return (status === enums.RDEmploymentIncomeType.directorOrShareholderGreaterThanTwentyPercentShare);
        },

        r5062IsTimeTradingNeeded: function (status) {
            return (status === enums.RDEmploymentIncomeType.selfEmployedPartner || status === enums.RDEmploymentIncomeType.selfEmployedSoleTrader || status === enums.RDEmploymentIncomeType.directorOrShareholderGreaterThanTwentyPercentShare);
        },
        r5069IsShareOfNetProfitNeeded: function (status) {
            return (status === enums.RDEmploymentIncomeType.selfEmployedPartner);
        },
        r5059IsEmployedForTaxNeeded: function (contract, p2112) {
            return (contract === enums.RDEmploymentContractTypeCode.subContractorFixedTerm || contract === enums.RDEmploymentContractTypeCode.subContractorOpenEnded) && (p2112 === 'true');
        },

        r5064IsNetProfitNeeded: function (status, contract, employedForTaxPurposes) {
            return (((contract === enums.RDEmploymentContractTypeCode.subContractorFixedTerm || contract === enums.RDEmploymentContractTypeCode.subContractorOpenEnded) && (employedForTaxPurposes == 'false'))
            || (status === enums.RDEmploymentIncomeType.selfEmployedPartner || status === enums.RDEmploymentIncomeType.selfEmployedSoleTrader || status === enums.RDEmploymentIncomeType.directorOrShareholderGreaterThanTwentyPercentShare));
        },

        r5076IsEmploymentStatusNeeded: function (incomeType) {
            return (incomeType === enums.RDIncomeTypeCode.employmentIncome);
        },

        r5057IsContractTypeNeeded: function (incomeType, status) {
            return (incomeType === enums.RDIncomeTypeCode.employmentIncome && status === enums.RDEmploymentIncomeType.employed);
        },

        r5083IsPreviousEmploymentNeeded: function (caseData, applicantIndex, minEmploymentHistory) {
            var applicable = false;
            if ((!caseData) ||
                (!caseData.applicants) || (!caseData.applicants[applicantIndex]) || (!caseData.applicants[applicantIndex].currentIncomes)) {

                return false;
            }

            angular.forEach(caseData.applicants[applicantIndex].currentIncomes, function (currentIncome) {
                if (currentIncome.mainEmployment) {
                    var currentMonth = new Date().getMonth() + 1;
                    var currentYear = new Date().getFullYear();
                    var months = ((currentYear - currentIncome.startYear) * 12);
                    months -= currentIncome.startMonth;
                    months += currentMonth;

                    if (months < minEmploymentHistory) {
                        applicable = true;
                    }
                }
            });

            return applicable;
        },

        r5084IsOtherIncomeNeeded: function (incomeType) {
            return (incomeType === enums.RDIncomeTypeCode.otherIncome);
        },

        r5072IsMainEmploymentNeeded: function (currentIncomes, status, currentIndex, netMonthlyIncome) {

            if (status == undefined || status === enums.RDEmploymentStatusCode.notEmployed ||
                (parseInt(netMonthlyIncome) === 0 &&
                (status === enums.RDEmploymentStatusCode.retired ||
                status === enums.RDEmploymentStatusCode.homeMaker ||
                status === enums.RDEmploymentStatusCode.student))) {
                return false;
            }

            var result = true;
            if (currentIncomes && currentIncomes.length > 0) {
                angular.forEach(currentIncomes, function (value, index) {
                    if ((value.mainEmployment) && (index != (parseInt(currentIndex) - 1)))
                        result = false;
                });
            }
            return result;
        },

        r5092IsMainEmploymentStatusKnown: function (mainEmploymentStatus, netMonthlyIncome) {
            return (mainEmploymentStatus && parseInt(netMonthlyIncome) > 0);
        },

        r5130NoEmploymentIncomeEntered: function (status, netMonthlyIncome) {
            return (((status === enums.RDEmploymentStatusCode.employed) ||
                (status === enums.RDEmploymentStatusCode.selfEmployedPartner) ||
                (status === enums.RDEmploymentStatusCode.selfEmployedSoleTrader) ||
                (status === enums.RDEmploymentStatusCode.directorOrShareholderLessThanOrEqualTwentyPercentShare) ||
                (status === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare)
            ) && parseInt(netMonthlyIncome) === 0);
        },

        r5112FilterIncomeType: function (status, employmentIncomeRecordsLimitReached) {
            return (status === enums.RDEmploymentStatusCode.notEmployed || employmentIncomeRecordsLimitReached);
        },

        r5126InvalidEmploymentIncome: function (mainEmploymentStatus, currentIncomes) {
            var hasEmploymentIncome = false;
            if (currentIncomes && currentIncomes.length > 0) {
                angular.forEach(currentIncomes, function (value) {
                    if (value.employmentStatus)
                        hasEmploymentIncome = true;
                });
            }
            var isNotEmployed = (mainEmploymentStatus === enums.RDEmploymentStatusCode.notEmployed);

            return (isNotEmployed && hasEmploymentIncome);
        },

        r5127ContractNotContinuing: function (expectingContractRenewal, timeRemainingYears, timeRemainingMonths) {
            var noTimeRemaining = !isNaN(timeRemainingYears) && timeRemainingYears === '0' && !isNaN(timeRemainingMonths) && timeRemainingMonths === '0';

            return noTimeRemaining && expectingContractRenewal === 'false';
        },

        r5161OtherIncomeSourceNeeded: function (mainEmploymentStatus, netMonthlyIncome, currentIncomes) {

            var isNotEmployed = ((mainEmploymentStatus === enums.RDEmploymentStatusCode.notEmployed) ||
            (mainEmploymentStatus === enums.RDEmploymentStatusCode.retired) ||
            (mainEmploymentStatus === enums.RDEmploymentStatusCode.homeMaker) ||
            (mainEmploymentStatus === enums.RDEmploymentStatusCode.student));

            var hasIncome = false;
            if (currentIncomes && currentIncomes.length > 0) {
                hasIncome = true;
            }

            var netIncomePresent = (!isNaN(netMonthlyIncome) && (parseInt(netMonthlyIncome) > 0));

            return (isNotEmployed && netIncomePresent && !hasIncome);
        },

        r0100NonGuaranteedBonusApplicable: function (employmentStatus, contractType, employedForTax, p2100) {

            employmentStatus = parseInt(employmentStatus);
            contractType = parseInt(contractType);

            return (p2100 === 'true') && r5060IsBasicSalaryNeeded(contractType, employedForTax, employmentStatus);
        },

        r0101NonGuaranteedOvertimeApplicable: function (employmentStatus, contractType, employedForTax, p2102) {

            employmentStatus = parseInt(employmentStatus);
            contractType = parseInt(contractType);

            return (p2102 === 'true') && r5060IsBasicSalaryNeeded(contractType, employedForTax, employmentStatus);
        },

        r0103NonGuaranteedCommissionApplicable: function (employmentStatus, contractType, employedForTax, p2104) {
            employmentStatus = parseInt(employmentStatus);
            contractType = parseInt(contractType);

            return (p2104 === 'true') && r5060IsBasicSalaryNeeded(contractType, employedForTax, employmentStatus);
        },

        r0105AllowancesApplicable: function (employmentStatus, contractType, employedForTax, p2106) {

            employmentStatus = parseInt(employmentStatus);
            contractType = parseInt(contractType);

            if ((contractType === enums.RDEmploymentContractTypeCode.permanent ||
                contractType === enums.RDEmploymentContractTypeCode.fixedTermContract ||
                contractType === enums.RDEmploymentContractTypeCode.zeroHoursContract ||
                contractType === enums.RDEmploymentContractTypeCode.temporary ||
                contractType === enums.RDEmploymentContractTypeCode.pieceWorker) && (p2106 === 'true')) {
                return true;
            }

            if ((contractType === enums.RDEmploymentContractTypeCode.subContractorFixedTerm ||
                contractType === enums.RDEmploymentContractTypeCode.subContractorOpenEnded ) && employedForTax === 'true' && (p2106 === 'true')) {
                return true;
            }

            return (employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderLessThanOrEqualTwentyPercentShare && (p2106 === 'true'));
        },

        r0107TimeContractingApplicable: function (employmentContract, p2107, p6356) {

            return p2107 === 'true' && (employmentContract === enums.RDEmploymentContractTypeCode.zeroHoursContract ||
                employmentContract === enums.RDEmploymentContractTypeCode.subContractorFixedTerm ||
                employmentContract === enums.RDEmploymentContractTypeCode.subContractorOpenEnded ||
                (employmentContract === enums.RDEmploymentContractTypeCode.fixedTermContract && p6356 === 'true'))

        },

        r0108DailyRateApplicable: function (employmentContract, p2108) {
            return ((employmentContract === enums.RDEmploymentContractTypeCode.zeroHoursContract ||
            employmentContract === enums.RDEmploymentContractTypeCode.fixedTermContract ||
            employmentContract === enums.RDEmploymentContractTypeCode.subContractorFixedTerm ||
            employmentContract === enums.RDEmploymentContractTypeCode.subContractorOpenEnded) && (p2108 === 'true'));
        },

        r0109NumberOfPartnersApplicable: function (employmentStatus, p2109) {
            return (employmentStatus === enums.RDEmploymentStatusCode.selfEmployedPartner && (p2109 === 'true'));
        },

        r0110PercentageShareholdingApplicable: function (employmentStatus, p2110) {
            return (employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare && (p2110 === 'true'));
        },

        r0111GrossSalaryLastButOneApplicable: function (employmentStatus, p2111) {
            return (employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare && (p2111 === 'true'));
        },

		r0113IsGrossSalaryAndDividendsLastButTwoApplicable: function (employmentStatus, p2111, p6321) {
			return (employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare
											&& (p2111 === 'true')
											&& (p6321 === 'true'));
		},

		r6189IsGrossProfitCurrentYearApplicable: function (employmentStatus, p6322) {
			return (employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare && (p6322 == 'true'));
		},


        /* DIP - Previous Employment */

        r5145IsPrevContractTypeNeeded: function (employmentStatus) {
            employmentStatus = parseInt(employmentStatus);
            return (employmentStatus === enums.RDEmploymentStatusCode.employed);
        },

        r5146IsPrevOccupationTypeNeeded: function (employmentStatus) {
            employmentStatus = parseInt(employmentStatus);
            return (employmentStatus === enums.RDEmploymentStatusCode.employed ||
            employmentStatus === enums.RDEmploymentStatusCode.selfEmployedPartner ||
            employmentStatus === enums.RDEmploymentStatusCode.selfEmployedSoleTrader ||
            employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare ||
            employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderLessThanOrEqualTwentyPercentShare);
        },

        r5147IsPrevOccupationDescriptionNeeded: function (occupation) {
            occupation = parseInt(occupation);
            return (occupation === enums.RDOccupationTypeCode.other );
        },

        r5148IsPrevBasicSalaryNeeded: function (employmentStatus, contractType, employedForTax) {

            employmentStatus = parseInt(employmentStatus);
            contractType = parseInt(contractType);


            if (employmentStatus === enums.RDEmploymentStatusCode.employed && contractType === enums.RDEmploymentContractTypeCode.permanent ||
                contractType === enums.RDEmploymentContractTypeCode.fixedTermContract ||
                contractType === enums.RDEmploymentContractTypeCode.temporary ||
                contractType === enums.RDEmploymentContractTypeCode.zeroHoursContract ||
                contractType === enums.RDEmploymentContractTypeCode.pieceWorker) {
                return true;
            }

            if (contractType === enums.RDEmploymentContractTypeCode.subContractorFixedTerm ||
                contractType === enums.RDEmploymentContractTypeCode.subContractorOpenEnded) {
                return (employedForTax === 'true');
            }

            return (employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderLessThanOrEqualTwentyPercentShare);
        },

        r5149IsPrevEmployedForTaxNeeded: function (contractType) {
            contractType = parseInt(contractType);
            return (contractType === enums.RDEmploymentContractTypeCode.subContractorFixedTerm ||
            contractType === enums.RDEmploymentContractTypeCode.subContractorOpenEnded);
        },

        r5150IsPrevSalaryIncludingDividendsNeeded: function (employmentStatus) {
            employmentStatus = parseInt(employmentStatus);
            return (employmentStatus === enums.RDEmploymentStatusCode.directorOrShareholderGreaterThanTwentyPercentShare);
        },

        r5151IsPrevNetProfitNeeded: function (employmentStatus, contractType, employedForTax) {
            employmentStatus = parseInt(employmentStatus);
            contractType = parseInt(contractType);

            if (employmentStatus === enums.RDEmploymentStatusCode.selfEmployedSoleTrader || employmentStatus === enums.RDEmploymentStatusCode.selfEmployedPartner) {
                return true;
            }

            if (employmentStatus === enums.RDEmploymentStatusCode.employed &&
                contractType === enums.RDEmploymentContractTypeCode.subContractorFixedTerm || contractType === enums.RDEmploymentContractTypeCode.subContractorOpenEnded) {
                return (employedForTax === 'false');
            }

            return false;
        },


        /* DIP - Existing Mortgages */
        r5134OtherExistingMortgageLender: function (lender) {
            return (parseInt(lender) === 14);
        },

        r5132ExistingMortgageToBeRetained: function (mortgageFuture) {
            return (parseInt(mortgageFuture) === 2);
        },

        r6370TenancyInPlaceApplicable: function (p6379, propertyLetOut) {
            return p6379.toLowerCase() === 'true' && propertyLetOut === true;
        },

        r6371RemainingTermApplicable: function(p6380){
            return p6380.toLowerCase() === 'true';
        },

        r5133PropertyToBeLetOut: function (propertyLetOut) {
            return (propertyLetOut === 'true');
        },

        r5073MultipleApplicants: function (applicants) {
            return (applicants.length > 1);
        },

        r5137IsExistingBorrower: function (caseData) {
            var result = false;
            if (caseData && caseData.applicants
                && caseData.applicants.length > 0) {
                angular.forEach(caseData.applicants, function (value) {
                    if (parseInt(value.applicantType) === enums.RDApplicantTypeCode.existingBorrowerWithLender ||
                        parseInt(value.applicantType) === enums.RDApplicantTypeCode.borrowerWithOtherLender) {
                        result = true;
                    }
                });
            }
            return result;
        },

        r5153IsBalanceBeingTransferredFromOtherLender: function (caseData) {
            return (caseData && caseData.loanRequirement && caseData.loanRequirement.currentMortgageRedemptionAmount
            && caseData.loanRequirement.currentMortgageRedemptionAmount > 0);
        },

        r5152NoExistingMortgageAddedToCase: function (caseData) {
            return (!caseData.existingMortgages || caseData.existingMortgages.length === 0);
        },

        r5136DeclaredExistingMortgagesNotEntered: function (caseData, outstandingValue) {
            var balanceBeingTransfered = this.r5153IsBalanceBeingTransferredFromOtherLender(caseData);

            return balanceBeingTransfered && outstandingValue > 0;
        },

        r5039_CalculateRepaymentAmount: function(totalLoanAmount, interestOnlyAmount){
            return totalLoanAmount - interestOnlyAmount;
        },

        r6357_DebtConAdditionalBorrowingNotSupportedByLoansReqs: function(p6396, totalLoanAmount, interestOnlyAmount, additionalBorrowing){
             var pDebtConAdditionalBorrowingOnSeparateLoanPart = p6396.toLowerCase() === 'true';
             if (!pDebtConAdditionalBorrowingOnSeparateLoanPart){
                 return false;
             }
            var repaymentAmount = this.r5039_CalculateRepaymentAmount(totalLoanAmount, interestOnlyAmount);
            var additionalBorrowingForDebtConAmount = this.r6302_CalculateDebtConsolidationAdditionalBorrowing(additionalBorrowing);

            return repaymentAmount < additionalBorrowingForDebtConAmount;

        },

        /* Product Selection */

        r5163IsThereInterestOnlyandRepaymentBorrowing: function (totalLoanAmount, interestOnlyAmount) {
            return totalLoanAmount > interestOnlyAmount && interestOnlyAmount > 0;
        },

        r5164IsThereInterestOnlyBorrowingOnly: function (totalLoanAmount, interestOnlyAmount) {
            return interestOnlyAmount === totalLoanAmount;
        },

        r5165IsThereRepaymentBorrowingOnly: function (interestOnlyAmount) {
            return interestOnlyAmount === 0;
        },


        r5212DoProductsShownHaveInterestOnlyandRepaymentBorrowing: function (totalLoanAmount, interestOnlyAmount) {
            return totalLoanAmount > interestOnlyAmount && interestOnlyAmount > 0;
        },
        r5213DoProductsShownHaveInterestOnlyBorrowingOnly: function (totalLoanAmount, interestOnlyAmount) {
            return totalLoanAmount === interestOnlyAmount;
        },
        r5214DoProductsShownHaveRepaymentBorrowingOnly: function (interestOnlyAmount) {
            return interestOnlyAmount === 0;
        },

        r5168IsThereIntOnlyandRepaymentUnallocated: function (productCount, currentRepaymentTotal, currentInterestOnlyTotal, totalLoanAmount, interestOnlyAmount) {
            var repaymentAmount = (totalLoanAmount - interestOnlyAmount);
            return ((productCount > 0) && (repaymentAmount > 0 && currentRepaymentTotal < repaymentAmount) && (interestOnlyAmount > 0 && currentInterestOnlyTotal < interestOnlyAmount));
        },
        r5169IsThereInterestOnlyUnallocated: function (productCount, currentRepaymentTotal, currentInterestOnlyTotal, totalLoanAmount, interestOnlyAmount) {
            var repaymentAmount = (totalLoanAmount - interestOnlyAmount);
            return ((productCount > 0) && (interestOnlyAmount > 0 && currentInterestOnlyTotal < interestOnlyAmount) && (repaymentAmount === 0 || currentRepaymentTotal === repaymentAmount));
        },
        r5170IsThereRepaymentUnallocated: function (productCount, currentRepaymentTotal, currentInterestOnlyTotal, totalLoanAmount, interestOnlyAmount) {
            var repaymentAmount = (totalLoanAmount - interestOnlyAmount);
            return ((productCount > 0) && (repaymentAmount > 0 && currentRepaymentTotal < repaymentAmount) && (interestOnlyAmount === 0 || currentInterestOnlyTotal === interestOnlyAmount));
        },

        r5166HaveLoanPartsBeenEntered: function (productCount) {
            return (productCount > 0);
        },

        r5171HaveAllLoanPartsBeenEntered: function (productCount, currentRepaymentTotal, currentInterestOnlyTotal, totalLoanAmount, interestOnlyAmount) {
            var repaymentAmount = (totalLoanAmount - interestOnlyAmount);
            return ((productCount > 0) && (repaymentAmount === 0 || currentRepaymentTotal === repaymentAmount) && (interestOnlyAmount === 0 || currentInterestOnlyTotal === interestOnlyAmount));
        },

		r5172IsSumLoanPartsGreaterThanTotalLoanAmount: function (productCount, currentRepaymentTotal, currentInterestOnlyTotal, totalLoanAmount, interestOnlyAmount) {
            var repaymentAmount = (totalLoanAmount - interestOnlyAmount);
            return ((productCount > 0) && ((currentRepaymentTotal > repaymentAmount) || (currentInterestOnlyTotal > interestOnlyAmount)));
        },

        r5173ProductCodeNotFound: function (code, products) {
            if (!code || code.length === 0) {
                return false;
            }

            if (!products || products.length === 0) {
                return false;
            }

            for (var p = 0; p < products.length; p++) {
                if (products[p].code === code) {
                    return false;
                }
            }
            return true;
        },

		r5180SumOfRepaymentLoanPartsDoesNotEqualTotalRepaymentLoanAmount: function (productCount, currentRepaymentTotal, totalLoanAmount, interestOnlyAmount) {
			var repaymentAmount = (totalLoanAmount - interestOnlyAmount);
			return ((productCount > 0) && (currentRepaymentTotal > repaymentAmount));
		},

		r5174SumOfIOLoanPartsDoesNotEqualTotaIIOLoanAmount: function (productCount, currentInterestOnlyTotal, interestOnlyAmount) {
			return ((productCount > 0) && (currentInterestOnlyTotal > interestOnlyAmount));
		},

		r5386AreLoanPartAmountsInvalid: function (interestOnlyAmount, repaymentAmount) {
            var totalEntered = (interestOnlyAmount || 0) + (repaymentAmount || 0);
            return totalEntered <= 0;
        },

        r5387NoProductsFound: function (products) {
            return (!products || products.length === 0);
        },

        r6358_DebtConAdditionalBorrowingOnSeparateLoanPart: function(p6396, additionalBorrowing){
             var pDebtConAdditionalBorrowingOnSeparateLoanPart = p6396.toLowerCase() === 'true';
             if (!pDebtConAdditionalBorrowingOnSeparateLoanPart){
                 return false;
             }
            var debtConsolidationForAdditionalBorrowing = this.r6302_CalculateDebtConsolidationAdditionalBorrowing(additionalBorrowing);
            return debtConsolidationForAdditionalBorrowing > 0;
        },

        r6399_CalculateDebtConAdditionalBorrowingUnallocated: function(additionalBorrowing, selectedProducts){
            var debtConsolidationForAdditionalBorrowingAmount = this.r6302_CalculateDebtConsolidationAdditionalBorrowing(additionalBorrowing);
            var debtConsolidationRepaymentAmount = 0;
            selectedProducts.forEach(function(selectedProduct){
                if (selectedProduct.forDebtConsolidation === true){
                    debtConsolidationRepaymentAmount += selectedProduct.repaymentAmount;
                }
            });

            return debtConsolidationForAdditionalBorrowingAmount - debtConsolidationRepaymentAmount;
        },

        r6413_SumOfDebtConLoanPartsGreaterThanTotalDebtConAmount: function(p6396, additionalBorrowing, selectedProducts){
            var debtConAdditionalBorrowingOnSeparateLoanPart = this.r6358_DebtConAdditionalBorrowingOnSeparateLoanPart(p6396, additionalBorrowing);
            if (!debtConAdditionalBorrowingOnSeparateLoanPart){
                return false;
            }
            var unallocatedAdditionalBorrowingForDebtConsolidationAmount = this.r6399_CalculateDebtConAdditionalBorrowingUnallocated(additionalBorrowing, selectedProducts);

            return unallocatedAdditionalBorrowingForDebtConsolidationAmount < 0
        },

        r6415_SumOfDebtConLoanPartsLessThatTotalDebtConAmount: function(p6396, additionalBorrowing, selectedProducts){
            if (selectedProducts.length == 0){
                return false;
            }
            var debtConAdditionalBorrowingOnSeparateLoanPart = this.r6358_DebtConAdditionalBorrowingOnSeparateLoanPart(p6396, additionalBorrowing);
            if (!debtConAdditionalBorrowingOnSeparateLoanPart){
                return false;
            }
            var unallocatedAdditionalBorrowingForDebtConsolidationAmount = this.r6399_CalculateDebtConAdditionalBorrowingUnallocated(additionalBorrowing, selectedProducts);

            return unallocatedAdditionalBorrowingForDebtConsolidationAmount > 0
        },

        /* FMA - Property Address */

        r5321IsSecurityAddressLikelyToBeKnown: function (applicationType, propertyOwnership, propertyPurpose) {
            return ((applicationType === enums.RDApplicationTypeCode.remortgage && propertyPurpose !== enums.RDPropertyPurposeTypeCode.buyToLet) ||
            (applicationType === enums.RDApplicationTypeCode.newPurchase && propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy))
        },

        r5308IsPropertyTypeKnown: function (propertyType) {
            return (propertyType != undefined)
        },

        r5346IsPropertyTypeUnknown: function (propertyType) {
            return (!propertyType)
        },

        r5281IsRightToBuy: function (propertyOwnership) {
            return (propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy)
        },

        r5037IsSharedOwnership: function (propertyOwnership) {
            return (propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership)
        },

        r5304DoesPropertyRequireThirdPartyDetails: function (propertyOwnership, equityShareScheme, mortgageGuaranteeScheme) {
            return (propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy) || (propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership)
                || this.r5319AreBuildersDetailsNeeded(propertyOwnership, equityShareScheme, mortgageGuaranteeScheme);
        },

        r5319AreBuildersDetailsNeeded: function (propertyOwnership, equityShareScheme, mortgageShareScheme) {
            return (
                        (propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare) &&
                        (   equityShareScheme == enums.RDEquityShareTypeCode.helpToBuy ||
                            equityShareScheme == enums.RDEquityShareTypeCode.helpToBuyScotland ||
                            equityShareScheme == enums.RDEquityShareTypeCode.helpToBuyWales ||
                            equityShareScheme == enums.RDEquityShareTypeCode.helpToBuyLondon)) ||
                        (propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee &&
                        (   mortgageShareScheme === enums.RDMortgageGuaranteeTypeCode.mINewHome ||
                            mortgageShareScheme === enums.RDMortgageGuaranteeTypeCode.newbuy));
        },

        r5034IsEquityShare: function (propertyOwnership, equityShareScheme) {
            return (propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare)
                && (equityShareScheme === enums.RDEquityShareTypeCode.helpToBuy || equityShareScheme === enums.RDEquityShareTypeCode.helpToBuyScotland
                || equityShareScheme === enums.RDEquityShareTypeCode.helpToBuyWales)
        },
        r5035MinimumFullMarketValue: function (applicationType, purchasePrice, estimatedValue, propertyOwnershipType, minPurchasePrice,
                                               parameters, maxFullMarketValue) {
            propertyOwnershipType = parseInt(propertyOwnershipType);
            purchasePrice = parseInt(purchasePrice) || 0;
            minPurchasePrice = parseInt(minPurchasePrice);
            maxFullMarketValue = parseInt(maxFullMarketValue);
            estimatedValue = parseInt(estimatedValue) || 0;
            if (parseInt(applicationType) === enums.RDApplicationTypeCode.newPurchase) {
                switch (propertyOwnershipType) {
                    case enums.RDPropertyOwnershipTypeCode.rightToBuy:
                        return newPurchaseMinimumFullMarketValueBasedOnAPercentage(parseInt(parameters.p6351));
                    case enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember:
                        return newPurchaseMinimumFullMarketValueBasedOnAnAmount(parseInt(parameters.p6063));
                    case enums.RDPropertyOwnershipTypeCode.sharedOwnership:
                        return newPurchaseMinimumFullMarketValueBasedOnAnAmount(parseInt(parameters.p6064));
                    case enums.RDPropertyOwnershipTypeCode.equityShare:
                        return purchasePrice;
                    default:
                        return purchasePrice;
                }
            }
            else {
                return parseInt(estimatedValue);
            }

            function newPurchaseMinimumFullMarketValueBasedOnAnAmount(adjustmentValue) {
                if (purchasePrice < minPurchasePrice)
                    return minPurchasePrice + adjustmentValue;
                //if (purchasePrice >= maxFullMarketValue)
                //    return maxFullMarketValue;
                return purchasePrice + adjustmentValue;
            }

            function newPurchaseMinimumFullMarketValueBasedOnAPercentage(adjustmentValue) {

                var priceUsed = purchasePrice;

                if (purchasePrice < minPurchasePrice) {
                    priceUsed =  minPurchasePrice;
                }

                adjustmentValue = parseInt(adjustmentValue);
                var adjustedAmount = Math.ceil(priceUsed * (100 / adjustmentValue));
                return adjustedAmount;
            }
        },

        r5389MinPurchasePrice: function (propertyPurpose, propertyOwnershipType, equityShareSchemeType, parameters, fullMarketValue) {
            if (parseInt(propertyPurpose) === enums.RDPropertyPurposeTypeCode.buyToLet) {
                return parameters.p6001;
            }

            if (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.equityShare) {
                if (!fullMarketValue)
                    return parameters.p2238;
                switch (equityShareSchemeType) {
                    case enums.RDEquityShareTypeCode.buildersEquityShare:
                        return equityShareMinPurchasePrice(parameters.p6066);
                    case enums.RDEquityShareTypeCode.helpToBuy:
                        return equityShareMinPurchasePrice(parameters.p6068);
                    case enums.RDEquityShareTypeCode.helpToBuyScotland:
                        return equityShareMinPurchasePrice(parameters.p6070);
                    case enums.RDEquityShareTypeCode.helpToBuyWales:
                        return equityShareMinPurchasePrice(parameters.p6072);
                    case enums.RDEquityShareTypeCode.homebuyDirect:
                        return equityShareMinPurchasePrice(parameters.p6074);
                    case enums.RDEquityShareTypeCode.houseAssociationOrLocalAuthority:
                        return equityShareMinPurchasePrice(parameters.p6078);
                    case enums.RDEquityShareTypeCode.northernIrelandCoownershipHousingAssociation:
                        return equityShareMinPurchasePrice(parameters.p6076);
                    default:
                        return parameters.p2238;
                }
            }

            return parameters.p2238;

            function equityShareMinPurchasePrice(adjustmentParameter) {
                adjustmentParameter = parseInt(adjustmentParameter);
                return Math.ceil(parseInt(fullMarketValue) * (adjustmentParameter / 100));
            }
        },

          r5420MaximumFullMarketValue: function (propertyOwnership, equityShareSchemeType, parameters) {
            switch (propertyOwnership) {
                case enums.RDPropertyOwnershipTypeCode.equityShare:
                    return parseInt(this.r5416MaxEquityShareFullMarketValue(equityShareSchemeType, parameters));
                default:
                    return parameters.p2243;
            }
        },

        /* FMA - Employment Details */

        r5206EmployerDetailsNeeded: function (applicant) {
            var hasMainEmploymentRecord = false;
            if (applicant.currentIncomes && applicant.currentIncomes.length > 0) {
                angular.forEach(applicant.currentIncomes, function (value) {
                    if ((value.incomeType === enums.RDIncomeTypeCode.employmentIncome)
                        && ((value.employmentStatus === enums.RDEmploymentIncomeType.directorOrShareholderLessThanOrEqualTwentyPercentShare) || (value.employmentStatus === enums.RDEmploymentIncomeType.employed)
                        || (value.employmentStatus === enums.RDEmploymentIncomeType.selfEmployedPartner) || (value.employmentStatus === enums.RDEmploymentIncomeType.selfEmployedSoleTrader))
                        && (value.mainEmployment))
                        hasMainEmploymentRecord = true;
                });
            }
            return hasMainEmploymentRecord;
        },

        r5207AccountantDetailsNeeded: function (applicant) {
            var hasMainEmploymentRecord = false;
            if (applicant.currentIncomes && applicant.currentIncomes.length > 0) {
                angular.forEach(applicant.currentIncomes, function (value) {
                    if ((value.incomeType === enums.RDIncomeTypeCode.employmentIncome)
                        && ((value.employmentStatus === enums.RDEmploymentIncomeType.selfEmployedPartner) || (value.employmentStatus === enums.RDEmploymentIncomeType.selfEmployedSoleTrader)
                        || (value.employmentStatus === enums.RDEmploymentIncomeType.directorOrShareholderGreaterThanTwentyPercentShare))
                        && (value.mainEmployment)
                        || (value.employedForTaxPurposes))
                        hasMainEmploymentRecord = true;
                });
            }
            return hasMainEmploymentRecord;
        },

        r5302EmploymentDetailsNeeded: function (applicant) {
            return (this.r5206EmployerDetailsNeeded(applicant) || this.r5207AccountantDetailsNeeded(applicant));
        },

        r5301ValidateBankAccountError: function (result) {
            return (result != 0);
        },

        r5417BankServiceIntegrationAvailable: function (p6079) {
            return p6079 === 'True';
        },

        r5388IsBTL: function (propertyPurpose) {
            return parseInt(propertyPurpose) === enums.RDPropertyPurposeTypeCode.buyToLet;
        },
        r5920ConsumerBTLIsNonRegulated: function (applicationType, intendedFamilyOccupancy, previousFamilyOccupancy, familyOccupancy, ownOtherLetProperties) {
            return familyOccupancy === false || previousFamilyOccupancy === false || ownOtherLetProperties === true ||
                    (intendedFamilyOccupancy === false && this.r7004IsNewPurchase(applicationType))
        },

        /* FMA */

        r3025LeaseTermTooShort: function (leaseRemainingYears, p0049) {
            return leaseRemainingYears < p0049;
        },

        r3026LeaseTermAtEndTooShort: function (newLeaseYears, p0050) {
            return newLeaseYears < p0050;
        },

        r6395IsUnencumberedRemortgage: function (applicationType, currentMortgageRedemptionAmount) {
            return (currentMortgageRedemptionAmount == null || currentMortgageRedemptionAmount == 0 || angular.isUndefined(currentMortgageRedemptionAmount)) && applicationType === enums.RDApplicationTypeCode.remortgage;
        },

        r2028DetermineEligibilityForAvm: function (caseData, parameters) {
            if (caseData.loanRequirement.hasPropertyChanged === false && angular.isDefined(caseData.valuation) && angular.isDefined(caseData.valuation.valuationType) && caseData.valuation.valuationType !== null && caseData.valuation.valuationType !== enums.RDValuationType.aVM) {
                return false;
            }
            if (caseData.loanRequirement.hasPropertyChanged === false && angular.isDefined(caseData.valuationType) && caseData.valuationType !== enums.RDValuationType.aVM) {
                return false;
            }
            if (parameters.p6051.toLocaleLowerCase() === 'false') {
                return false;
            }
            if (!caseData.loanRequirement || !caseData.loanRequirement.applicationType ||
                caseData.loanRequirement.applicationType !== enums.RDApplicationTypeCode.remortgage) {
                return false;
            }
            if (!caseData.loanRequirement.propertyPurpose ||
                caseData.loanRequirement.propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet) {
                return false;
            }

            if (!caseData.property || !caseData.property.propertyDetails) {
                return false;
            }
            if (!caseData.property.tenure ||
                caseData.property.tenure === enums.RDPropertyTenureCode.commonhold) {
                return false;
            }

            var AVMMinimumLeaseholdYearsRemaining = parseInt(parameters.p6393);

            if (caseData.property.tenure === enums.RDPropertyTenureCode.leasehold &&
                parseInt(caseData.property.leaseRemainingYears) < AVMMinimumLeaseholdYearsRemaining) {
               return false;
            }

            var propertyDetails = caseData.property.propertyDetails;

            if (!propertyDetails.propertyType)
                return false;

            var propertyFloors = parseInt(propertyDetails.propertyFloors);
            if (propertyFloors !== propertyFloors)
                return false;

            var AVMMaximumNumberOfBedrooms = parseInt(parameters.p6395);
            if (propertyDetails.propertyBedrooms > AVMMaximumNumberOfBedrooms) {
                return false;
            }

            var isUnencumberedRemortgage = rulesService.r6395IsUnencumberedRemortgage(caseData.loanRequirement.applicationType, caseData.loanRequirement.currentMortgageRedemptionAmount);

            if (isUnencumberedRemortgage && parameters.p6394.toLowerCase() === 'true') {
               return false;
            }

            if (propertyDetails.propertyType === enums.RDPropertyTypeCode.convertedFlatOrMaisonette ||
                propertyDetails.propertyType === enums.RDPropertyTypeCode.purposeBuiltFlatOrMaisonette ||
                propertyDetails.propertyType === enums.RDPropertyTypeCode.selfContainedFlatOrMaisonette ||
                propertyDetails.propertyType === enums.RDPropertyTypeCode.selfContainedStudioFlat) {
                if (propertyFloors > parseInt(parameters.p6273))
                    return false;
                // not a flat and less than parameter limit
            } else if (propertyFloors > parseInt(parameters.p2171)){
                return false;
            }
            if (!caseData.loanRequirement.propertyOwnership ||
                caseData.loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership) {
                return false;
            }
            if (!propertyDetails.yearPropertyBuilt)
                return false;

            var yearPropertyBuilt = parseInt(propertyDetails.yearPropertyBuilt);
            var minPropertyBuildYear = moment().subtract(parseInt(parameters.p6274), 'years').year();

            if (yearPropertyBuilt < parseInt(parameters.p6007) ||
                yearPropertyBuilt > minPropertyBuildYear) {
                return false;
            }
            if (parameters.p6235.toLocaleLowerCase() === 'true' &&
                !propertyDetails.propertyHabitable) {
                return false;
            }
            if (parameters.p6345.toLocaleLowerCase() === 'true' &&
                !propertyDetails.wallsStandardConstruction) {
                return false;
            }
            if (parameters.p6346.toLocaleLowerCase() === 'true' &&
                !propertyDetails.roofStandardConstruction) {
                return false;
            }
            if (parameters.p6344.toLocaleLowerCase() === 'true' &&
                (angular.isUndefined(propertyDetails.largePlot) ||
                propertyDetails.largePlot === true)) {
                return false;
            }
            if (parameters.p6342.toLocaleLowerCase() === 'true' &&
                (angular.isUndefined(propertyDetails.underConstruction) ||
                propertyDetails.underConstruction === true)) {
                return false;
            }
            if (parameters.p6244.toLocaleLowerCase() === 'true' &&
                (angular.isUndefined(propertyDetails.listedBuilding) ||
                propertyDetails.listedBuilding === true)) {
                return false;
            }
            if (parameters.p6247.toLocaleLowerCase() === 'true' &&
                (angular.isUndefined(propertyDetails.subsidenceHeaveLandslip) ||
                propertyDetails.subsidenceHeaveLandslip === true)) {
                return false;
            }
            if (parameters.p6243.toLocaleLowerCase() === 'true' &&
                (angular.isUndefined(propertyDetails.coastalRiverErosion) ||
                propertyDetails.coastalRiverErosion === true)) {
                return false;
            }
            if (parameters.p6246.toLocaleLowerCase() === 'true' &&
                (angular.isUndefined(propertyDetails.flooded) ||
                propertyDetails.flooded === true)) {
                return false;
            }
            if (parameters.p6347.toLocaleLowerCase() === 'true' &&
                (angular.isUndefined(propertyDetails.structuralAlteration) ||
                propertyDetails.structuralAlteration === true)) {
                return false;
            }

            var currentEstimatedValue = parseInt(caseData.loanRequirement.currentEstimatedValue);
            if (currentEstimatedValue > parseInt(parameters.p6275) ||
                currentEstimatedValue < parseInt(parameters.p6277))
                return false;

            var loanRequirement = caseData.loanRequirement;
            var depositBuildersCashBack = getDepositAmount(enums.RDMortgageDepositSourceCode.builderCashback,
                loanRequirement.depositDetails);
            var depositVendorsCashBack = getDepositAmount(enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive,
                loanRequirement.depositDetails);
            var ltv = this.r0041CalculateSalesLTV(loanRequirement.applicationType, loanRequirement.propertyOwnership,
                loanRequirement.totalLoanAmount, currentEstimatedValue, loanRequirement.purchasePrice,
                loanRequirement.fullMarketValue,
                depositBuildersCashBack, depositVendorsCashBack, parameters.p3115);

            if (ltv > parseFloat(parameters.p6276))
                return false;

            return true;
        },

        r0012TranscriptionDateSuitable: function (transcriptionReportDate, p0075) {
            return moment(transcriptionReportDate).startOf('day').diff(moment().startOf('day').subtract('days', p0075)) >= 0;
        },

        /* LCM */
        customerGTEMinAgeAtApplication: function (dateOfBirth, p0038) {
            var futureDate = moment(dateOfBirth).add('years', p0038);
            var days = futureDate.diff(moment(), 'days');
            return days <= 0;
        },

        customerLTMaxAgeAtApplication: function (dateOfBirth, p0039) {
            var futureDate = moment(dateOfBirth).add('years', p0039);
            var days = futureDate.diff(moment(), 'days');
            return days > 0;
        },

        calculateStateRetirementDate: function (birthday, stateRetirementAge) {
            if (birthday !== undefined && birthday !== null) {
                return moment(birthday).add(stateRetirementAge, 'years').format("MMMM YYYY");
            }
            return null;
        },

        r7004IsNewPurchase: function (applicationType) {
            return parseInt(applicationType) === enums.RDApplicationTypeCode.newPurchase;
        },
        r7007IsRemortgage: function (applicationType) {
            return parseInt(applicationType) === enums.RDApplicationTypeCode.remortgage;
        },
        r7008IsRemortgageRightToBuy: function (applicationType, propertyOwnershipType) {
            return this.r7007IsRemortgage(applicationType) &&
                (parseInt(propertyOwnershipType) === enums.RDPropertyOwnershipTypeCode.rightToBuy);
        },
        r7009IsEquityShare: function (propertyOwnershipType) {
            return (parseInt(propertyOwnershipType) === enums.RDPropertyOwnershipTypeCode.equityShare);
        },
        r7011IsNewPurchaseRightToBuy: function (applicationType, propertyOwnershipType) {
            return this.r7004IsNewPurchase(applicationType) &&
                (parseInt(propertyOwnershipType) === enums.RDPropertyOwnershipTypeCode.rightToBuy);
        },
        r7013IsSharedOwnership: function (propertyOwnershipType) {
            return (parseInt(propertyOwnershipType) === enums.RDPropertyOwnershipTypeCode.sharedOwnership);
        },
        r7016IsMortgageGuarantee: function (propertyOwnershipType) {
            return (parseInt(propertyOwnershipType) === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee);
        },
        r5026IsFamilyPurchase: function (propertyOwnershipType) {
            return (parseInt(propertyOwnershipType) === enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember);
        },
        r5100IsNewPurchaseRightToBuyFamilyPurchase: function (applicationType, propertyOwnershipType) {
            var at = parseInt(applicationType),
                pot = parseInt(propertyOwnershipType);
            return (at === enums.RDApplicationTypeCode.newPurchase && (pot === enums.RDPropertyOwnershipTypeCode.rightToBuy || pot === enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember));
        },

        calculateAge: function (birthDate) {
            var age = 0;
            if (birthDate) {
                var moDate = moment(birthDate);

                if (moDate.isValid() && !(moment().isBefore(moDate))) {
                    age = parseInt((new moment()).diff(moDate, 'years', true));
                }
            }
            return age;
        },

        r5012DefaultGender: function (title) {
            switch (parseInt(title)) {
                case enums.RDIndividualTitleCode.mr:
                case enums.RDIndividualTitleCode.lord:
                case enums.RDIndividualTitleCode.sir:
                    return enums.RDGenderTypeCode.male;
                case enums.RDIndividualTitleCode.mrs:
                case enums.RDIndividualTitleCode.miss:
                case enums.RDIndividualTitleCode.ms:
                case enums.RDIndividualTitleCode.dame:
                case enums.RDIndividualTitleCode.lady:
                    return enums.RDGenderTypeCode.female;
            }
        },

        //noinspection FunctionWithInconsistentReturnsJS
        r0003IsMortgageGuaranteeAndPurchasePriceExceedsMaximum: function (propertyOwnershipTypeCode, mortgageGuaranteeScheme, purchasePrice, parameters) {
            if (!parameters.p0035 || !parameters.p0036 || !parameters.p0037) {
                throw 'Parameters p0035, p0036, p0037 are mandatory';
            }

            var potc = parseInt(propertyOwnershipTypeCode),
                mgs = parseInt(mortgageGuaranteeScheme),
                pp = parseInt(purchasePrice);


            if (potc !== enums.RDPropertyOwnershipTypeCode.mortgageGuarantee) {
                return false;
            }

            if (mgs === enums.RDMortgageGuaranteeTypeCode.helpToBuy) {
                return pp > parseInt(parameters.p0035);
            }

            if (mgs === enums.RDMortgageGuaranteeTypeCode.mINewHome) {
                return pp > parseInt(parameters.p0036);
            }

            if (mgs === enums.RDMortgageGuaranteeTypeCode.newbuy) {
                return pp > parseInt(parameters.p0037);
            }

            return false;
        },

        /**
         * Calculate if any additional borrowing is going on a RM case
         * @returns {number} that indicates the amount additional being borrowed
         * @param totalLoanAmount
         * @param applicationType
         * @param currentMortgageRedemptionAmount
         */
        r3006RemortgageAdditionalBorrowingCalculation: function (totalLoanAmount, applicationType, currentMortgageRedemptionAmount) {

            if ((totalLoanAmount === undefined) ||
                (applicationType === undefined)) return 0;

            applicationType = parseInt(applicationType);
            totalLoanAmount = parseInt(totalLoanAmount);
            currentMortgageRedemptionAmount = parseInt(currentMortgageRedemptionAmount) || 0;

            if ((isNaN(totalLoanAmount) || isNaN(currentMortgageRedemptionAmount)) || isNaN(applicationType)) throw "Either Application Type, Total Loan Amount or Redemption Amount could not be evaluated to a number";

            if (applicationType != enums.RDApplicationTypeCode.remortgage) return 0;

            var result = (totalLoanAmount - currentMortgageRedemptionAmount);

            return (result > 0 ? result : 0);
        },

        /**
         * Calculates the end date of the mortgage based on the caseData.loanRequirement.mortgageTermMonths
         * @param caseData
         * @returns {*} a date that represents the date the mortgage ends
         */
        r3016CalculateMortgageTermEndCaseData: function (caseData) {
            if ((!caseData) || (!caseData.loanRequirement) || (!caseData.loanRequirement.mortgageTermMonths)) throw new Error('loanRequirement.mortgageTermMonths should have been supplied.');

            var termInMonths = parseInt(caseData.loanRequirement.mortgageTermMonths);

            if (isNaN(termInMonths)) throw new Error("loanRequirement.mortgageTermMonths is incorrectly formatted.");

            if (termInMonths <= 0) throw new Error("loanRequirement.mortgageTermMonths must greater than 0.");

            // rule states that the end of term is calculated from the 1st of the next month - 1 day.
            var firstDay = moment().add('M', 1).startOf('M');
            return moment(firstDay).add('M', termInMonths).subtract('d', 1);
        },

        /**
         * Calculate the date of the specified applicant retirement based on their birthday and planned retirement age.
         * @param caseData containing applicant[x].dob and applicant[x].retirementAge
         * @param applicantIndex index of teh applicant
         * @returns {*} the date of retirement
         */
        r3015CalculateApplicantRetirementDateCaseData: function (caseData, applicantIndex) {

            if ((!caseData) || (!caseData.applicants) || (!caseData.applicants[applicantIndex]) ||
                (!caseData.applicants[applicantIndex].retirementAge) || (!caseData.applicants[applicantIndex].dob))throw new Error("No applicants details has been supplied.");

            var plannedRetirementAge = parseInt(caseData.applicants[applicantIndex].retirementAge);
            var birthDate = new moment(caseData.applicants[applicantIndex].dob, 'L');

            if (!birthDate.isValid()) throw new Error("Date of birth was not in a recognised format.");

            var retirementDate = birthDate.add("y", plannedRetirementAge);

            return retirementDate;
        },

        r5102IsRemortgageOrHousePurchasePropertyFound: function (applicationType, locatedProperty) {
            var at = parseInt(applicationType),
                    lp = (locatedProperty != undefined && (locatedProperty === 1 || locatedProperty === '1' || locatedProperty != "0"));

            return (at === enums.RDApplicationTypeCode.remortgage) ||
                (at === enums.RDApplicationTypeCode.newPurchase && lp);
        },
        r5071IsRemortgageOrApplicantLocatedPropertyTheyWishToBuy: function (applicationType, locatedProperty) {
            var at = parseInt(applicationType),
                lp = (locatedProperty != undefined && (locatedProperty === 1 || locatedProperty === '1' || locatedProperty != "0"));

            return (at === enums.RDApplicationTypeCode.remortgage || lp);
        },

        /**
         * returns a flag that indicates if consent to credit check has been made.
         * @param caseData
         * @returns {boolean} true if consent has been given on this case
         */
        r5038HasFootprintConsentBeenGiven: function (caseData) {
            var consentGiven = true;

            if (!caseData
                || !caseData.applicants
                || caseData.applicants.length == 0) {
                return false;
            }

            var applicants = caseData.applicants || [];
            angular.forEach(applicants, function (applicant) {
                if (!applicant.consentGiven) {
                    consentGiven = false;
                }
            });


            return consentGiven;
        },
        r5015IsADepositNeeded: function (caseData) {
            if (!caseData || !caseData.loanRequirement) {
                return false;
            }

            var applicationType = parseInt(caseData.loanRequirement.applicationType),
                totalLoanAmount = parseInt(caseData.loanRequirement.totalLoanAmount),
                purchasePrice = parseInt(caseData.loanRequirement.purchasePrice);

            return (applicationType === enums.RDApplicationTypeCode.newPurchase && (totalLoanAmount < purchasePrice));
        },

        r5277FilterPhysicalValuationOptions: function (rdValuationType, applicationType, propertyLocation, propertyPurpose, productsHaveFreeVal,
                                                       p6010, p6011, isEIRApplicable) {

            var filteredValuationTypes = angular.copy(rdValuationType);

            if (propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet) {
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.mortgageValuationReport,
                    enums.RDValuationType.mortgageValuationReportWithBuildingSurvey,
                    enums.RDValuationType.mortgageValuationReportWithHomebuyersReport,
					enums.RDValuationType.upgradeToHomebuyersReport,
					enums.RDValuationType.upgradeToBuildingSurvey
                    ]);
            }

            if ((applicationType !== enums.RDApplicationTypeCode.newPurchase ||
                propertyLocation !== enums.RDUKCountryCode.scotland ||
                this.r5388IsBTL(propertyPurpose))) {
                removeItemFromValueset(filteredValuationTypes,
                    enums.RDValuationType.transcriptionOfScottishHomeReport);
            }

            if ((p6010 !== "True")) { //pHomeBuyersReportSupported
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.mortgageValuationReportWithHomebuyersReport,
                    enums.RDValuationType.upgradeToHomebuyersReport,
                    enums.RDValuationType.buyToLetHomebuyersReport,
                    enums.RDValuationType.upgradeToBuyToLetHomebuyersReport]);
            }

            if ((p6011 !== "True")) { //pBuildingSurveySupported
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.mortgageValuationReportWithBuildingSurvey,
                    enums.RDValuationType.upgradeToBuildingSurvey,
                    enums.RDValuationType.buyToLetBuildingsSurvey,
                    enums.RDValuationType.upgradeToBuyToLetBuildingsSurvey]);
            }

            if (productsHaveFreeVal) {
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.mortgageValuationReport,
                    enums.RDValuationType.mortgageValuationReportWithHomebuyersReport,
                    enums.RDValuationType.mortgageValuationReportWithBuildingSurvey,
                    enums.RDValuationType.buyToLetValuation,
                    enums.RDValuationType.buyToLetHomebuyersReport,
                    enums.RDValuationType.buyToLetBuildingsSurvey,
                    enums.RDValuationType.externalValuationReport
                ]);

            }
            else {
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.upgradeToBuyToLetHomebuyersReport,
                    enums.RDValuationType.upgradeToBuyToLetBuildingsSurvey,
                    enums.RDValuationType.upgradeToHomebuyersReport,
                    enums.RDValuationType.upgradeToBuildingSurvey,
                    enums.RDValuationType.noUpgradeRequired,
                    enums.RDValuationType.noUpgradeRequiredFromEIR
                ]);
            }

            if (propertyPurpose === enums.RDPropertyPurposeTypeCode.ownerOccupation || propertyPurpose === enums.RDPropertyPurposeTypeCode.secondProperty) {
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.buyToLetHomebuyersReport,
                    enums.RDValuationType.buyToLetBuildingsSurvey,
                    enums.RDValuationType.upgradeToBuyToLetHomebuyersReport,
                    enums.RDValuationType.upgradeToBuyToLetBuildingsSurvey,
                    enums.RDValuationType.buyToLetValuation]);
            }

            if(isEIRApplicable) {
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.mortgageValuationReport,
                    enums.RDValuationType.buyToLetValuation,
                    enums.RDValuationType.noUpgradeRequired
                ]);

            } else {
                removeItemsFromValueset(filteredValuationTypes, [
                    enums.RDValuationType.externalValuationReport,
                    enums.RDValuationType.noUpgradeRequiredFromEIR
                ]);
            }

            removeItemsFromValueset(filteredValuationTypes, [
                enums.RDValuationType.postOfferReinspection,
                enums.RDValuationType.preOfferReinspection,
                enums.RDValuationType.reinspection,
                enums.RDValuationType.aVM,
                enums.RDValuationType.initialPhysicalValuationTypes]);

            return filteredValuationTypes;
        },

        /*FMA*/

        r5315IsPropertyFlatOrMaisonette: function (propertyType) {
            var pt = parseInt(propertyType);
            return (pt === enums.RDPropertyTypeCode.convertedFlatOrMaisonette ||
            pt === enums.RDPropertyTypeCode.purposeBuiltFlatOrMaisonette ||
            pt === enums.RDPropertyTypeCode.selfContainedFlatOrMaisonette ||
            pt === enums.RDPropertyTypeCode.selfContainedStudioFlat);
        },

        r5338OtherOccupantsOver17: function (caseData) {
            if (caseData.property && caseData.property.propertyDetails && caseData.property.propertyDetails.residentsOver17) {
                if (caseData.property.propertyDetails.residentsOver17 === true) {
                    return true
                }
            }
            return false;
        },

        r5368IsSufficientInformationToCheckForAVM: function (completenessCheckResults) {
            //loop through the array and identify whether the Property Address and details have passed completeness check.
            var propertyAddressCompleted = true;
            var propertyDetailsCompleted = true;
            if (completenessCheckResults) {
                completenessCheckResults.forEach(function (result) {
                    if (result.key === 'propertyAddress' || result.key === 'propertyDetails') {
                        if (result.validationResults.length > 0) {
                            if (result.key === 'propertyAddress') {
                                propertyAddressCompleted = false;
                            }
                            else {
                                propertyDetailsCompleted = false;
                            }
                        }
                    }
                })
            }
            return (propertyAddressCompleted && propertyDetailsCompleted);
        },

        r5402IsNotBtl: function (caseData) {
            if ((!caseData) || (!caseData.loanRequirement) || (!caseData.loanRequirement.propertyPurpose)) {
                return false;
            }
            return caseData.loanRequirement.propertyPurpose != enums.RDPropertyPurposeTypeCode.buyToLet;
        },

        r5601EnableAutoPayments: function (refData, caseStatus) {
            if(caseStatus !== null && caseStatus!== undefined && (parseInt(caseStatus) === enums.RDCaseStatus.cancelled
            || parseInt(caseStatus) === enums.RDCaseStatus.declined)){
                return false;
            }
            if (refData && refData.parameters && refData.parameters.p6100 !== null && refData.parameters.p6100 !== undefined) {
                return (refData.parameters.p6100.toLowerCase() === 'true');
            }
            return true;
        },

        r5777OutstandingFeesToPay: function (selectedProducts, feePaymentOptions) {
            if (angular.isUndefined(feePaymentOptions)) {
                return undefined;
            }

            var upfrontFees = [];

            angular.forEach(selectedProducts,
                function(product) {
                    var filteredFees = (product.fees.filter(function(fee) {
                        return fee.payableWhen === enums.RDFeePayableWhen.onApplication && !fee.isEstimated &&
                            feePaymentOptions.filter(function(option) {
                                return option.feeId === fee.id &&
                                    option.chosenPaymentOption === enums.RDFeePayableHow.creditDebitCard &&
                                    option.parentProductId === product.id;
                            }).length > 0;
                    }));

                    upfrontFees.push.apply(upfrontFees, filteredFees);
                });

            var feeTotalToPay = 0;

            angular.forEach(upfrontFees, function (fee) {
                if ((angular.isDefined(fee.feeStatus) && fee.feeStatus != enums.RDFeeStatus.unpaid) ||
                    fee.paymentTransactionReference !== null && fee.paymentTransactionReference !== undefined && fee.paymentTransactionReference.length > 0) {
                    // Fee has been paid
                } else {
                    feeTotalToPay += fee.amount;
                }

            });

            return feeTotalToPay > 0;
        },

        r5838IntermediaryUserOlderMinAge: function (birthDate, p6148) {
            if (birthDate !== undefined && birthDate !== null) {

                // p6148 is the minimum intermediary age.
                // Add this to the date of birth and find the difference between that date and now.
                var difference = moment(birthDate).add(parseInt(p6148), 'years').diff(moment());

                // If difference is negative, the resulting date is less than now, so the intermediary
                // is old enough.
                return (difference < 0);
            }
            return false;
        },

		r5842MaxPurchasePrice: function (propertyPurpose, propertyOwnershipType, parameters, minPurchasePrice, fullMarketValue) {
			propertyOwnershipType = parseInt(propertyOwnershipType);

			if (parseInt(propertyPurpose) === enums.RDPropertyPurposeTypeCode.buyToLet) {
				return parameters.p6050;
			}

			switch (propertyOwnershipType) {
				case enums.RDPropertyOwnershipTypeCode.rightToBuy:
					return adjustMaxPurchasePriceBasedOnAnAmount(parseInt(parameters.p6024));
				case enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember:
					return adjustMaxPurchasePriceBasedOnAnAmount(parseInt(parameters.p6063));
				case enums.RDPropertyOwnershipTypeCode.sharedOwnership:
					return adjustMaxPurchasePriceBasedOnAnAmount(parseInt(parameters.p6064));
				default:
					return parameters.p2239;
			}

			function adjustMaxPurchasePriceBasedOnAnAmount(FMVAdjustmentParameter) {
				var maxPurchasePrice = Math.min(parseInt(parameters.p2239) - FMVAdjustmentParameter, (fullMarketValue || 0) - FMVAdjustmentParameter);
				if (maxPurchasePrice <= minPurchasePrice) {
					return minPurchasePrice;
				} else {
					return maxPurchasePrice;
				}
			}
		},

		r5843IsEquityShareAndPurchasePriceExceedsMaximum: function (propertyOwnership, equityShareScheme, purchasePrice, fullMarketValue, parameters) {

            if (propertyOwnership !== enums.RDPropertyOwnershipTypeCode.equityShare || equityShareScheme === undefined) {
				return false;
			}

			if (!parameters.p6067 || !parameters.p6069 || !parameters.p6071 || !parameters.p6073 || !parameters.p6075 || !parameters.p6065 || !parameters.p6077 || !parameters.p2239) {
				throw 'Parameters p6067, p6069, p6071, p6073, p6075, p6065, p6077, p2239 are mandatory';
			}

			var result =  (equityShareScheme == enums.RDEquityShareTypeCode.helpToBuy &&
				    purchasePrice > (fullMarketValue * (parseInt(parameters.p6067) / 100))) ||
				(equityShareScheme == enums.RDEquityShareTypeCode.helpToBuyScotland &&
			    	purchasePrice > (fullMarketValue * (parseInt(parameters.p6069) / 100))) ||
				(equityShareScheme == enums.RDEquityShareTypeCode.helpToBuyWales &&
			    	purchasePrice > (fullMarketValue * (parseInt(parameters.p6071) / 100))) ||
				(equityShareScheme == enums.RDEquityShareTypeCode.homebuyDirect &&
			    	purchasePrice > (fullMarketValue * (parseInt(parameters.p6073) / 100))) ||
				(equityShareScheme == enums.RDEquityShareTypeCode.northernIrelandCoownershipHousingAssociation &&
				    purchasePrice > (fullMarketValue * (parseInt(parameters.p6075) / 100))) ||
				(equityShareScheme == enums.RDEquityShareTypeCode.buildersEquityShare &&
				    purchasePrice > (fullMarketValue * (parseInt(parameters.p6065) / 100))) ||
				(equityShareScheme == enums.RDEquityShareTypeCode.houseAssociationOrLocalAuthority &&
			    	purchasePrice > (fullMarketValue * (parseInt(parameters.p6077) / 100))) ||
				purchasePrice > (parseInt(parameters.p2239));

			return result;
		},

        r5847IntermediaryUserYoungerMaxAge: function (birthDate, p6151) {
            if (birthDate !== undefined && birthDate !== null) {

                // p6151 is the maximum intermediary age.
                // Add this to the date of birth and find the difference between that date and now.
                var difference = moment(birthDate).add(parseInt(p6151), 'years').diff(moment());

                // If difference is positive, the resulting date is more than now, so the intermediary
                // is young enough.
                return (difference > 0);
            }
            return false;
        },

        // Implementation of F0013 function using P6208 - pUseAddToLoanFeesInLTVCalculation
        // Returns the add to loan fees applicable for use in Ltv calc
        f0013GetAddToLoanFeesForLtv: function(fees, p6208UseAddToLoanFeesInLTVCalculation, loanRequirement) {
            if (p6208UseAddToLoanFeesInLTVCalculation === "True" ||
                (loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee &&
                loanRequirement.guaranteeShareScheme === enums.RDMortgageGuaranteeTypeCode.helpToBuy)) {
                return feesAddedToLoan(fees);
            }

            return 0;
        },

        // Implementation of F0013 function using P6212 - pUseAddToLoanFeesInAffordabilityCalculation
        // Returns the add to loan fees applicable for use in the max affordability check
        f0013GetAddToLoanFeesForMaxAffordability: function(fees, p6212UseAddToLoanFeesInAffordabilityCalculation, loanRequirement) {
            if (p6212UseAddToLoanFeesInAffordabilityCalculation === "True" ||
                (loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee &&
                loanRequirement.guaranteeShareScheme === enums.RDMortgageGuaranteeTypeCode.helpToBuy)) {
                return feesAddedToLoan(fees);
            }

            return 0;
        },

        r6011PreviouslyCapturedRepaymentStrategiesApplicable: function (p6217, interestOnlyAmount) {
            return p6217.toLowerCase() === 'true' && r5087AllOrPartInterestOnly(interestOnlyAmount);
        },

        r6081MaxInterestOnlyAmount: function (totalLoanAmount, purchasePrice, currentEstimatedValue, applicationType,
                                              p6266) {
            var propertyPriceMaxInterestOnlyAmount = this.r6080MaxInterestOnlyPurchasePriceCalculation(purchasePrice,
                currentEstimatedValue, applicationType, p6266),
                tla = parseInt(totalLoanAmount);

            return propertyPriceMaxInterestOnlyAmount < tla ?
                propertyPriceMaxInterestOnlyAmount :
                tla;
        },

        r6080MaxInterestOnlyPurchasePriceCalculation: function (purchasePrice, currentEstimatedValue, applicationType,
                                              p6266) {
            var propertyPrice;
            if (applicationType === enums.RDApplicationTypeCode.newPurchase)
                propertyPrice = purchasePrice;
            if (applicationType === enums.RDApplicationTypeCode.remortgage)
                propertyPrice = currentEstimatedValue;

            return Math.floor(parseInt(propertyPrice) * parseFloat(p6266));
        },

        r5392MinLoanAmount: function(parameters, isBuyToLet, overallApplicantType){
            if (isBuyToLet){
                return parseInt(parameters.p6000);
            } else {
                switch (overallApplicantType){
                    case enums.RDApplicantTypeCode.firstTimeBuyer:
                        return parseInt(parameters.p6278);
                    default:
                        return parseInt(parameters.p6279);
                }
            }
        },

        r5393MaxLoanAmount: function (parameters, isBuyToLet, overallApplicantType, propertyOwnership, purchasePrice,
                                     currentEstimatedValue, applicationType) {
            var maximumEquityShareLoanAmount = calculateMaximumEquityShareLoanAmount(),
                maximumLoanAmount;
            if (isBuyToLet) {
                maximumLoanAmount = parseInt(parameters.p6049);
            } else {
                switch (overallApplicantType) {
                    case enums.RDApplicantTypeCode.firstTimeBuyer:
                        maximumLoanAmount = parseInt(parameters.p6280);
                        break;
                    default:
                        maximumLoanAmount = parseInt(parameters.p6281);
                        break;
                }
            }

            if (maximumEquityShareLoanAmount !== null && maximumEquityShareLoanAmount < maximumLoanAmount)
                maximumLoanAmount = maximumEquityShareLoanAmount;

            return maximumLoanAmount;

            function calculateMaximumEquityShareLoanAmount() {
                if (propertyOwnership !== enums.RDPropertyOwnershipTypeCode.equityShare)
                    return null;

                var value;
                switch (applicationType) {
                    case enums.RDApplicationTypeCode.newPurchase:
                        if (isNullOfUndefined(purchasePrice) || parseInt(purchasePrice) === 0)
                            return null;
                        value = parseInt(purchasePrice);
                        break;
                    case enums.RDApplicationTypeCode.remortgage:
                        if (isNullOfUndefined(currentEstimatedValue) || parseInt(currentEstimatedValue) === 0)
                            return null;
                        value = parseInt(currentEstimatedValue);
                        break;
                    default:
                        return null;
                }

                var p6477MaxPercentageOfShare = parseInt(parameters.p6477);

                return Math.floor(value * (p6477MaxPercentageOfShare / 100));
            }
        },

        r5395MaxLoanTerm: function(parameters, propertyPurpose, totalLoanAmount) {

            var maxTermBandedByLoanAmount = parameters.p6222.toLowerCase() === "true";
            var btlTermBand1Value = parseInt(parameters.p6223);
            var btlMaxTermBand1 = parseInt(parameters.p6225);
            var btlTermBand2Value = parseInt(parameters.p6224);
            var btlMaxTermBand2 = parseInt(parameters.p6226);
            var btlMaxTermBand3 = parseInt(parameters.p6227);
            var maxBtlLoanTerm = parseInt(parameters.p6003);
            var maxLoanTerm = parseInt(parameters.p0053);
            var nonBtlTermBand1Value = parseInt(parameters.p6228);
            var nonBtlMaxTermBand1 = parseInt(parameters.p6230);
            var nonBtlTermBand2Value = parseInt(parameters.p6229);
            var nonBtlMaxTermBand2 = parseInt(parameters.p6231);
            var nonBtlMaxTermBand3 = parseInt(parameters.p6232);
            var maxTerm;

            if (propertyPurpose == enums.RDPropertyPurposeTypeCode.buyToLet) {
                if (maxTermBandedByLoanAmount) {
                    if (totalLoanAmount <= btlTermBand1Value) {
                        maxTerm = btlMaxTermBand1;
                    }
                    else if (totalLoanAmount > btlMaxTermBand1 && totalLoanAmount <= btlTermBand2Value) {
                        maxTerm = btlMaxTermBand2;
                    }
                    else {
                        maxTerm = btlMaxTermBand3;
                    }
                }
                else {
                    maxTerm = maxBtlLoanTerm;
                }
            } else {
                if (maxTermBandedByLoanAmount) {
                    if (totalLoanAmount <= nonBtlTermBand1Value) {
                        maxTerm = nonBtlMaxTermBand1;
                    } else if (totalLoanAmount > nonBtlMaxTermBand1 && totalLoanAmount <= nonBtlTermBand2Value) {
                        maxTerm = nonBtlMaxTermBand2;
                    }
                    else {
                        maxTerm = nonBtlMaxTermBand3;
                    }
                }
                else {
                    maxTerm = maxLoanTerm;
                }
            }
            return maxTerm;
        },

        r6104MaxNumberOfFloors: function (propertyType, parameters) {
            if (propertyType === enums.RDPropertyTypeCode.convertedFlatOrMaisonette ||
                propertyType === enums.RDPropertyTypeCode.purposeBuiltFlatOrMaisonette ||
                propertyType === enums.RDPropertyTypeCode.selfContainedFlatOrMaisonette ||
                propertyType === enums.RDPropertyTypeCode.selfContainedStudioFlat)
                return parseInt(parameters.p6291);

            return parseInt(parameters.p6016);
        },

        r6166ValuationsDataInGroupEditable : function(isDataReadonlyInPcv, initialValuation, isAvmEligible, isEirApplicable) {
            return !(isDataReadonlyInPcv && initialValuation.valuationType &&
            (
                (initialValuation.valuationType === enums.RDValuationType.aVM && isAvmEligible)
                || (initialValuation.valuationType === enums.RDValuationType.externalValuationReport && isEirApplicable)
                || (initialValuation.valuationType === enums.RDValuationType.noUpgradeRequiredFromEIR && isEirApplicable)
                || (initialValuation.valuationType !== enums.RDValuationType.aVM
                        && initialValuation.valuationType  !== enums.RDValuationType.externalValuationReport
                        && initialValuation.valuationType !== enums.RDValuationType.noUpgradeRequiredFromEIR
                    )
            ));
        },

        r5282PropertyAccessDetailsRequired: function(valuationType) {
            if (valuationType &&
                valuationType !== enums.RDValuationType.transcriptionOfScottishHomeReport &&
                valuationType !== enums.RDValuationType.externalValuationReport &&
                valuationType !== enums.RDValuationType.noUpgradeRequiredFromEIR) {
                return true;
            }
            return false;
        },

        r6194IsEIRApplicable: function (loanRequirement, propertyData, parameters) {

            if(!parameters.iP0001 || parameters.iP0001 === '2.6') {
                return false;
            }

            if (parameters.p6329.toLocaleLowerCase() !== 'true' || !loanRequirement || !propertyData || !propertyData.propertyDetails) {
                return false;
            }

            var propertyHasValidTenureForEIR =
                propertyData.tenure
                && (
                    propertyData.tenure !== enums.RDPropertyTenureCode.leasehold
                    || (propertyData.leaseRemainingYears && propertyData.leaseRemainingYears >= parameters.p6332)
                );

            var ltvIsEligibleForEIR = IsLtvEligibleForEIR(loanRequirement, parameters.p3115, parameters.p6324, parameters.p6326);

            if (loanRequirement.propertyOwnership != enums.RDPropertyOwnershipTypeCode.sharedOwnership
                && !(loanRequirement.propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet && parameters.p6331.toLocaleLowerCase() !== 'true')
                && (!isApplicable(parameters.p6247) || propertyData.propertyDetails.subsidenceHeaveLandslip === false)
                && (!isApplicable(parameters.p6243) || propertyData.propertyDetails.coastalRiverErosion === false)
                && (!isApplicable(parameters.p6246) || propertyData.propertyDetails.flooded === false)
                && ((loanRequirement.purchasePrice && loanRequirement.purchasePrice <= parameters.p6323)
                    || (loanRequirement.currentEstimatedValue && loanRequirement.currentEstimatedValue <= parameters.p6323))
                && (!isApplicable(parameters.p6347) || propertyData.propertyDetails.structuralAlteration === false)
                && (!isApplicable(parameters.p6235) || propertyData.propertyDetails.propertyHabitable)
                && (!isApplicable(parameters.p6345) || propertyData.propertyDetails.wallsStandardConstruction)
                && (!isApplicable(parameters.p6346) || propertyData.propertyDetails.roofStandardConstruction)
                && (!isApplicable(parameters.p6344) || propertyData.propertyDetails.largePlot === false)
                && (!isApplicable(parameters.p6342) || propertyData.propertyDetails.underConstruction === false)
                && (propertyData.propertyDetails.propertyFloors === 1 || propertyData.propertyDetails.propertyFloors === 2)
                && (!isApplicable(parameters.p6244) || propertyData.propertyDetails.listedBuilding === false)
                && (!rulesService.r5315IsPropertyFlatOrMaisonette(propertyData.propertyDetails.propertyType))
                && (propertyHasValidTenureForEIR)
                && (propertyData.propertyDetails.newBuildIndicator === false)
                && (ltvIsEligibleForEIR)
                && (parseInt(propertyData.propertyDetails.yearPropertyBuilt) >= parseInt(parameters.p6333))
                && (loanRequirement.totalLoanAmount <= parameters.p6334)
            ) {
                return true;
            }

            return false;

            function IsLtvEligibleForEIR(loanRequirement, p3115_MaxBuildersCashbackPercentage, p6324_MaxLTVEIRRemortgage, p6326_MaxLTVEIRNewPurchase)
            {
                if (!loanRequirement.applicationType) {
                    return false;
                }

                var ltvValue = GetLTVUsingR0041(loanRequirement, p3115_MaxBuildersCashbackPercentage);

                var ltvPercentage = (ltvValue * 100).toFixed(2);

                var remortgageLtvEligible = loanRequirement.applicationType === enums.RDApplicationTypeCode.remortgage &&
                    ltvPercentage <= parseFloat(p6324_MaxLTVEIRRemortgage * 100).toFixed(2);

                var newPurchaseLtvEligible = loanRequirement.applicationType === enums.RDApplicationTypeCode.newPurchase &&
                    ltvPercentage <= parseFloat(p6326_MaxLTVEIRNewPurchase * 100).toFixed(2);

                return remortgageLtvEligible || newPurchaseLtvEligible;
            }

            function GetLTVUsingR0041(loanRequirement, p3115_MaxBuildersCashbackPercentage ) {
                var propertyValue = 0;
                if (loanRequirement.applicationType === enums.RDApplicationTypeCode.newPurchase) {
                    propertyValue = loanRequirement.purchasePrice;
                } else {
                    propertyValue = loanRequirement.currentEstimatedValue;
                }

                var depositBuildersCashBack = getDepositAmount(enums.RDMortgageDepositSourceCode.builderCashback,
                    loanRequirement.depositDetails);

                var depositVendorsCashBack = getDepositAmount(enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive,
                    loanRequirement.depositDetails);

                var ltv = rulesService.r0041CalculateSalesLTV(loanRequirement.applicationType, loanRequirement.propertyOwnership,
                    loanRequirement.totalLoanAmount, propertyValue, loanRequirement.purchasePrice,
                    loanRequirement.fullMarketValue,
                    depositBuildersCashBack, depositVendorsCashBack, p3115_MaxBuildersCashbackPercentage);

                return ltv;
            }
        },

        r6207GroundRentServiceChargeMessage: function(p6330, applicationType, tenureType, expenditureTypes){

            if(p6330 === 'undefined' || p6330.toLowerCase() === 'false'){
                return false;
            }

            if(applicationType === 'undefined' || parseInt(applicationType) !== enums.RDApplicationTypeCode.newPurchase){
                return false;
            }

            if(tenureType === 'undefined' || parseInt(tenureType) !== enums.RDPropertyTenureCode.leasehold){
                return false;
            }

            var filteredExpenditureTypes = expenditureTypes.filter(function(item){
                return (item.expenditureType === enums.RDHouseholdExpenditureTypeCode.groundRent || item.expenditureType === enums.RDHouseholdExpenditureTypeCode.serviceCharge);
            });
            if(filteredExpenditureTypes && filteredExpenditureTypes.length > 0){
                return false;
            }

            return true;
        },

		r5426CalculateCaseApplicantAnnualOccupationIncome: function(currentIncomes, ip0001_PipelineReleaseFeatureLock) {
			if (!currentIncomes || currentIncomes.length === 0) {
				return {
					totalEmploymentIncome: 0,
					totalOtherIncome: 0,
					totalIncome: 0
				};
			}

			var totalEmploymentIncome = 0;
			var totalOtherIncome = 0;

			angular.forEach(currentIncomes, function (incomeItem) {
				if (incomeItem.incomeType === enums.RDIncomeTypeCode.employmentIncome) {
					var employedForTaxPurposes = incomeItem.employedForTaxPurposes ? 'true' : 'false';
                    incomeItem.annualIncomeAmount = 0;

					if (rulesService.r5060IsBasicSalaryNeeded(incomeItem.employmentContract, employedForTaxPurposes, incomeItem.employmentStatus)) {
						var basicSalaryAnnualAmount = getFrequencyAmount(incomeItem.basicSalary, incomeItem.basicSalaryFrequency);
						var bonusAnnualAmount = getFrequencyAmount(incomeItem.bonusGuaranteed, incomeItem.bonusGuaranteedFrequency);
						var overtimeAnnualAmount = getFrequencyAmount(incomeItem.overtimeGuaranteed, incomeItem.overtimeGuaranteedFrequency);
						var commissionAnnualAmount = getFrequencyAmount(incomeItem.commissionGuaranteed, incomeItem.commissionGuaranteedFrequency);

                        incomeItem.annualIncomeAmount = (basicSalaryAnnualAmount + bonusAnnualAmount + overtimeAnnualAmount + commissionAnnualAmount);
					}

					if (rulesService.r5064IsNetProfitNeeded(incomeItem.employmentStatus, incomeItem.employmentContract, employedForTaxPurposes)) {
                        incomeItem.annualIncomeAmount += isNaN(incomeItem.previousYearNetProfit) ? 0 : incomeItem.previousYearNetProfit;
					}

					if (rulesService.r5067IsSalaryIncludingDividendsNeeded(incomeItem.employmentStatus)) {
						if (!ip0001_PipelineReleaseFeatureLock || ip0001_PipelineReleaseFeatureLock === "2.6") {
                            incomeItem.annualIncomeAmount += isNaN(incomeItem.currentYearSalaryIncDividends) ? 0 : incomeItem.currentYearSalaryIncDividends;
						}
						else {
                            incomeItem.annualIncomeAmount += isNaN(incomeItem.currentYearSalary) ? 0 : incomeItem.currentYearSalary;
                            incomeItem.annualIncomeAmount += isNaN(incomeItem.currentYearDividends) ? 0 : incomeItem.currentYearDividends;
						}
					}

					totalEmploymentIncome += incomeItem.annualIncomeAmount;
				}
				else {
                    incomeItem.annualIncomeAmount = getFrequencyAmount(incomeItem.otherIncomeAmount, incomeItem.otherIncomeAmountFrequency);
                    totalOtherIncome += incomeItem.annualIncomeAmount;
				}
			});

			return {
				totalEmploymentIncome: totalEmploymentIncome,
				totalOtherIncome: totalOtherIncome,
				totalIncome: (totalEmploymentIncome + totalOtherIncome)
			};
		},

		r6248MaxNumberOfBedroomsPermitted: function (propertyPurpose, p6352MaxNumberOfBedroomsNonBtl, p6353MaxNumberOfBedroomsBtl) {
		    return propertyPurpose === enums.RDPropertyPurposeTypeCode.buyToLet ? p6353MaxNumberOfBedroomsBtl : p6352MaxNumberOfBedroomsNonBtl;
		},

        r6302_CalculateDebtConsolidationAdditionalBorrowing: function(additionalBorrowing) {
            var debtConsolidationAdditionalBorrowing = 0;

            if (additionalBorrowing) {
                angular.forEach(additionalBorrowing, function(additionalBorrowingItem) {
                    if (additionalBorrowingItem.borrowingReason == enums.RDAdditionalBorrowingReasonCode.repayUnsecuredDebts ||
                        additionalBorrowingItem.borrowingReason == enums.RDAdditionalBorrowingReasonCode.payOffSecondCharge) {
                        debtConsolidationAdditionalBorrowing +=  additionalBorrowingItem.borrowingAmount;
                    }
                });
            }
		    return debtConsolidationAdditionalBorrowing;
        },

        r6304_AdditionalBorrowingForDebtConsolidationApplicable: function(additionalBorrowing, p6366DebtConsolidationCommitmentsValidationApplicable) {
            var additionalBorrowingForDebtConsolidationAmount = this.r6302_CalculateDebtConsolidationAdditionalBorrowing(additionalBorrowing);

            return p6366DebtConsolidationCommitmentsValidationApplicable.toLowerCase() === 'true' &&
                additionalBorrowingForDebtConsolidationAmount > 0;
        },

        r6433_CommittedExpenditureProviderMandatory: function(p6404CommittedExpenditureProviderMandatoryForSecuredLoans, expenditureType){
            return expenditureType == enums.RDCommittedExpenditureTypeCode.securedLoans &&  p6404CommittedExpenditureProviderMandatoryForSecuredLoans.toLowerCase() === 'true'
        },

        r6479_BtlStressRateDecisionApplicable: function(parameters, propertyPurpose){
            return parameters.p6431.toLowerCase() === 'true' && this.r5388IsBTL(propertyPurpose);
        },

        r6478_BtlStressMaxBorrowingAmountExceeded: function(parameters, loanRequirement, fees){
            var btlStressRateDecisionApplicable = this.r6479_BtlStressRateDecisionApplicable(parameters, loanRequirement.propertyPurpose);
            var totalLoanAmountIncFeesAddedToLoan = loanRequirement.totalLoanAmount + this.f0013GetAddToLoanFeesForMaxAffordability(fees, parameters.p6212, loanRequirement);
            var btlStressMaxBorrowingAmount = loanRequirement.btlStressedMaximumAffordableBorrowing;

            return btlStressRateDecisionApplicable && totalLoanAmountIncFeesAddedToLoan > btlStressMaxBorrowingAmount;
        },

        r6480_BtlStressProductsMessageApplicable: function(parameters, loanRequirement, fees, products){
            var btlStressMaxBorrowingExceeded = this.r6478_BtlStressMaxBorrowingAmountExceeded(parameters, loanRequirement, fees);
            var noProductsFound = this.r5387NoProductsFound(products);

            return btlStressMaxBorrowingExceeded && !noProductsFound;
        },

        r6485_IsApplicantTypeExistingBorrower: function (applicantType){
            if(applicantType === enums.RDApplicantTypeCode.existingBorrowerWithLender){
                return true;
            }
            return false;
        },

        r6486_IsPrimaryMortgageAccountMandatory: function(applicationType){
            if (applicationType === enums.RDApplicationTypeCode.additionalBorrowing ||
                applicationType === enums.RDApplicationTypeCode.rateSwitch){
                return true;
            }
            return false;
        }
    };

    // Helper string.format function
    // Use like this: "My name is {0} and I am {1}".format(name, age)
    if (!String.prototype.format) {
        String.prototype.format = function () {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
            });
        };
    }

    return rulesService;

    //R6317 Calculate Fees Added To Loan
    function feesAddedToLoan(fees) {
        var feesToAdd = 0;
        if (fees && fees.length > 0) {
            for (var i = 0; i < fees.length; i++) {
                if (fees[i].selectedPaymentMethod == enums.RDFeePayableHow.addToLoan) {
                    feesToAdd += fees[i].amount;
                }
            }
        }
        return feesToAdd;
    }

    function removeItemFromValueset(valueset, itemValue) {
        if (valueset) {
            for (var i = 0; i <= valueset.length - 1; i++) {
                if (valueset[i].value === itemValue) {
                    valueset.splice(i, 1);
                    break;
                }
            }
        }
    }

    function removeItemsFromValueset(valueset, itemValues) {
        var len = valueset.length - 1;
        for (var i = len; i >= 0; i--) {
            if (itemValues.indexOf(valueset[i].value) >= 0) {
                valueset.splice(i, 1);
            }
        }
    }

    function removeItemFromArray(array, itemValue) {
        if (array) {
            var index = array.indexOf(itemValue);

            if (index !== -1) {
                array.splice(index, 1);
            }
        }
    }

    function removeItemsFromArray(array, itemValues) {
        var len = array.length - 1;
        for (var i = len; i >= 0; i--) {
            if (itemValues.indexOf(array[i]) >= 0) {
                array.splice(i, 1);
            }
        }
    }

    function getCustomerValue(applicationType, propertyOwnershipType, currentEstimatedValue, purchasePrice, fullMarketValue, additionalBorrowing, afterWorkValue) {
        var customerValue;

        if (applicationType === enums.RDApplicationTypeCode.newPurchase) {
            if (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.rightToBuy) {
                customerValue = fullMarketValue;
            } else {
                customerValue = purchasePrice;
            }
        } else {
            if (additionalBorrowing > 0 && afterWorkValue > 0) {
                customerValue = afterWorkValue;
            } else {
                customerValue = currentEstimatedValue;
            }
        }
        return customerValue;
    }

    function adjustPropertyValue(propertyValue, applicationType, deposits, afterWorksValueIsApplicable, afterWorkValue) {

        if (afterWorksValueIsApplicable === true && afterWorkValue > 0) {
            propertyValue = afterWorkValue;
        }

        if (applicationType === enums.RDApplicationTypeCode.newPurchase && deposits != undefined && deposits.length > 0) {
            for (var i = 0; i < deposits.length; i += 1) {
                if (deposits[i].source === enums.RDMortgageDepositSourceCode.builderCashback && deposits[i].amount > 0) {
                    if (deposits[i].amount > 0.05 * propertyValue) {
                        propertyValue = propertyValue - (deposits[i].amount - 0.05 * propertyValue);
                    }
                }
                if (deposits[i].source === enums.RDMortgageDepositSourceCode.vendorCashbackOrIncentive && deposits[i].amount > 0) {
                    propertyValue -= deposits[i].amount;
                }
            }
        }

        return propertyValue;
    }

    function dStandardMaxLtv(totalLoanAmount) {
        /// <summary>Calculate standard max ltv</summary>
        /// <param name="options" type="numeric">total loan amount</param>

        //Requirement.TotalLoanAmount is between 0.00 - 500,001.00
        if (totalLoanAmount >= 0 && totalLoanAmount < 500001) {
            return 0.90;
        }
        if (totalLoanAmount >= 500001 && totalLoanAmount < 750001) {
            return 0.85;
        }
        if (totalLoanAmount >= 750001 && totalLoanAmount < 1000001) {
            return 0.80;
        }

        return 0.75;
    }

    function dAdditionalBorrowingMaxLtv(additionalBorrowingAmount) {
        /// <summary>Calculate max ltv for additional borrowing</summary>
        /// <param name="options" type="numeric">additional Borrowing Amount</param>
        if (additionalBorrowingAmount >= 0 && additionalBorrowingAmount < 75001) {
            return 0.85;
        }
        if (additionalBorrowingAmount >= 75001 && additionalBorrowingAmount < 100001) {
            return 0.80;
        }
        return 0.75;
    }

    function dSchemeTypeMaxLtv(propertyOwnershipType) {
        /// <summary>Calculate max ltv for schema type</summary>
        /// <param name="propertyOwnershipType" type="numeric">property ownership type</param>
        if (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.sharedOwnership) {
            return 0.95;
        }
        if (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.rightToBuy) {
            return 0.90;
        }

        if (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.equityShare) {
            return 0.95;
        }

        if (propertyOwnershipType === enums.RDPropertyOwnershipTypeCode.mortgageGuarantee) {
            return 0.95;
        }

        return 1;
    }

    function dPropertyTypeMaxLtv(propertyType) {
        var pt = parseInt(propertyType);

        if (pt === enums.RDPropertyTypeCode.convertedFlatOrMaisonette || pt === enums.RDPropertyTypeCode.purposeBuiltFlatOrMaisonette) {
            return 0.6;
        }

        return 1;

    }

    function r5052IsApplicantNotRetired(applicant){
        return !applicant.retired;
    }

    function r3000DetermineOverallCustomerType(customerTypes) {
        /// <summary>
        ///     This calculates over all customer type
        ///     this rule use in R3003 & R3004 rule
        /// </summary>
        /// <param name="customerTypes" type="numeric">list of customer types</param>

        if (isCustomerType(customerTypes, enums.RDApplicantTypeCode.existingBorrowerWithLender)) {
            return enums.RDApplicantTypeCode.existingBorrowerWithLender;
        }
        if (isCustomerType(customerTypes, enums.RDApplicantTypeCode.firstTimeBuyer)) {
            return enums.RDApplicantTypeCode.firstTimeBuyer
        }
        if (isCustomerType(customerTypes, enums.RDApplicantTypeCode.previousBorrowerWithLender)) {
            return enums.RDApplicantTypeCode.previousBorrowerWithLender;
        }
        if (isCustomerType(customerTypes, enums.RDApplicantTypeCode.borrowerWithOtherLender)) {
            return enums.RDApplicantTypeCode.borrowerWithOtherLender;
        }
        if (isCustomerType(customerTypes, enums.RDApplicantTypeCode.previousBorrowerOther)) {
            return enums.RDApplicantTypeCode.previousBorrowerOther;
        }
        return customerTypes[0];
    }

    function isCustomerType(customerTypes, RDApplicantType) {

        var applicantType = customerTypes.filter(function (value) {
            return value === RDApplicantType;
        });
        if (applicantType.length > 0) {
            return true
        }
    }

    function r5060IsBasicSalaryNeeded(contract, employedForTaxPurposes, status) {
        if ((status === enums.RDEmploymentIncomeType.employed) && (
                (contract === enums.RDEmploymentContractTypeCode.permanent) ||
                (contract === enums.RDEmploymentContractTypeCode.fixedTermContract) ||
                (contract === enums.RDEmploymentContractTypeCode.temporary) ||
                (contract === enums.RDEmploymentContractTypeCode.zeroHoursContract) ||
                (contract === enums.RDEmploymentContractTypeCode.pieceWorker)
            )) {
            return true;
        }
        else if ((contract === enums.RDEmploymentContractTypeCode.subContractorFixedTerm || contract === enums.RDEmploymentContractTypeCode.subContractorOpenEnded) && (employedForTaxPurposes === 'true')) {
            return true;
        }
        else if (status === enums.RDEmploymentIncomeType.directorOrShareholderLessThanOrEqualTwentyPercentShare) {
            return true;
        }
        return false;
    }

    function safeInt(input) {
        var result = 0;
        if (input !== undefined && input !== null) {
            if (!isNaN(input)) {
                result = parseInt(input);
            }
        }

        return result;
    }

    function r5087AllOrPartInterestOnly (interestOnlyLoanAmount) {
        return (interestOnlyLoanAmount > 0);
    }

    function calculatePropertyValueExcludingDeposit(applicationType, ownershipType, currentEstimatedValue, purchasePrice, fullMarketValue, avmValuation) {

        var propertyValue = null,
            ot = parseInt(ownershipType),
            at = parseInt(applicationType);

        if (at === enums.RDApplicationTypeCode.newPurchase) {
            propertyValue = (ot === enums.RDPropertyOwnershipTypeCode.rightToBuy ||
            ot === enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember ||
            ot === enums.RDPropertyOwnershipTypeCode.equityShare)
                ? parseInt(fullMarketValue)
                : parseInt(purchasePrice);

        } else {
            var avmValue = safeInt(avmValuation);
            propertyValue = avmValue > 0
                ? avmValue
                : ot === enums.RDPropertyOwnershipTypeCode.equityShare ? parseInt(fullMarketValue) : parseInt(currentEstimatedValue);
        }

        return propertyValue;
    }

    function r6024MaxBuilderCashbackExceeded(depositBuildersCashback, p3115, applicationType, ownershipType, currentEstimatedValue, purchasePrice, fullMarketValue, avmValuation) {

        var propertyValue = calculatePropertyValueExcludingDeposit(applicationType, ownershipType, currentEstimatedValue, purchasePrice, fullMarketValue, avmValuation);
        var buildersCashback = safeInt(depositBuildersCashback);
        if (buildersCashback > 0) {
            var pMaxBuildersCashbackPercentage = parseFloat(p3115) / 100;
            var maxAllowedCashback = Math.round(propertyValue * pMaxBuildersCashbackPercentage);
            return (buildersCashback > maxAllowedCashback);
        }
    }

    function getDepositAmount(type, depositDetails) {
        var amount = 0;

        if (depositDetails && depositDetails.length > 0) {
            for (var i = 0; i < depositDetails.length; i++) {
                if (depositDetails[i].source == type) {
                    amount += depositDetails[i].amount;
                }
            }
        }
        return amount;
    }

    function getFrequencyAmount(amount, frequencyCode) {
		if (isNaN(amount)) {
			return 0;
		}

		if (isNaN(frequencyCode)) {
			return amount;
		}

		switch (frequencyCode) {
			case (enums.RDFrequencyCode.monthly):
			{
				return (amount * 12);
			}
			case (enums.RDFrequencyCode.weekly):
			{
				return (amount * 52);
			}
			case (enums.RDFrequencyCode.fourWeekly):
			{
				return (amount * 13);
			}
			case (enums.RDFrequencyCode.quarterly):
			{
				return (amount * 4);
			}
			default:
				return amount;
		}
    }

    function isApplicable(parameter) {
        // Default to applicable - so if the parameter is not present, the field is applicable
        return angular.isUndefined(parameter) || parameter.toLocaleLowerCase() === 'true';
    }

    function isNullOfUndefined(value) {
        return value === null || value === undefined;
    }
}]);
