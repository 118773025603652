angular.module('mso').factory('contextInterceptor', ['$rootScope', '$q', '$window', '$location', 'SiteConfig', function ($rootScope, $q, $window, $location, SiteConfig) {
    'use strict';

    return {
        request: function (config) {
            config.headers = config.headers || {};

            //if ($window.sessionStorage.channel != undefined && config.url.indexOf('api') > 0) {
            //    console.log('>>>>>>>' + config.url + ' CASE CONTEXT');
            //} else if (config.url.indexOf('api') > 0) {
            //    console.log('>>>>>>>' + config.url + ' SITE CONTEXT');
            //}

            if ($window.sessionStorage.channel != undefined) {
                config.headers.channel = $window.sessionStorage.channel;
            }
            else if (SiteConfig.channel != undefined) {
                config.headers.channel = SiteConfig.channel;
            }

            if ($window.sessionStorage.brand != undefined) {
                config.headers.brand = $window.sessionStorage.brand;
            }
            else if (SiteConfig.brand != undefined) {
                config.headers.brand = SiteConfig.brand;
            }

            if ($window.sessionStorage.sourcePortal != undefined) {
                config.headers.sourcePortal = $window.sessionStorage.sourcePortal;
            }
            else if (SiteConfig.sourcePortal != undefined) {
                config.headers.sourcePortal = SiteConfig.sourcePortal;
            }

            if ($window.sessionStorage.anchorDate != undefined) {
                config.headers.anchorDate = $window.sessionStorage.anchorDate;
            }

            return config;
        }
    };
}]).config(['$httpProvider', function ($httpProvider) {
    'use strict';
    $httpProvider.interceptors.push('contextInterceptor');



}]);

