/*Directive to trigger functionality when the enter key is pressed. Use in html with ng-enter-click="doSomething()"*/
angular.module('mso.directives').directive('ngEnterClick', function() {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {

                setTimeout(function(){
                    scope.$apply(function (){
                        scope.$eval(attrs.ngEnterClick);
                    });
                });

                event.preventDefault();
            }
        });
    };
});