angular.module('mso.services').factory('LogoutService', ['$resource', '$q', function ($resource, $q) {
    var logoutAPI = $resource('/authentication/api/msov1/authenticate/logout');
    return {
        logout:logout
    };

    function logout(){
        var deferred = $q.defer();
        logoutAPI.save().$promise.then(function (data) {
                deferred.resolve(data);
            },
            function (errResp) {
                deferred.reject(errResp);
            });
        return deferred.promise;
    }

}]);
