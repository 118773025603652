angular.module('mso').controller('ProductInformationCtrl', ['$scope', '$modalInstance','product', 'parameters', '$rootScope', '$document',
    function ($scope, $modalInstance, product, parameters, $rootScope, $document) {

        $scope.close = function () {
            // de-register the $routeChangeStart listener
            deRegisterListener();
            $modalInstance.dismiss();
        };

        $document.on("keydown", function(e) {
            var KEY_ESCAPE = 27;
            if (e.keyCode === KEY_ESCAPE) {
                deRegisterListener();
            }
        });

        // Listener to close the modal if user navigates away
        // Adding an event listener also returns a function to de-register the listener
        var deRegisterListener = $rootScope.$on('$routeChangeStart', function(){
            $scope.close();
        });

        $scope.product = product;

        $scope.effectiveToDateKnown = function(product) {
            var date = new Date('0001-01-01'); // Date that is returned if product has a default date and not one stored in the db.
            var effectiveDate = new Date(product.effectiveTo);
            if (date.getTime() == effectiveDate.getTime()) {
                return false;
            }
            else {
                return true;
            }
        };
        
        // Format the Early Repayment Charges according to rule R5268 - Format ERCs
        $scope.formatErc = function(erc, isLast) {
            var durationText = erc.numberOfMonths != null
                ? ' for ' + erc.numberOfMonths + ' months'
                : ' until ' + moment(erc.endDate).format('DD.MM.YYYY');

            if (!isLast) {
                durationText += ', then';
            }
            
            switch (erc.basis){
                case 1: //MonthsInterest
                    return erc.monthsInterest + ' months interest' + durationText;
                case 2: // Fixed amount
                    return '£' + erc.amount + durationText;
                case 3: // Percentage of balance
                    return erc.percentage + '% of the loan amount' + durationText;
                case 4: //FixedAmountPlusPercentageOfBalance
                    return erc.percentage + '% of the loan amount, plus £' + erc.amount + durationText;

            }
            return null;
        };

        $scope.formatChargingBasis = function(product) {
            switch (product.interestChargingBasis){
                case 1:
                    return 'Daily';
                case 2:
                    return 'Monthly';
                case 3:
                    return 'Annual in Days'; //TODO: check if we have any in the database at the moment and check the text on SF/Int environment
            }
            return null;
        };

        // Format rates according to R5267 - FormatRateDetails
        $scope.formatRate = function(rate, currentStep, numberOfSteps) {

            var duration = '';
            if (numberOfSteps === 1 && currentStep === 1) {
                duration = 'for the whole term.';
            }
            else if (numberOfSteps > 1 && currentStep === numberOfSteps) {
                duration = 'for the remaining term.';
            }
            else if (rate.durationInMonths && rate.durationInMonths > 0) {
                duration = 'for ' + rate.durationInMonths + ' months.';
            }
            else if (rate.endDate) {
                    duration = 'until ' + moment(rate.endDate).format('DD.MM.YYYY.');
            }

            var capAndCollar = '';
            if (rate.cappedRate && rate.cappedRate > 0 && rate.collaredRate && rate.collaredRate > 0) {
                // Capped and collared
                capAndCollar = ' The variable rate will not go below a floor of ' + rate.collaredRate + '%, or above a ceiling of '+ rate.cappedRate + '%.';
            }
            else if (rate.cappedRate && rate.cappedRate > 0) {  // Cap only
                capAndCollar = ' The variable rate will not go above a ceiling of ' + rate.cappedRate + '%.';
            }
            else if (rate.collaredRate && rate.collaredRate > 0) { // Collar only
                capAndCollar = ' The variable rate will not go below a floor of ' + rate.collaredRate + '%.';
            }

            var description = (currentStep > 1)
                ? 'Followed by '
                : '';

            if (rate.baseRateName.toLowerCase() === 'fixed') {
                description += (currentStep > 1) ? 'a ' : 'A ';
                description += 'fixed rate of ' + rate.effectiveRatePercent + '% that will apply ' + duration;
            }
            else if (rate.baseRateName.toLowerCase() === 'variable') {
                var fixedAdjustment = ' ';
                if (rate.interestRate && rate.interestRate != 0) {
                    if (rate.interestRate > 0) {
                        fixedAdjustment = ', with a loading of ' + rate.interestRate + '% ';
                    }
                    else {
                        fixedAdjustment = ', with a discount of ' + (rate.interestRate * -1) + '% ';
                    }
                }
                description += parameters.p6030 + '\'s ' + rate.baseRateDescription + ', currently ' + rate.baseRatePercent + '%' + fixedAdjustment + duration + capAndCollar ;
            }
            else { // tracker
                var trackerAdjustment = 'the same as ';
                if (rate.interestRate && rate.interestRate != 0) {
                    if (rate.interestRate > 0) {
                        trackerAdjustment = rate.interestRate + '% above ';
                    }
                    else {
                        trackerAdjustment = (rate.interestRate * -1) + '% below ';
                    }
                }
                description += (currentStep > 1) ? 'a ' : 'A ';
                description += 'variable rate which is ' + trackerAdjustment + rate.baseRateDescription + ', currently ' + rate.baseRatePercent + '% ' + duration + capAndCollar;
            }

            return description;
        };

        //Format text with no content/value to display N/A instead
        $scope.formatDisplayText = function(textToFormat){

            if(textToFormat && textToFormat !== ''){
                return textToFormat;
            }

            return 'N/A';
        };
    }]);
