(function () {
    'use strict';
    angular.module('mso.authentication')
        .controller('ForgottenPasswordCtrl', ForgottenPasswordCtrl);

    ForgottenPasswordCtrl.$inject = ['ForgottenPasswordService', '$exceptionHandler', 'referenceData', '$location', '$window'];
    function ForgottenPasswordCtrl(ForgottenPasswordService, $exceptionHandler, referenceData, $location, $window) {

        var vm = this;

        vm.messages = referenceData.messages;
        vm.parameters = referenceData.parameters;

        // Email address validation parameters. P6259 - pValidMSOEmailFormat, P6260 - pMinEmailAddressLength, P6261 - pMaxEmailAddressLength.
        vm.emailAddressRegEx = new RegExp(vm.parameters.p6259);
        vm.emailMinCharacterLength = vm.parameters.p6260;
        vm.emailMaxCharacterLength = vm.parameters.p6261;

        vm.resetRequestSubmitted = false;
        vm.resetError = false;

        vm.originatingModule = 4; //intermediaryOriginatingModuleId;

        // Functions exposed to the view
        vm.reset = reset;
        vm.backToLogin = backToLogin;


        function reset(form) {
            vm.resetError = false;
            vm.resetRequestSubmitted = false;

            if (form.$invalid) {
                vm.showValidation = true;
            }
            else {
                ForgottenPasswordService.reset(vm.emailAddress, vm.originatingModule)
                    .then(function () {
                        vm.resetRequestSubmitted = true;
                    }, function (error) {
                        if (error.status == '400') {
                            vm.resetError = true;
                        }
                        else {
                            $exceptionHandler(error);
                        }
                    });
            }
        }

        function backToLogin() {
            var location = '';
            location += $location.protocol() + '://' + $location.host() + ':' + $location.port();
            location += '/index.html';

            $window.location.href = location;
        }
    }
})();