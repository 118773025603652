angular.module('mso.directives')
    .directive('msoValuesetLookup', function () {
        return {
            restrict: 'EA',
            controller: 'ValueSetLookupController',
            scope:true,
        template: '<span>{{result}}</span>',

            link: function(scope,el,attr){
                scope.result = scope.referenceDataServ.resolveValuesetValue(attr.value, attr.valueset);

                if (attr['lowercase'] != null){
                    scope.result = scope.result.toLowerCase();
                }
            }
        };
    })
    .controller('ValueSetLookupController', ['$scope', 'ReferenceDataService', function ($scope, ReferenceDataService) {

        $scope.result = "Unknown";
        $scope.referenceDataServ = ReferenceDataService;

    }]);