/**
 * Created by gilberj on 23/09/2014.
 */
angular.module('mso.directives')
    .directive('msoDocumentViewer', function () {
        return {
            restrict: 'EA',
            controller: 'DocumentViewer',
            template: require('/app/template/mso-document-viewer.html').default,
            scope: {
                viewmodel:'='
            }
        };
    })
    .controller('DocumentViewer',['$scope', 'enums', '$modal', 'RequirementsService', 'ReferenceDataService',
        '$exceptionHandler', 'Config', '$routeParams',
        function($scope, enums, $modal, RequirementsService, ReferenceDataService, $exceptionHandler,
                 Config, $routeParams){

        $scope.applicationString = ReferenceDataService.resolveValuesetValue(enums.RDCaseRequirementAppliesTo.application, 'RDCaseRequirementAppliesTo');
        $scope.enums = enums;
        $scope.canUploadDocuments = canUploadDocuments;
        $scope.uploadDocuments = uploadDocuments;

        $scope.refreshRequirements = function(caseId){
            RequirementsService.getRequirements(caseId).then(function(data){
                    var requirements;
                    if(data.caseRequirements){
                        requirements = data.caseRequirements;
                    }
                    else {
                        requirements = data;
                    }

                    $scope.viewmodel.caseRequirements = requirements.filter(function (req) {
                        return [enums.RDCaseRequirementStatus.requested, enums.RDCaseRequirementStatus.received,
                            enums.RDCaseRequirementStatus.satisfied].indexOf(req.status) >= 0;
                    });

                    $scope.viewmodel.caseRequirements = RequirementsService.addDescriptionToRequirements($scope.viewmodel.caseRequirements);
                    var requiredDocumentsCount = RequirementsService.getOutstandingRequirementCount($scope.viewmodel.caseRequirements);

                    $scope.$parent.$broadcast('caseRequirementsUpdated', requiredDocumentsCount);
                    $scope.viewmodel.outstandingRequirementsCount = requiredDocumentsCount;
                    $scope.viewmodel.allRequirementsReceived =  requiredDocumentsCount === 0;
                },
                function(error){
                    $exceptionHandler(error);
                });
        };

        function uploadDocuments() {
            var modalInstance = $modal.open({
                template: require('/app/template/upload-documents-v2.html').default,
                backdrop: 'static',
                controller: 'UploadDocumentV2Ctrl as uploadDocuments',
                windowClass: 'upload-document-dialog',
                size: 'lg',
                resolve: {
                    requirements: function() {
                        // Filter requirements - don't present those that the intermediary can't upload
                        return $scope.viewmodel.caseRequirements.filter(function (req) {
                            return req.attachable;
                        });
                    },
                    referenceData: function () {
                        return {
                            valuesets: $scope.viewmodel.vs,
                            parameters: $scope.viewmodel.parameters,
                            messages: $scope.viewmodel.messages
                        };
                    },
                    caseId: function () {
                        return $scope.caseId;
                    },
                    uploadDocumentResource: function () {
                        return {
                            href: Config.apiBaseHref + '/cases/' + $routeParams.caseId + '/documents/',
                            method: 'post'
                        };
                    },
                    applicants: function(){
                        return $scope.viewmodel.applicants;
                    }
                }
            });

            modalInstance.result.then(function () {
                    $scope.refreshRequirements($scope.viewmodel.caseId);
                },
                function() {

                });
        }

        function canUploadDocuments(){
            if ($scope.viewmodel === undefined)
                return false;

            if ($scope.viewmodel.readOnlyCaseAccess ||
                $scope.viewmodel.caseRequirements === undefined)
                return false;

            return $scope.viewmodel.caseRequirements.some(function (req) {
                return req.attachable;
            });
        }
    }]);
