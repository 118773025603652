
    angular.module('mso.authentication').factory('LoginService', ['$q', '$resource', 'Config',

    function ($q, $resource, Config) {
        var authenticateApi = $resource(Config.apiBaseHref + "/authenticate");
        return {
            login:login
        };

        function login(user){
            var deferred = $q.defer();

            authenticateApi.save({userName: user.userName, password: user.password}).$promise.then(function(data){
                deferred.resolve(data);
            }, function(err){
                deferred.reject(err);
            });
            return deferred.promise;
        };

    }]);