angular.module('mso.directives')
    .directive('msoPaymentOptionsPanel', function () {
        return {
            restrict: 'EA',
            controller: 'PaymentOptionsController',
            template: require('/app/template/mso-payment-options-panel.html').default,
            scope: {
                viewModel: '=',
                form: '='
            }
        }
    }
)
    .controller('PaymentOptionsController', ['$scope', 'enums', 'ValuationFeeService'
        , function ($scope, enums, ValuationFeeService) {
            $scope.enums = enums;
            $scope.sumAdvanceFees = function () {
                var sum = 0;

                if ($scope.viewModel.fees) {
                    angular.forEach($scope.viewModel.fees, function (fee) {
                        if(fee.payableWhen === enums.RDFeePayableWhen.onApplication  && !fee.isEstimated &&
                            (angular.isUndefined(fee.feeStatus) || fee.feeStatus == enums.RDFeeStatus.unpaid)) {
                            angular.forEach(fee.payableHowList, function (option) {
                                if (option.payableHowCode === fee.selectedPaymentMethod && parseInt(option.payableHowCode) === enums.RDFeePayableHow.creditDebitCard) {
                                    sum += fee.amount;
                                }
                            });
                        }
                    });
                }

                // R5440 - do not include valuation fee if it is estimated
                // PCV - or if it has already been paid
                if (($scope.viewModel.isFeeEstimated === undefined || !$scope.viewModel.isFeeEstimated)
                    && (!$scope.viewModel.valuationFeeStatus || $scope.viewModel.valuationFeeStatus === enums.RDFeeStatus.unpaid)) {
                    sum += $scope.viewModel.valuationFee || 0;
                }
                $scope.viewModel.totalFeesAdvance = sum;
            };

            $scope.formatSelectedValuation = function(valuationCaption, feeAmount, isFeeEstimated) {
                return ValuationFeeService.formatValuationCaption(valuationCaption, feeAmount, isFeeEstimated);
            };

            $scope.$watch('viewModel.fees + viewModel.valuationFee + viewModel.valuationFeeStatus', function () {
                if ($scope.viewModel.fees) {
                    angular.forEach($scope.viewModel.fees, function (value) {
                        if (!value.selectedPaymentMethod && value.payableHowList.length === 1) {
                            //We need the model selectedPaymentMethod to be string based so that
                            //its comparison works to match it to a selected option.
                            value.selectedPaymentMethod = value.payableHowList[0].payableHowCode;
                        }
                    });

                    $scope.sumAdvanceFees();
                }
            });
        }
    ]).controller('PaymentTableCtrl', ['$scope',
    function ($scope) {
        $scope.$watch('fee.selectedPaymentMethod', function () {
            $scope.$parent.sumAdvanceFees();
        })
    }]);


