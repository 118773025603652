angular.module('mso.services').factory('ReferenceDataService',  ['$resource', '$q', '$log', 'Config', 'ReferenceDataMap', 'SiteConfig', '$window', '$exceptionHandler', function($resource, $q, $log, Config, ReferenceDataMap, SiteConfig, $window, $exceptionHandler){
        var ReferenceData = $resource(Config.apiBaseHref + '/configuration/:type', { type:'' }, {
            getValuesets: { method: 'GET', isArray: false, params:{type: 'valuesets'} },
            getMessages: { method: 'GET', isArray: false, params:{type: 'messages'} },
            getParameters: { method: 'GET', isArray: false, params:{type: 'parameters'} }
        });

        var specialValuesets =
        {yesNo: [
            { "value": true, "caption": "Yes" },
            { "value": false, "caption": "No" }
        ],
            months: [
                { "value": 1, "caption": "January" },
                { "value": 2, "caption": "February" },
                { "value": 3, "caption": "March" },
                { "value": 4, "caption": "April" },
                { "value": 5, "caption": "May" },
                { "value": 6, "caption": "June" },
                { "value": 7, "caption": "July" },
                { "value": 8, "caption": "August" },
                { "value": 9, "caption": "September" },
                { "value": 10, "caption": "October" },
                { "value": 11, "caption": "November" },
                { "value": 12, "caption": "December" }
            ]
        };
        var refDataCache = {
            valuesets:{},
            messages:{},
            parameters:{}
        };

        function buildQueryDelta(targets, cache, prefix){
            var query = '';
            for(var i=0; i < targets.length;i++){

                var testValue = (prefix === undefined || targets[i].lastIndexOf(prefix,0) === 0) ? targets[i] : prefix + targets[i];
                if(!cache.hasOwnProperty(testValue)){
                    query = query.concat(testValue, '+');
                }
            }
            return query.slice(0, -1);
        }

        function getReferenceDataForView(viewName, caseData){

            var deferred = $q.defer();
            var mapping = null;
            angular.forEach(ReferenceDataMap, function(viewMap){
                if(viewMap.when === viewName){
                    mapping = viewMap;
                }
            });

            if(!mapping){
                throw new Error("getReferenceDataForView():: Unable to locate view '" + viewName + "' in the reference data map");
            }

            angular.extend(refDataCache.valuesets, specialValuesets);

            var promises = [];

            //Add the operational messages/parameters to all reference data calls.
            //This needs to be done in advance, in anticipation of any errors occuring.
            var operationalMessages  = ['3012', '3013', '3015', '3016', '3017', '3019', '3020'];
            var operationalParameters = ['p6048', 'p6110', 'iP0001'];
            angular.forEach(operationalMessages, function(message){
                if (mapping.messages.indexOf(message) === -1) {
                    mapping.messages.push(message);
                }
            });
            angular.forEach(operationalParameters, function(parameter){
                if (mapping.parameters.indexOf(parameter) === -1) {
                    mapping.parameters.push(parameter);
                }
            });

            var vsQueryString = buildQueryDelta(mapping.valuesets, refDataCache.valuesets );

            //default context values
            var channel = SiteConfig.channel;
            var brand = SiteConfig.brand;
            var anchorDate = null;

            if ($window.sessionStorage.channel != undefined) {
                channel = $window.sessionStorage.channel;
                brand = $window.sessionStorage.brand;
                anchorDate = $window.sessionStorage.anchorDate;
            }

            if(vsQueryString.length > 0){
                promises.push(ReferenceData.getValuesets({names: vsQueryString, format:'associative', channel: channel, brand: brand, anchorDate: anchorDate}).$promise.then( function(valuesets){
                    angular.extend(refDataCache.valuesets, valuesets);

                }));
            }
            var msgQueryString = buildQueryDelta(mapping.messages, refDataCache.messages ,'msg');
            msgQueryString = msgQueryString.replace(/msg/g,'');
            if(msgQueryString.length > 0){
                promises.push( ReferenceData.getMessages({ids: msgQueryString, format:'associative', channel: channel, brand: brand, anchorDate: anchorDate}).$promise.then( function(messages){
                    angular.extend(refDataCache.messages, messages);
                }));
            }
            var prmQueryString = buildQueryDelta(mapping.parameters, refDataCache.parameters );
            if(prmQueryString.length > 0)
            {
                promises.push( ReferenceData.getParameters({names: prmQueryString,format:'associative', channel: channel, brand: brand, anchorDate: anchorDate}).$promise.then( function(parameters){
                    angular.extend(refDataCache.parameters, parameters);
                }));
            }

            if(promises.length === 0){
                return $q.when(refDataCache);
            }

            $q.all(promises).then(
                function(){
                    deferred.resolve(refDataCache);
                },
                function(error){
                    $exceptionHandler(error);
                }
            );

            return deferred.promise;
        }
        function resolveValuesetValue(value, valueSet)
        {
            for(var p in refDataCache.valuesets){
                if (refDataCache.valuesets.hasOwnProperty(p)
                    && p.toUpperCase() === valueSet.toUpperCase())            {
                    for(var i=0; i < refDataCache.valuesets[p].length;i++){
                        if(refDataCache.valuesets[p][i].value == value){
                            return refDataCache.valuesets[p][i].caption;
                        }
                    }
                }
            }

            return "#Unknown#";
        }
        function resolveMessageText(messageId){
            for (var m in refDataCache.messages){
                if (refDataCache.messages.hasOwnProperty(m)
                    &&m.toLowerCase() == messageId.toLowerCase()){
                    return refDataCache.messages[m];
                }
            }
            return "";
        }
 
        function resolveParameterValue(parameterId){
            for (var p in refDataCache.parameters){
                if (refDataCache.parameters.hasOwnProperty(p)
                    &&p.toLowerCase() == parameterId.toLowerCase()){
                    return refDataCache.parameters[p];
                }
            }
            return "";
        }

        return {
            getReferenceDataForView : getReferenceDataForView,
            resolveValuesetValue: resolveValuesetValue,
            resolveMessageText: resolveMessageText,
            resolveParameterValue: resolveParameterValue
        };
    }]);