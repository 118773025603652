import angular from "angular";

require("angular-animate");
require("angular-resource");
require("angular-route");
require("angular-sanitize");

require("angular-i18n/angular-locale_en-gb");

require("angular-localization");

require("angular-bootstrap");

require("angularjs-toaster");

import moment from "moment";

import "moment/locale/en-gb";

window.moment = moment;

const msoContext = require.context(
  "./scripts/mso/",
  true,
  /^(?!.*\.module\.js$)^(?!.*\.spec\.js$).*\.js$/,
);

msoContext.keys().forEach(msoContext);

const modulesContext = require.context(
  "./modules/",
  true,
  /^(?!.*\.module\.js$)^(?!.*\.spec\.js$).*\.js$/,
);

modulesContext.keys().forEach(modulesContext);

const sessionContext = require.context(
  "./scripts/session-expiry/",
  true,
  /^(?!.*\.module\.js$)^(?!.*\.spec\.js$).*\.js$/,
);

sessionContext.keys().forEach(sessionContext);

import "./scripts/app.module";

export default angular.module("mso.authentication");
