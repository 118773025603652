angular.module('mso.directives')
    .directive('caseRequirementDropDown', function () {
        return {
            restrict: "EA",
            template: require('/app/template/case-requirement-dropdown.template.html').default,
            scope: {
                keyValuePairs: "=",
                selectedKeys: "=",
                placeholder: "@",
                requirementsSelectionComplete: "="
            },
            replace: true,
            link: function (scope, element, attrs) {

                scope.requirementsSelectionComplete = false;
                scope.options =
                        scope.keyValuePairs
                                .map(function (cv) {
                                    return {
                                        key: cv.key,
                                        value: cv.value,
                                        isSelected: scope.selectedKeys.indexOf(cv.key) !== -1
                                    };
                                });

                scope.checkboxSelected = function () {
                    scope.selectedKeys =
                            scope.options
                                    .filter(function (option) {
                                        return option.isSelected;
                                    })
                                    .map(function (option) {
                                        return option.key;
                                    });
                };

                scope.$watch('selectedKeys', function () {
                    var f = function () {
                        var selected = scope.options
                                .filter(function (option) {
                                    return option.isSelected;
                                });

                        switch (selected.length) {
                            case 0:
                                return undefined;
                            case 1:
                                return "1 requirement selected";
                            default:
                                return selected.length + " requirements selected";
                        }
                    };

                    scope.selectionText = f();
                });

                scope.setRequirementsSelectionComplete = function() {
                    scope.requirementsSelectionComplete = true;
                };

                scope.clickHandler = function(event) {
                    event.stopPropagation();
                    scope.requirementsSelectionComplete = false;
                };
            }
        };
    });

