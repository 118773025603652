// Matches any anchor ('a') tags which have no href attribute and adds an empty href
// This is required for accessibility - tabbing through a page using the keyboard does not recognise anchor tags without an href attribute
angular.module('mso').directive('a', function () {
   return {
       restrict: 'E',
       link: function(scope, el, attr) {
           var hrefFound = false;
           if (el[0].attributes && el[0].attributes && el[0].attributes.length > 0) {
               for (var i = 0; i < el[0].attributes.length; i++) {
                   if (el[0].attributes[i].name == 'href') {
                       hrefFound = true;
                       break;
                   }
               }
           }

           if (!hrefFound) {
               el[0].attributes.setNamedItem(document.createAttribute("href"));
           }
       }
   };
});