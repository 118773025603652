angular.module('mso.services')
    .factory('ProductFactoryModuleService', ['$resource', '$q', 'Config', 'enums', '$window', '$routeParams', 'RuleService', 'noDecimalPlaceCurrencyFilter', '$filter', function ($resource, $q, Config, enums, $window, $routeParams, RuleService, noDecimalPlaceCurrencyFilter, $filter) {
        "use strict";
        var ProductSearchApi = $resource(Config.productFactoryApiBaseHref + '/search', {caseId: $routeParams.caseId}, {'post': {method: 'POST', isArray: true} });
        var ProductInformationApi = $resource(Config.productFactoryApiBaseHref + '/product-information', {caseId: $routeParams.caseId});
        var GetValuationFeesApi = $resource(Config.productFactoryApiBaseHref + '/valuation-fees',  {caseId: $routeParams.caseId}, {'post': {method: 'POST', isArray: true} });
        var EsisApi = $resource(Config.productFactoryApiBaseHref + '/esis', {caseId: $routeParams.caseId});
        var CheckEligibilityApi = $resource(Config.productFactoryApiBaseHref + '/eligibility', {caseId: $routeParams.caseId}, {'post': {method: 'POST', isArray: false} });
        var GetPackageFeesApi = $resource(Config.productFactoryApiBaseHref + '/package-fees', {caseId: $routeParams.caseId}, {'post': {method: 'POST', isArray: true} });
        var GetPackageFeaturesApi = $resource(Config.productFactoryApiBaseHref + '/package-features', {caseId: $routeParams.caseId}, {'post': {method: 'POST', isArray: true} });

        var service = {
            // Check the eligibility of a product
            checkEligibility: function(modellingData, repaymentAmount, interestOnlyAmount, productCode, ltv, termInMonths){
                var checkEligibilityRequest = service.prepareProductSearchRequest(modellingData, repaymentAmount, interestOnlyAmount, productCode, null, ltv, termInMonths);
                // Set ignoreEffectiveDate to false as call is coming from a module before submission
                checkEligibilityRequest.ignoreEffectiveDate = false;

                var deferred = $q.defer();
                CheckEligibilityApi.post(checkEligibilityRequest).$promise.then(function (data) {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            // Calls API to get an Esis document
            generateEsis: function (modellingData, illustrationMortgageSolution, r0041Ltv, r0043MaxLtv,
                                    maxAffordableBorrowing) {

                var getEsisForIllustrationRequest = service.prepareEsisRequest(modellingData, r0041Ltv, r0043MaxLtv,
                    maxAffordableBorrowing);

                // The "solutionData" is included in the SolutionIllustratedPmEvent raised by PM, and used to reconstitute the illustration data later, if required
                getEsisForIllustrationRequest.solutionData = illustrationMortgageSolution;

                var deferred = $q.defer();
                EsisApi.save(getEsisForIllustrationRequest).$promise.then(function (data) {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            // Calls API to get ValuationFees for the specified fee types
            getValuationFees: function(modellingData, lenderFeeTypes)
            {
                var getLenderFeesRequest = service.prepareLenderFeesRequest(modellingData, lenderFeeTypes);

                var deferred = $q.defer();

                GetValuationFeesApi.post(getLenderFeesRequest).$promise.then(function(data) {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            // Calls API to get ProductInformation
            getProductInformation: function(modellingData, repaymentAmount, interestOnlyAmount, productCode, termInMonths)
            {
                var getProductInformationRequest = service.prepareProductInformationRequest(modellingData, productCode, repaymentAmount, interestOnlyAmount, termInMonths);
                var deferred = $q.defer();

                ProductInformationApi.save(getProductInformationRequest).$promise.then(function(data) {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            // Calls API to search for products
            searchProducts: function (modellingData, repaymentAmount, interestOnlyAmount, productCode, productSet, ltv, termInMonths) {
                var productSearchRequest = service.prepareProductSearchRequest(modellingData, repaymentAmount, interestOnlyAmount, productCode, productSet, ltv, termInMonths);

                var deferred = $q.defer();

                ProductSearchApi.post(productSearchRequest).$promise.then(function (data) {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            // Calls API to get the package fees payable to the lender
            getPackageFees: function (modellingData, valuationType) {
                var getPackageFeesRequest = service.prepareGetPackageFeesRequest(modellingData, valuationType);

                var deferred = $q.defer();

                GetPackageFeesApi.post(getPackageFeesRequest).$promise.then(function (data) {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            // Calls API to get the package features payable to the lender
            getPackageFeatures: function (modellingData) {
                var getPackageFeaturesRequest = service.prepareGetPackageFeaturesRequest(modellingData);

                var deferred = $q.defer();

                GetPackageFeaturesApi.post(getPackageFeaturesRequest).$promise.then(function (data) {
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            
            // Prepares the data structure to send to the product search service
            prepareProductSearchRequest: function (modellingData, repaymentAmount, interestOnlyAmount, productCode, productSet, ltv, termInMonths) {
                var propertyValue = service.getPropertyValue(modellingData);

                var oldestDob = service.getOldestApplicantDob(modellingData.applicants);
                var assumedStartDate = service.getAssumedStartDate();
                var maxAgeAtStart = service.getAgeAtDate(oldestDob, assumedStartDate);
                var dateAtTerm = moment(assumedStartDate)
                    .add(modellingData.loanRequirement.mortgageTermMonths, 'months')
                    .add(-1, 'days')
                    .toDate();
                var maxAgeAtTerm = service.getAgeAtDate(oldestDob, dateAtTerm);
                if(termInMonths === undefined){
                    termInMonths = service.resetTermToMortgageTermInMonths(modellingData);
                }

                var productSearchRequest = {
                    productCode: productCode,
                    applicationType: modellingData.loanRequirement.applicationType,
                    overallApplicantType: modellingData.loanRequirement.overallApplicantType,
                    paymentRoute: [
                        modellingData.intermediary.paymentRouteCode
                    ],
                    productSet: productSet,
                    propertyLocation: modellingData.loanRequirement.propertyLocation,
                    propertyOwnershipType: modellingData.loanRequirement.propertyOwnership,
                    propertyPurpose: modellingData.loanRequirement.propertyPurpose,
                    equityShareScheme: modellingData.loanRequirement.equityShareScheme,
                    mortgageGuaranteeScheme: modellingData.loanRequirement.guaranteeShareScheme,
                    firmRegistrationNumber: modellingData.intermediary.firmFcaNumber,
                    interestOnlyAmount: interestOnlyAmount,
                    totalLoanAmount: modellingData.loanRequirement.totalLoanAmount,
                    repaymentAmount: repaymentAmount,
                    ltv: ltv,
                    propertyValue: propertyValue,
                    termInMonths: termInMonths,
                    oldestApplicantAgeAtStart: maxAgeAtStart,
                    oldestApplicantAgeAtTerm: maxAgeAtTerm,
                    channel: modellingData.channel,
                    productsEffectiveAt: new Date()
                };

                return productSearchRequest;
            },

            // Prepares the data structure to send to the getPackageFees API operation
            prepareGetPackageFeesRequest: function (modellingData, valuationType) {
                var propertyValue = service.getPropertyValue(modellingData);

                // If we're in PCV, and there are reinspection fees present from originations, make the getPackageFees
                // call with the relevant valuation type so that the reinspection fees get added to the package.
                // This is so that on resubmission the fees are not cancelled by the fee correctors.

                if (modellingData.loanRequirement.hasPropertyChanged === false && modellingData.adjustedFees) {
                    var reinspectionFees = modellingData.adjustedFees.filter(function(fee) {
                        return isReinspectionFee(fee)
                    });
                    if (reinspectionFees && reinspectionFees.length > 0) {
                        var latestReinpectionFee = $filter('orderBy')(reinspectionFees, '-createdDate')[0];
                        valuationType = mapFeeTypeToValuationType(latestReinpectionFee.type);
                    }
                }



                var requestedProducts = [];
                angular.forEach(modellingData.selectedProducts, function(product){

                    if(product.termInMonths === undefined){
                        product.termInMonths = service.resetTermToMortgageTermInMonths(modellingData);
                    }

                    requestedProducts.push({
                        productCode: product.code,
                        interestOnlyAmount: product.interestOnlyAmount,
                        repaymentAmount: product.repaymentAmount,
                        termInMonths: product.termInMonths
                    });
                });

                var selectedFeePaymentOptions = [];
                angular.forEach(modellingData.feePaymentOptions, function(feePaymentOption){
                    selectedFeePaymentOptions.push({
                        feeId: feePaymentOption.feeId,
                        productId: feePaymentOption.parentProductId,
                        paymentMethod: feePaymentOption.chosenPaymentOption
                    });
                });

                var getPackageFeesRequest = {
                    requestedProducts: requestedProducts,
                    selectedFeePaymentOptions: selectedFeePaymentOptions,
                    effectiveDate: new Date(),
                    firmRegistrationNumber: modellingData.intermediary.firmFcaNumber,
                    applicationType: modellingData.loanRequirement.applicationType,
                    paymentRoute: [
                        modellingData.intermediary.paymentRouteCode
                    ],
                    overallApplicantType: modellingData.loanRequirement.overallApplicantType,
                    propertyValue: propertyValue,
                    valuationType: valuationType,
                    propertyPurpose: modellingData.loanRequirement.propertyPurpose
                };

                return getPackageFeesRequest;
            },

            // Prepares the data structure to send to the GetLenderFees API operation
            prepareLenderFeesRequest: function(modellingData, lenderFeeTypes){
                 var propertyValue = service.getPropertyValue(modellingData);

                 var getLenderFees = {
                     feeTypes: lenderFeeTypes,
                     valuationType: enums.RDValuationType.initialPhysicalValuationTypes,
                     effectiveDate: new Date(),
                     firmRegistrationNumber: modellingData.intermediary.firmFcaNumber,
                     paymentRoute: [
                         modellingData.intermediary.paymentRouteCode
                     ],
                     overallApplicantType: modellingData.loanRequirement.overallApplicantType,
                     applicationType: modellingData.loanRequirement.applicationType,
                     propertyValue: propertyValue,
                     totalLoanAmount: modellingData.loanRequirement.totalLoanAmount,
                     requestedProducts: modellingData.selectedProducts,
                     hasPropertyChanged: modellingData.loanRequirement.hasPropertyChanged,
                     selectedValuationType: modellingData.valuationType,
                     propertyPurpose: modellingData.loanRequirement.propertyPurpose
                 };
                 return getLenderFees;
             },

            // Prepares the data structure to send to the getProductInformation API operation
            prepareProductInformationRequest: function(modellingData, productCode, repaymentAmount, interestOnlyAmount, termInMonths){
                var propertyValue = service.getPropertyValue(modellingData);

                if(termInMonths === undefined){
                    termInMonths = service.resetTermToMortgageTermInMonths(modellingData);
                }

                var productInformationRequest = {
                    productCode: productCode,
                        repaymentAmount: repaymentAmount,
                    interestOnlyAmount: interestOnlyAmount,
                    termInMonths: termInMonths,
                    effectiveDate: new Date(),
                    firmRegistrationNumber: modellingData.intermediary.firmFcaNumber,
                    applicationType: modellingData.loanRequirement.applicationType,
                    paymentRoute: [
                        modellingData.intermediary.paymentRouteCode
                ],
                    overallApplicantType: modellingData.loanRequirement.overallApplicantType,
                    propertyValue: propertyValue,
                    totalLoanAmount: modellingData.loanRequirement.totalLoanAmount,
                    propertyPurpose: modellingData.loanRequirement.propertyPurpose
                };

                return productInformationRequest;
            },

            // Prepares the data structure to send to the getEsis API operation
            prepareEsisRequest: function(modellingData, r0041Ltv, r0043MaxLtv, maxAffordableBorrowing){
                var customers = [];
                angular.forEach(modellingData.applicants, function(applicant){
                    customers.push({
                        forename: applicant.forename,
                        middlename: "middlename" in applicant ? applicant.middlename : "",
                        surname: applicant.surname
                    });
                });

                var products = [];
                angular.forEach(modellingData.selectedProducts, function(product){

                    if(product.termInMonths === undefined){
                        product.termInMonths = service.resetTermToMortgageTermInMonths(modellingData);
                    }

                    product.productCode = product.code;
                    product.termInMonths = product.termInMonths;
                    products.push(product);
                });

                var propertyValue = service.getPropertyValue(modellingData);

                var intermediaryAddress = modellingData.intermediary.address;
                intermediaryAddress.postCode = intermediaryAddress.line10;
                intermediaryAddress.telephoneNumber = modellingData.intermediary.intTelNo;

                var selectedFeePaymentOptions = [];
                angular.forEach(modellingData.feePaymentOptions, function(feePaymentOption){
                    selectedFeePaymentOptions.push({
                        feeId: feePaymentOption.feeId,
                        productId: feePaymentOption.parentProductId,
                        paymentMethod: feePaymentOption.chosenPaymentOption
                    });
                });

                var otherFees = [];
                if(modellingData.intermediaryAdvice.feeChargedToApplicant > 0)
                {
                    var refundableAmount = 0;
                    switch (modellingData.intermediaryAdvice.isFeeRefundable){
                        case 1: //enums.RDFeeRefundable.no
                            refundableAmount = 0;
                            break;
                        case 2: //enums.RDFeeRefundable.yesAmount
                            refundableAmount = modellingData.intermediaryAdvice.refundableAmount;
                            break;
                        case 3: //enums.RDFeeRefundable.yesPercentage
                            refundableAmount = (modellingData.intermediaryAdvice.refundablePercentage * modellingData.intermediaryAdvice.feeChargedToApplicant) / 100;
                            break;
                    }
                    var intermediaryFee = {
                        type: enums.RDFeeType.intermediary,
                        amount: modellingData.intermediaryAdvice.feeChargedToApplicant,
                        payableTo: enums.RDFeePayableTo.broker,
                        payableToDescription: modellingData.intermediaryAdvice.feePayableTo || null,
                        payableWhen: modellingData.intermediaryAdvice.feePayableWhen || null,
                        refundableAmount: refundableAmount,
                        refundable: modellingData.intermediaryAdvice.isFeeRefundable,
                        isEstimated: false,
                        isSubjectToChange: false,
                        selectedPaymentMethod: enums.RDFeePayableHow.creditDebitCard
                    };
                    otherFees.push(intermediaryFee);
                }

                if (isCaseInPcv(modellingData)) {
                    otherFees.push.apply(otherFees, modellingData.packageFees);

                    if (modellingData.loanRequirement.hasPropertyChanged === false && modellingData.adjustedFees) {
                        // Find valuation and reinspection fees
                        var valuationAndReinspectionFees = modellingData.adjustedFees.filter(function(fee) {
                            return isValuationOrReinspectionFee(fee);
                        });
                        if (valuationAndReinspectionFees && valuationAndReinspectionFees.length > 0) {
                            for (var i = 0; i < valuationAndReinspectionFees.length; i++) {
                                var valuationOrReinsppectionFee = {
                                    type: valuationAndReinspectionFees[i].type,
                                    amount: valuationAndReinspectionFees[i].currentAmount,
                                    payableTo: valuationAndReinspectionFees[i].payableTo,
                                    payableToDescription: null,
                                    payableWhen: valuationAndReinspectionFees[i].payableWhen,
                                    refundableAmount: null,
                                    refundable: valuationAndReinspectionFees[i].refundable,
                                    isEstimated: valuationAndReinspectionFees[i].estimated,
                                    isSubjectToChange: valuationAndReinspectionFees[i].canBeVaried,
                                    selectedPaymentMethod: valuationAndReinspectionFees[i].feePaymentOptions[0],
                                    description: valuationAndReinspectionFees[i].description,
                                    msoReference: valuationAndReinspectionFees[i].msoReference,
                                    feeStatus: valuationAndReinspectionFees[i].feeStatus
                                };
                                otherFees.push(valuationOrReinsppectionFee);
                            }
                        }
                    }
                }

                var getEsisForIllustrationRequest = {
                    caseId: modellingData.caseId,
                    customerDetails: customers,
                    isRegulated: service.isRegulated(modellingData.loanRequirement),
                    caseReference: modellingData.friendlyId,
                    levelOfService: modellingData.intermediaryAdvice.levelOfService,
                    intermediaryAddress: intermediaryAddress,
                    intermediaryAdvisorName: modellingData.intermediary.forename + " " + modellingData.intermediary.surname,
                    intermediaryFirmName: modellingData.intermediary.intermediaryFirm,
                    procFeePrimaryRecipient: modellingData.intermediary.paymentRouteName, // TODO: this comes from a flag set on PaymentRoutes. GetIntermediaryInfo needs to be updated to bring back the flag set in PanelManagement.
                    lenderDetails: "", // These are retrieved within the controller
                    requestedProducts: modellingData.selectedProducts,
                    otherFees: otherFees,
                    effectiveDate: new Date(),
                    channel: "Intermediary",
                    firmRegistrationNumber: modellingData.intermediary.firmFcaNumber,
                    applicationType: modellingData.loanRequirement.applicationType,
                    paymentRoute: [
                        modellingData.intermediary.paymentRouteCode
                    ],
                    overallApplicantType: modellingData.loanRequirement.overallApplicantType,
                    propertyValue: propertyValue,
                    valuationType: modellingData.valuationType,
                    selectedFeePaymentOptions: selectedFeePaymentOptions,
                    propertyPurpose: modellingData.loanRequirement.propertyPurpose,
                    propertyLocation: modellingData.loanRequirement.propertyLocation,
                    propertyOwnershipType: modellingData.loanRequirement.propertyOwnership,
                    equityShareScheme: modellingData.loanRequirement.equityShareScheme,
                    mortgageGuaranteeScheme: modellingData.loanRequirement.guaranteeShareScheme,
                    ltv: r0041Ltv,
                    maxLtv: r0043MaxLtv,
                    maxTotalLoanAmount: maxAffordableBorrowing
                };

                return getEsisForIllustrationRequest;
            },
            // Prepares the data structure to send to the getPackageFeatures API operation
            prepareGetPackageFeaturesRequest: function (modellingData) {

                var requestedProducts = [];
                angular.forEach(modellingData.selectedProducts, function(product){
                    requestedProducts.push({
                        productCode: product.code,
                        interestOnlyAmount: product.interestOnlyAmount,
                        repaymentAmount: product.repaymentAmount
                    });
                });

                var getPackageFeaturesRequest = {
                    requestedProducts: requestedProducts,
                    effectiveDate: new Date(),
                    applicationType: modellingData.loanRequirement.applicationType,
                    overallApplicantType: modellingData.loanRequirement.overallApplicantType
                };

                return getPackageFeaturesRequest;
            },

            isRegulated: function(loanRequirement){
                var lr = loanRequirement;
                if (RuleService.r5388IsBTL(lr.propertyPurpose)) {
                    return !RuleService.r5920ConsumerBTLIsNonRegulated(lr.applicationType, lr.intendedFamilyOccupancy, lr.previousFamilyOccupancy, lr.familyOccupancy, lr.ownOtherLetProperties)
                }
                return true;
            },

            getAgeAtDate: function(dob, date) {
                var age = date.getFullYear() - dob.getFullYear();
                var m = date.getMonth() - dob.getMonth();
                if (m < 0 || (m === 0 && date.getDate() < dob.getDate())) {
                    age--;
                }
                return age;
            },

            getOldestApplicantDob: function(applicants) {
                var minDate = Math.min.apply(null, applicants.map( function(applicant) {
                    return moment(applicant.dob, 'L').toDate();
                }));

                return new Date(minDate);
            },

            getAssumedStartDate: function() {
                var assumedStartDate = new Date();
                if (assumedStartDate.getMonth() == 11) {
                    return new Date(assumedStartDate.getFullYear() + 1, 0, 1);
                }

                return new Date(assumedStartDate.getFullYear(), assumedStartDate.getMonth() + 1, 1);
            },

            getPropertyValue: function(modellingData) {
                var propertyValue = modellingData.loanRequirement.purchasePrice;
                if (modellingData.loanRequirement.applicationType === enums.RDApplicationTypeCode.remortgage &&
                    modellingData.loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy) {
                    return modellingData.loanRequirement.currentEstimatedValue;
                }
                if (modellingData.loanRequirement.applicationType === enums.RDApplicationTypeCode.remortgage) {
                    propertyValue = modellingData.loanRequirement.currentEstimatedValue;
                }

                if(modellingData.loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.sharedOwnership
                    || modellingData.loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.rightToBuy
                    || modellingData.loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.equityShare
                    || modellingData.loanRequirement.propertyOwnership === enums.RDPropertyOwnershipTypeCode.purchaseFromFamilyMember)
                {
                    propertyValue = modellingData.loanRequirement.fullMarketValue;
                }

                return propertyValue;
            },

            resetTermToMortgageTermInMonths: function(modellingData) {
                return modellingData.loanRequirement.mortgageTermMonths;
            },

            mapValuationTypeToAssociatedFeeType: function(valuationTypes) {
                var feeTypes = [];
                valuationTypes.forEach(function (type) {
                    switch (type.value) {
                        case enums.RDValuationType.buyToLetValuation:
                            type.feeType = enums.RDFeeType.bTLValuation.toString();
                            break;
                        case enums.RDValuationType.mortgageValuationReport:
                            type.feeType = enums.RDFeeType.mortgageValuation.toString();
                            break;
                        case enums.RDValuationType.mortgageValuationReportWithBuildingSurvey:
                            type.feeType = enums.RDFeeType.buildingSurvey.toString();
                            break;
                        case enums.RDValuationType.mortgageValuationReportWithHomebuyersReport:
                            type.feeType = enums.RDFeeType.homebuyerSurvey.toString();
                            break;
                        case enums.RDValuationType.postOfferReinspection:
                            type.feeType = enums.RDFeeType.postOfferReinspection.toString();
                            break;
                        case enums.RDValuationType.preOfferReinspection:
                            type.feeType = enums.RDFeeType.preOfferReinspection.toString();
                            break;
                        case enums.RDValuationType.transcriptionOfScottishHomeReport:
                            type.feeType = enums.RDFeeType.transcription.toString();
                            break;
                        case enums.RDValuationType.upgradeToBuildingSurvey:
                            type.feeType = enums.RDFeeType.upgradeToBuildingSurvey.toString();
                            break;
                        case enums.RDValuationType.upgradeToHomebuyersReport:
                            type.feeType = enums.RDFeeType.upgradeToHomebuyersSurvey.toString();
                            break;
                        case enums.RDValuationType.buyToLetHomebuyersReport:
                            type.feeType = enums.RDFeeType.bTLHomebuyer.toString();
                            break;
                        case enums.RDValuationType.buyToLetBuildingsSurvey:
                            type.feeType = enums.RDFeeType.bTLBuildingSurvey.toString();
                            break;
                        case enums.RDValuationType.upgradeToBuyToLetHomebuyersReport:
                            type.feeType = enums.RDFeeType.upgradeToBTLHomebuyer.toString();
                            break;
                        case enums.RDValuationType.upgradeToBuyToLetBuildingsSurvey:
                            type.feeType = enums.RDFeeType.upgradeToBTLBuildingSurvey.toString();
                            break;
                        case enums.RDValuationType.externalValuationReport:
                            type.feeType = enums.RDFeeType.externalInspection.toString();
                            break;
                    }
                    if (type.feeType) {
                        feeTypes.push(type.feeType);
                    }
                });
                return feeTypes;
            },

            addFeeAmountToValuationDescription: function(valuationFees, availableValuationTypes) {
                availableValuationTypes.forEach(function(type) {
                    var match = false;
                    valuationFees.forEach(function (fee){
                        if(match){
                            return;
                        }
                        if(fee.type === parseInt(type.feeType)){

							type.caption += " (" + noDecimalPlaceCurrencyFilter(fee.amount);
							if (fee.isEstimated) {
								type.caption += ' estimated *';
							}
							type.caption += ")";

                            match=true;

                            type.amount = fee.amount;
                            type.isFeeEstimated = fee.isEstimated;
                            type.fee = { //for FMA
                                amount: fee.amount,
                                isFeeEstimated: fee.isEstimated
                            };
                            type.feeStatus = fee.feeStatus;
                            type.feeStatusFormatted = fee.feeStatusFormatted;

                        }
                    });
                });
            }
        };

        return service;

        function isValuationOrReinspectionFee (fee) {
            switch (fee.type) {
                case enums.RDFeeType.mortgageValuation:
                case enums.RDFeeType.buildingSurvey:
                case enums.RDFeeType.bTLValuation:
                case enums.RDFeeType.homebuyerSurvey:
                case enums.RDFeeType.postOfferReinspection:
                case enums.RDFeeType.preOfferReinspection:
                case enums.RDFeeType.transcription:
                case enums.RDFeeType.upgradeToBuildingSurvey:
                case enums.RDFeeType.upgradeToHomebuyersSurvey:
                case enums.RDFeeType.bTLHomebuyer:
                case enums.RDFeeType.bTLBuildingSurvey:
                case enums.RDFeeType.upgradeToBTLHomebuyer:
                case enums.RDFeeType.reinspection:
                    return true;
                default:
                    return false;
            }
        }

        function isReinspectionFee (fee) {
            switch (fee.type) {
                case enums.RDFeeType.postOfferReinspection:
                case enums.RDFeeType.preOfferReinspection:
                case enums.RDFeeType.reinspection:
                    return true;
                default:
                    return false;
            }
        }
        function mapFeeTypeToValuationType (feeType) {
            switch (feeType) {
                case enums.RDFeeType.postOfferReinspection:
                    return enums.RDValuationType.postOfferReinspection;
                case enums.RDFeeType.preOfferReinspection:
                    return enums.RDValuationType.preOfferReinspection;
            }
        }

        function isCaseInPcv(modellingData) {
            return modellingData.loanRequirement.hasPropertyChanged != undefined
                && modellingData.loanRequirement.hasPropertyChanged != null;
        }
    }]);
