// sets the focus on the element
// Use in html as follows:
// data-mso-autofocus
// or data-mso-autofocus="condition"

angular.module('mso.directives').directive('msoAutofocus', ['$timeout', function($timeout) {

    return function( scope, elem, attrs ) {
        var apply = true;
        if (attrs.msoAutofocus !== null && attrs.msoAutofocus !== undefined && attrs.msoAutofocus !== '') {
            apply = scope.$eval(attrs.msoAutofocus);
        }

        if (apply) {
            $timeout(function(){
                elem[0].focus();
            },0);
        }
    };
}]);