(function() { 'use strict';
  angular.module('mso.services')
    .factory('PagedResourceDataService', PagedResourceDataService);

  // Shared data service for returning paged resources, e.g. mortgage clubs, networks, firms and intermediaries
  PagedResourceDataService.$inject = ['$resource', 'Config', '$q'];
  function PagedResourceDataService($resource, Config, $q) {

      return {
          getPagedResources: getPagedResources
      };

      function getPagedResources(page, pageSize, searchCriteria, apiAddress) {
          var deferred = $q.defer();
          // set up the appropriate api using apiAddress of the resource required, e.g. "/mortgage-clubs"
          var api = $resource(Config.apiBaseHref + apiAddress, null);
          api.get({page: page, pageSize: pageSize, searchCriteria: searchCriteria}).$promise
              .then(function (data) {
                  deferred.resolve(data);
              },
              function (err) {
                  deferred.reject(err);
              });

          return deferred.promise;
      }

  }
})();
