
    angular.module('mso.authentication').controller('LoginCtrl', ['ReferenceDataService', 'LoginService', '$scope', '$window', '$exceptionHandler', 'Config', '$routeParams',

    function (ReferenceDataService, LoginService, $scope, $window, $exceptionHandler, Config, $routeParams) {

        ReferenceDataService.getReferenceDataForView('/').then(function (data) {
            $scope.messages = data.messages;
            $scope.parameters = data.parameters;
            $scope.registrationLink = (($scope.parameters.p6845 || '').toLowerCase() === "true") ?
                $scope.messages.msg5029 :
                'Register for ' + $scope.parameters.p6030 + ' Intermediary';
        }, function(error){
            $exceptionHandler(error);
        });

        $scope.user = {userName: '', password: ''};
        $scope.showValidation = false;
        $scope.transientStoreToken = $routeParams.token;

        delete $window.sessionStorage.user;
        delete $window.sessionStorage.token;
        delete $window.sessionStorage.lockToken;
        delete $window.sessionStorage.intermediary;
        delete $window.sessionStorage.advisorName;
        delete $window.sessionStorage.roles;
        delete $window.sessionStorage.username;

        delete $window.sessionStorage.channel;
        delete $window.sessionStorage.brand;
        delete $window.sessionStorage.sourcePortal;
        delete $window.sessionStorage.anchorDate;

        delete $window.sessionStorage.resourceActions;

        $scope.login = function () {
            if($scope.preventSubmit){
                return false;
            }

            if ($scope.form.$invalid) {
                $scope.showValidation = true;
            }
            else {
                $scope.preventSubmit = true;
                LoginService.login($scope.user).then(function (data) {
                    var decodedClaims = decodeClaims(data.token);
                    if (isUserAppropriateForApplication(decodedClaims.aud)) {
                        $window.sessionStorage.token = data.token;
                        $window.sessionStorage.roles = decodedClaims.role;
                        $window.sessionStorage.username = $scope.user.userName;
                        $window.sessionStorage.advisorName = decodedClaims.given_name + ' ' + decodedClaims.family_name;
                        $window.location.href = angular.isDefined($scope.transientStoreToken) ?
                        'case-home-ui/seed-case/'+ $scope.transientStoreToken :
                        'adviser-home-ui/';
                        return;
                    }
                    $scope.showAuthenticationFailed = true;
                }, function (error) {
                    if (error.status == '401') {
                        $scope.showAuthenticationFailed = true;
                        $scope.showPasswordForgotten = false;
                        $scope.forbiddenErrorMessage = null;
                    }
                    else if (error.status == '403') {
                        $scope.showAuthenticationFailed = false;
                        $scope.showPasswordForgotten = true;
                        $scope.forbiddenErrorMessage = error.data;
                    }
                    else {
                        $exceptionHandler(error);
                    }
                }).finally(function(){
                    $scope.preventSubmit = false;
                });
            }
        };

        function decodeClaims(token) {
            var refactoredToken = token.split('.')[1];
            var decodedString = decodeString(refactoredToken);
            return angular.fromJson(decodedString);
        }

        //Decoding the string to base 64 decodeURIComponent
        function decodeString(str){
            var strWithUriReplacements = str.replace(/-/g, '+').replace(/_/g, '/');   //convert from base64 to base64Url encoding
            var atobResult = atob(strWithUriReplacements);  //decode base64Url encoded string
            var splitResult = atobResult.split('');     //split string into character array

            //covert array into new array
            var mapResult = splitResult.map(function(c) {

                var charCode = c.charCodeAt(0);     //get character as decimal number
                var hexString = charCode.toString(16);      //convert to string of hex value
                var hexStringWithPadding = '00' + hexString;    //pad hex string
                var slice = hexStringWithPadding.slice(-2); //take last two characters

                return  '%' + slice;    //add percent encoding character
            });

            var stringFromMap = mapResult.join('');     //join character array back into string
            var decodedString = decodeURIComponent(stringFromMap);  //decode percent encoded hex characters

            return decodedString;

        }

        function isUserAppropriateForApplication(aud) {
            var isAuthorised = false;
            if (aud) {
                if (aud.constructor.isArray) {
                    angular.forEach(aud, function (audience) {
                        if (audience === Config.audience) {
                            isAuthorised = true;
                        }
                    });
                }
                else if (aud === Config.audience) {
                    isAuthorised = true;
                }
            }
            return isAuthorised;
        }

    }]);
