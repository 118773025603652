// Broadcasts a keydown event on the scope.  Use in html with data-mso-key-bind or
// data-mso-key-bind="{{data}}" where 'data' is some context data to pass to the event, e.g. an index

angular.module('mso.directives').directive('msoKeyBind', function() {

    return function( scope, elem, attrs ) {
        elem.bind('keydown', function( event ) {
            scope.$broadcast('keydown', { code: event.keyCode, data: attrs.msoKeyBind, event: event } );
        });
    };
});