(function () {
    'use strict';

    angular.module('mso.services')
        .factory('NavigationService', NavigationService);

    NavigationService.$inject = ['$location'];

    function NavigationService(){
        return {
            resolveUrl: resolveUrl
        };

        function resolveUrl(transitionName, caseId){
            var transitionToUrlMap = {
                "reenterdip": "dip/#/{caseId}/loan-requirements",
                "reenterps": "ps/#/{caseId}/product-selection",
                "reenterfma": "fma/#/{caseId}/applicant-contact-details/1",
                "reenterpresubmission": "pre-submission/#/make-payment/{caseId}",
                "reenterapplicationsubmission": "submission/#/submission-outcome/{caseId}",
                "dip": "dip/#/{caseId}/loan-requirements",
                "remodeldip": "dip/#/{caseId}/loan-requirements",
                "productselection": "ps/#/{caseId}/product-selection",
                "product-selection": "ps/#/{caseId}/product-selection",
                "fullmortgageapplication": "fma/#/{caseId}/applicant-contact-details/1",
                "fma": "fma/#/{caseId}/applicant-contact-details/1",
                "presubmission": "pre-submission/#/make-payment/{caseId}",
                "applicationsubmission": "submission/#/submission-outcome/{caseId}",
                "reenterrateswitch": "rate-switch/#!/{caseId}/summary",
                "backtodip": "dip/#/{caseId}/loan-requirements",
                "backtoproductselection": "ps/#/{caseId}/product-selection",
                "backtofma": "fma/#/{caseId}/applicant-contact-details/1"
            };

            var url = transitionToUrlMap[transitionName.toLocaleLowerCase()];

            if(angular.isUndefined(url)){
                console.log('Could not find a match for ' + transitionName + ' for case id ' + caseId + ' so redirected to case home.');
                //Force the user back to case-home if we cannot find a match for now
                url = 'case-home-ui/' + caseId + '/case-home';
            }
            url = url.replace('{caseId}', caseId);
            return url;
        }


    }
})();