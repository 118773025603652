(function() {
    'use strict';
    angular.module('mso.directives')
        .directive('searchBox', searchBox);

    function searchBox() {
        var directive = {
            restrict: 'EA',
            template: require('/app/template/search-box.template.html').default,
            controller: searchBoxController,
            scope: {
                searchables: '=',
                selected: '=',
                showValidation: '=',
                requiredDataMissing: '=',
                searchBoxTitle: '=',
                removeOnSelect: '=',
                searchBoxId: '='
            }
        };

        searchBoxController.$inject = ['$scope', 'filterFilter', '$timeout'];
        return directive;

        function searchBoxController($scope, filterFilter, $timeout) {
            if($scope.removeOnSelect === undefined) {
                $scope.removeOnSelect = false;
            }
            $scope.getSearchResults = getSearchResults;
            $scope.selectItem = selectItem;
            $scope.populateDropDown = populateDropDown;
            $scope.toggleOpenClose = toggleOpenClose;
            $scope.getPlaceHolder = getPlaceHolder;
            $scope.itemsAvailableToSelect = itemsAvailableToSelect;

            function populateDropDown() {
                $scope.dropDownItems = $scope.searchables.filter(function (s) {
                    return s.selected !== true
                });
            }

            function toggleOpenClose() {
                // Used for enabling keyboard access to opening the dropdown
                populateDropDown();
                $scope.isOpen = !$scope.isOpen;
            }

            function getPlaceHolder() {
                if ($scope.searchables == null || $scope.searchables == undefined) {
                    return "There are no items available";
                }

                if (itemsAvailableToSelect()) {
                    return "Search...";
                }
                else {
                    return "There are no more items available";
                }
            }

            function itemsAvailableToSelect() {
                if ($scope.searchables == null || $scope.searchables == undefined) {
                    return false;
                }
                return $scope.searchables.filter(function (s) {
                        return s.selected !== true
                    }).length > 0;
            }

            // Sorts items by their string name property
            function nameSorter(a, b) {
                return a.name.localeCompare(b.name);
            }

            function getSearchResults(searchValue) {
                // Filter the results by the search string passed in
                var results = filterFilter(angular.copy($scope.searchables), {name: searchValue});
                // Exclude any results where the item has its 'selected' property set to true
                return results.filter(function (r) {
                    return r.selected !== true
                });
            }

            function selectItem($item) {
                $scope.selected = $item;
                if($scope.removeOnSelect) {
                    $item.selected = true;
                    for (var i = 0; i < $scope.searchables.length; i++) {
                        if ($scope.searchables[i].name == $item.name) {
                            $scope.searchables[i].selected = true;
                        }
                    }
                }
                $scope.searchBoxForm.$setDirty();
                $scope.isOpen = false;
            }
        }
    }
})();
