'use strict';

angular.module('mso.directives')
    .directive('msoValidationToaster', ['$parse', '$timeout', function ($parse, $timeout) {
        return {
            restrict: 'A',
            scope: true,
            template: require('/app/template/mso-validation-message.html').default,
            link: function(scope, elem, attrs){
                var showValidationToaster = $parse(attrs.showValidationToaster);
                var formInvalid = $parse(attrs.attachedForm + '.$invalid');

                scope.inValidForm = scope.$parent[attrs.attachedForm];
                scope.showToaster = showValidationToaster;
                scope.positionClass = attrs.positionClass;

                scope.$parent.$watch(showValidationToaster, function(value) {
                    scope.showToaster = value;
                    if (value) {
                        $timeout(function(){
                            var anchor = angular.element(elem[0].querySelector('.anchorValidation'))[0];
                            if (anchor) {
                                anchor.focus();
                            }
                        }, 250);
                    }
                });
                scope.$parent.$watch(formInvalid, function(value){
                    scope.inValidForm = value;
                });

                scope.focusOnFirstError = function(){
                    scope.$parent[attrs.showValidationToaster] = false;
                    //get the form element
                    var formElement = angular.element(document.getElementsByName(attrs.attachedForm));
                    // find the first invalid element
                    var firstInvalid = angular.element(
                        formElement[0].querySelector(':not(data-ng-form).ng-invalid'))[0];
                    // if we find one, set focus
                    if (firstInvalid) {
                        firstInvalid.focus();
                    }
                    //If nothing is invalid must be cross field. Message will be displayed at the top of the screen.
                    else{
                        window.scrollTo(0,0);
                    }
                };
            }
        };
    }]);

