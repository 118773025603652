angular.module('mso.services').factory('SolicitorService', ['$resource', '$q', 'Config', function ($resource, $q, Config) {
    var SolicitorApi = $resource(Config.apiBaseHref + '/services/solicitor', {});

    return {
        searchSolicitor: function(companyName, town, postcode){
            var deferred = $q.defer();
            SolicitorApi.query({companyName:companyName, town:town, postcode:postcode})
                .$promise.then(function(data){
                    deferred.resolve(data);
            },
            function(error){
                deferred.reject(error)
            });

            return deferred.promise;
        }
    }
}]);