angular.module('mso.directives').directive('msoFormat', ['$filter', function ($filter) {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;

            ctrl.$formatters.unshift(function (a) {
                return $filter(attrs.format||'number')(ctrl.$modelValue);
            });
            ctrl.$parsers.unshift(function (viewValue) {
                if (!viewValue|| viewValue === '') return 0;

                var plainNumber = viewValue.replace(/[^\d|\.+]/g, '');
                if (plainNumber != "") {
                    elem.val($filter('number')(plainNumber));
                }
                return plainNumber;
            });

            elem.bind('keypress', function(event) {
                if(!acceptableInput(event, false)){
                    event.preventDefault();
                }
            });
        }
    };
}]);

angular.module('mso.directives').directive('msoFormatNumeric', function () {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;

            elem.bind('keypress', function(event) {
                if(!acceptableInput(event, false)){
                    event.preventDefault();
                }
            });
        }
    };
});

angular.module('mso.directives').directive('msoFormatSortcode', function(){
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;

            elem.bind('keypress', function(event) {

                var extraKeysToAccept;

                if (event.key) {
                    extraKeysToAccept = ["-"];
                }
                else {
                    // Safari doesn't support KeyboardEvent.key
                    extraKeysToAccept = [45];
                }

                if(!acceptableInput(event, extraKeysToAccept)){
                    event.preventDefault();
                }
            });
        }
    };
});

function acceptableInput(event, extraKeysToAccept){

    var currentKey;
    var acceptableKeys = [];

    if (event.key) {
        currentKey = event.key;

        // Add numbers into acceptable keys
        for (var i = 0; i <= 9; i++) {
            acceptableKeys.push(i.toString());
        }

        // Firefox raises events for these special keys, so include them as acceptable.
        acceptableKeys.push("Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete");
    }
    else {
        // Safari doesn't support KeyboardEvent.key so use the deprecated KeyboardEvent.keyCode
        currentKey = event.keyCode;

        // Add numbers ASCII codes into acceptable keys
        for (var j = 48; j <= 57; j++) {
            acceptableKeys.push(j);
        }

        // Note there is no need to include the special keys (Backspace, Tab, etc) as
        // Safari doesn't raise events for these.
    }

    if(extraKeysToAccept && extraKeysToAccept.length > 0){
        angular.forEach(extraKeysToAccept, function(character) {
            acceptableKeys.push(character);
        });
    }

    return matchKey(acceptableKeys, currentKey);
}

function matchKey(acceptableKeys, currentKey){
    return acceptableKeys.some(function(arrayKey){
        return arrayKey === currentKey;
    })
}

var INTEGER_REGEXP = /^\-?\d+$/;
angular.module('mso.directives').directive('msoRange', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            function validate(viewValue) {
                // ignore if value not set, required should pick that up instead
                if (angular.isUndefined(viewValue) || viewValue == null) {
                    ctrl.$setValidity('range', true);
                    return undefined;
                }
                if (INTEGER_REGEXP.test(viewValue)) {
                    // it is valid
                    var validity = true,
                        intValue = parseInt(viewValue);

                    if (angular.isDefined(attrs.min)) {
                        var min = parseInt(attrs.min);
                        if (intValue < min) {
                            validity = false;
                        }
                    }

                    if (angular.isDefined(attrs.max)) {
                        var max = parseInt(attrs.max);
                        if (intValue > max) {
                            validity = false;
                        }
                    }
                    ctrl.$setValidity('range', validity);
                    return viewValue;

                } else {
                    // it is invalid, return undefined (no model update)
                    ctrl.$setValidity('range', false);
                    return undefined;
                }
            }
            function apply_watch(watch) {
                //string - update all validators on expression change
                if (angular.isString(watch)) {
                    scope.$watch(watch, function () {
                        validate(ctrl.$modelValue);
                    });
                    return;
                }

                //array - update all validators on change of any expression
                if (angular.isArray(watch)) {
                    angular.forEach(watch, function (expression) {
                        scope.$watch(expression, function () {
                            validate(ctrl.$modelValue);
                        });
                    });
                }
            }

            if (attrs.msoRangeWatch) {
                apply_watch(scope.$eval(attrs.msoRangeWatch));
            }
            ctrl.$parsers.unshift(validate);
            ctrl.$formatters.unshift(validate);
        }
    };
});

'use strict';

/**
 * General-purpose validator for ngModel.
 * angular.js comes with several built-in validation mechanism for input fields (ngRequired, ngPattern etc.) but using
 * an arbitrary validation function requires creation of a custom formatters and / or parsers.
 * The ui-validate directive makes it easy to use any function(s) defined in scope as a validator function(s).
 * A validator function will trigger validation on both model and input changes.
 *
 * @example <input ui-validate=" 'myValidatorFunction($value)' ">
 * @example <input ui-validate="{ foo : '$value > anotherModel', bar : 'validateFoo($value)' }">
 * @example <input ui-validate="{ foo : '$value > anotherModel' }" ui-validate-watch=" 'anotherModel' ">
 * @example <input ui-validate="{ foo : '$value > anotherModel', bar : 'validateFoo($value)' }" ui-validate-watch=" { foo : 'anotherModel' } ">
 *
 * @param ui-validate {string|object literal} If strings is passed it should be a scope's function to be used as a validator.
 * If an object literal is passed a key denotes a validation error key while a value should be a validator function.
 * In both cases validator function should take a value to validate as its argument and should return true/false indicating a validation result.
 */
angular.module('mso.directives').directive('uiValidate', function () {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var validateFn, validators = {},
                validateExpr = scope.$eval(attrs.uiValidate);

            if (!validateExpr){ return;}

            if (angular.isString(validateExpr)) {
                validateExpr = { validator: validateExpr };
            }

            angular.forEach(validateExpr, function (exprssn, key) {
                validateFn = function (valueToValidate) {
                    var expression = scope.$eval(exprssn, { '$value' : valueToValidate });
                    if (angular.isObject(expression) && angular.isFunction(expression.then)) {
                        // expression is a promise
                        expression.then(function(){
                            ctrl.$setValidity(key, true);
                        }, function(){
                            ctrl.$setValidity(key, false);
                        });
                        return valueToValidate;
                    } else if (expression) {
                        // expression is true
                        ctrl.$setValidity(key, true);
                        return valueToValidate;
                    } else {
                        // expression is false
                        ctrl.$setValidity(key, false);
                        return valueToValidate;
                    }
                };
                validators[key] = validateFn;
                ctrl.$formatters.push(validateFn);
                ctrl.$parsers.push(validateFn);
            });

            function apply_watch(watch)
            {
                //string - update all validators on expression change
                if (angular.isString(watch))
                {
                    scope.$watch(watch, function(){
                        angular.forEach(validators, function(validatorFn){
                            validatorFn(ctrl.$modelValue);
                        });
                    });
                    return;
                }

                //array - update all validators on change of any expression
                if (angular.isArray(watch))
                {
                    angular.forEach(watch, function(expression){
                        scope.$watch(expression, function()
                        {
                            angular.forEach(validators, function(validatorFn){
                                validatorFn(ctrl.$modelValue);
                            });
                        });
                    });
                    return;
                }

                //object - update appropriate validator
                if (angular.isObject(watch))
                {
                    angular.forEach(watch, function(expression, validatorKey)
                    {
                        //value is string - look after one expression
                        if (angular.isString(expression))
                        {
                            scope.$watch(expression, function(){
                                validators[validatorKey](ctrl.$modelValue);
                            });
                        }

                        //value is array - look after all expressions in array
                        if (angular.isArray(expression))
                        {
                            angular.forEach(expression, function(intExpression)
                            {
                                scope.$watch(intExpression, function(){
                                    validators[validatorKey](ctrl.$modelValue);
                                });
                            });
                        }
                    });
                }
            }
            // Support for ui-validate-watch
            if (attrs.uiValidateWatch){
                apply_watch( scope.$eval(attrs.uiValidateWatch) );
            }
        }
    };
});
