angular.
module('mso').factory('ExceptionService',
    ['toaster', 'SiteConfig', 'ReferenceDataService',
        function (toaster, SiteConfig, ReferenceDataService) {

            "use strict";

            return new Service();

            function Service() {

                var self = this;
                var messageHeader = 'Error';
                var messageText = '';

                // List of status codes for current visible toasters
                self.toasterStatusDisplayList = [];

                self.displayError = function displayError(status, identifier, retryDate, additionalMessageText) {

                    if (self.toasterStatusDisplayList.indexOf(status) < 0) {
                        messageText = '';
                        buildMessageToBeDisplayed(identifier, retryDate, additionalMessageText);

                        toaster.pop({
                            showCloseButton: true,
                            closeHtml: "<button class='i-icon-button toast-close-button'><span class='sr-only'>Close</span><i class='fa fa-times fa-lg'></i></button>",
                            timeout: 0,
                            type: 'error',
                            title: messageHeader,
                            body: messageText,
                            onHideCallback: function () {
                                var toaster = this;
                                if (self.onToasterClose) {
                                    self.onToasterClose(status);
                                }
                            },
                            bodyOutputType: 'trustedHtml'
                        });

                        self.toasterStatusDisplayList.push(status);
                    }
                };

                self.onToasterClose = function onToasterClose(status) {

                    var i = self.toasterStatusDisplayList.indexOf(status);

                    // Don't display a new toaster if there is already one displayed for the same http status code
                    if (i >= 0) {
                        self.toasterStatusDisplayList.splice(i, 1);
                    }
                };

                function buildMessageToBeDisplayed(identifier, retryDate, additionalMessageText) {

                    /*
                     Build up the message to be displayed, depending on the type of error encountered
                     */
                    var useOperationalFailureMessage = false;
                    if (ReferenceDataService) {
                        try {
                            var generalMessage = ReferenceDataService.resolveMessageText('msg3012');
                            var retryWithIdentifierMessage = ReferenceDataService.resolveMessageText('msg3013');
                            var retryWithoutIdentifierMessage = ReferenceDataService.resolveMessageText('msg3017');
                            var contactHelplineWithIdentifierMessage = ReferenceDataService.resolveMessageText('msg3015');
                            var operationalHelplineWithIdentifierMessage = ReferenceDataService.resolveMessageText('msg3016');
                            var contactHelplineWithoutIdentifierMessage = ReferenceDataService.resolveMessageText('msg3019');
                            var operationalHelplineWithoutIdentifierMessage = ReferenceDataService.resolveMessageText('msg3020');
                            var telephoneNumberParameter = 'P6048';
                            var telephoneNumberParameterTag = '{' + telephoneNumberParameter + '}';
                            var helplineTelephoneNumber = ReferenceDataService.resolveParameterValue(telephoneNumberParameter);
                            var operationalTelephoneNumberParameter = 'P6110';
                            var operationalTelephoneNumberParameterTag = '{' + operationalTelephoneNumberParameter + '}';
                            var operationalTelephoneNumber = ReferenceDataService.resolveParameterValue(operationalTelephoneNumberParameter);

                            if (helplineTelephoneNumber && helplineTelephoneNumber.length > 0) {

                                //Line 1 - General message to the user
                                if (generalMessage && generalMessage.length > 0) {

                                    if (messageText.length > 0) {
                                        messageText = messageText + '<br/>';
                                    }

                                    messageText = messageText + generalMessage;
                                }

                                //Line 2a - Retry with contact helpline message to the user - where possible
                                if (retryDate && retryDate.length > 0 &&
                                    retryWithIdentifierMessage && retryWithIdentifierMessage.length > 0 &&
                                    retryWithoutIdentifierMessage && retryWithoutIdentifierMessage.length > 0) {

                                    if (messageText.length > 0) {
                                        messageText = messageText + '<br/>';
                                    }

                                    if (identifier.length > 0) {

                                        messageText = messageText + retryWithIdentifierMessage.
                                            replace('$0', retryDate).
                                            replace(telephoneNumberParameterTag, helplineTelephoneNumber).
                                            replace('$2', identifier);
                                    }
                                    else {
                                        messageText = messageText + retryWithoutIdentifierMessage.
                                            replace('$0', retryDate).
                                            replace(telephoneNumberParameterTag, helplineTelephoneNumber);
                                    }
                                }

                                //Line 2b - Contact helpline message to the user - where possible
                                else if (contactHelplineWithIdentifierMessage && contactHelplineWithIdentifierMessage.length > 0 &&
                                    contactHelplineWithoutIdentifierMessage && contactHelplineWithoutIdentifierMessage.length > 0) {

                                    if (messageText.length > 0) {
                                        messageText = messageText + '<br/>';
                                    }

                                    if (identifier.length > 0) {
                                        messageText = messageText + contactHelplineWithIdentifierMessage.
                                            replace(telephoneNumberParameterTag, helplineTelephoneNumber).
                                            replace('$1', identifier);
                                    }
                                    else {
                                        messageText = messageText + contactHelplineWithoutIdentifierMessage.
                                            replace(telephoneNumberParameterTag, helplineTelephoneNumber);
                                    }
                                }

                                //Line 3 - Additional message - only appears when we have encountered an unexpected error i.e. not a 500/503
                                if (additionalMessageText && additionalMessageText.length > 0) {

                                    if (messageText.length > 0) {
                                        messageText = messageText + '<br/>';
                                    }

                                    messageText = messageText + htmlEntities(additionalMessageText);
                                }
                            }

                            if (messageText.length === 0) {
                                if (operationalTelephoneNumber.length > 0)
                                {
                                    messageText = generalMessage + '<br/>';
                                    if (identifier) {
                                        messageText += operationalHelplineWithIdentifierMessage.replace(operationalTelephoneNumberParameterTag, operationalTelephoneNumber);  
                                        messageText = messageText.replace('$1', identifier);
                                    }
                                    else {
                                        messageText += operationalHelplineWithoutIdentifierMessage.replace(operationalTelephoneNumberParameterTag, operationalTelephoneNumber);  
                                    }
                                }
                                if (messageText.length === 0 ) {
                                    useOperationalFailureMessage = true;
                                }
                            }
                        }
                        catch (e) {
                            useOperationalFailureMessage = true;
                        }
                    }
                    else {
                        useOperationalFailureMessage = true;
                    }
                    
                    if (useOperationalFailureMessage) {
                        if (identifier) {
                            messageText = SiteConfig.operationalFailureWithIdentifierMessage.replace('$1', identifier);
                        }
                        else {
                            messageText = SiteConfig.operationalFailureMessage;
                        }
                    }                       
                }

                function htmlEntities(str) {
                    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
                }
            }
        }
    ]);