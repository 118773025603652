'use strict';

angular.module('mso', ['mso.templates']);
angular.module('mso.templates', []);
angular.module('mso.config', []).constant('SiteConfig', {
    channel: 'Intermediary',
    operationalFailureMessage:'An error has occurred with the link to an external system or within MSO.<br/>Please contact our customer support helpline if the issue persists.',
    operationalFailureWithIdentifierMessage:'An error has occurred with the link to an external system or within MSO.<br/>Please contact our customer support helpline quoting $1 if the issue persists.',
    suppressUnexpectedAPIErrors: true,
    suppressUnexpectedErrors: true,
    moduleTransitionMaxAttempts: 10
});
angular.module('mso.interceptors', ['mso.config']);
angular.module('mso.session', []);
angular.module('mso.directives', []);
angular.module('mso.services', ['mso.config']);
angular.module('mso.cm.directives', []);
angular.module('mso.dip.directives', []);
angular.module('mso.ps.directives', []);
angular.module('mso.fma.directives', []);
angular.module('mso.pre-sub.directives', []);
angular.module('mso.pm.directives', []);
angular.module('mso.registration', []);
angular.module('mso.registration.directives', []);
angular.module('authentication.modules', []);
angular.module('mso.authentication', [
    'ngResource',
    'ngRoute',
    'ngAnimate',
    'authentication.modules',
    'mso',
    'mso.templates',
    'mso.config',
    'mso.directives',
    'mso.services',
    'mso.session',
    'toaster',
    'ui.bootstrap'
]);
angular.module('dip.views', []);
angular.module('dip.template', []);
angular.module('mso.dip', [
  'ngResource',
  'ngSanitize',
  'ngRoute',
  'ngAnimate',
    'dip.template',
    'dip.views',
  'mso',
  'mso.templates',
  'mso.services',
  'mso.session',
  'mso.directives',
  'mso.dip.directives',
  'toaster',
  'ui.bootstrap',
  'ngFileUpload',
  'ui.bootstrap',
  'ngLocalize',
  'ngLocalize.Config',
  'mso.interceptors'
]);
angular.module('cm.views', []);
angular.module('cm.template', []);
angular.module('mso.cm', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'cm.template',
    'cm.views',
    'mso',
    'mso.templates',
    'mso.directives',
    'mso.services',
    'mso.session',
    'mso.cm.directives',
    'toaster',
    'ui.bootstrap',
    'ngFileUpload',
    'tc.chartjs',
    'mso.authentication',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('ps.views', []);
angular.module('ps.template', []);
angular.module('mso.ps', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'ps.template',
    'ps.views',
    'mso',
    'mso.templates',
    'mso.config',
    'mso.services',
    'mso.session',
    'mso.directives',
    'mso.ps.directives',
    'toaster',
    'ui.bootstrap',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('fma.views', []);
angular.module('fma.template', []);
angular.module('mso.fma', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'fma.template',
    'fma.views',
    'mso',
    'mso.templates',
    'mso.directives',
    'mso.services',
    'mso.session',
    'mso.fma.directives',
    'toaster',
    'ui.bootstrap',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('pm.views', []);
angular.module('pm.template', []);
angular.module('mso.pm', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'pm.template',
    'pm.views',
    'mso',
    'mso.templates',
    'mso.directives',
    'mso.services',
    'mso.session',
    'mso.pm.directives',
    'toaster',
    'ui.bootstrap',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('registration.modules', []);
angular.module('mso.registration', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'registration.modules',
    'mso',
    'mso.templates',
    'mso.services',
    'mso.session',
    'mso.directives',
    'mso.registration.directives',
    'toaster',
    'ui.bootstrap',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('pre-submission.modules', []);
angular.module('mso.preSubmission', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'pre-submission.modules',
    'mso',
    'mso.templates',
    'mso.services',
    'mso.session',
    'mso.directives',
    'mso.pre-sub.directives',
    'toaster',
    'ui.bootstrap',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('submission.modules', []);
angular.module('mso.submission', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'submission.modules',
    'mso',
    'mso.templates',
    'mso.services',
    'mso.session',
    'mso.directives',
    'toaster',
    'ngFileUpload',
    'ui.bootstrap',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('self-management.modules', []);
angular.module('mso.ism', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'self-management.modules',
    'mso',
    'mso.templates',
    'mso.services',
    'mso.session',
    'mso.directives',
    'toaster',
    'ui.bootstrap',
    'ngLocalize',
    'ngLocalize.Config',
    'mso.interceptors'
]);
angular.module('set-password.modules', []);
angular.module('mso.setpassword', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngAnimate',
    'set-password.modules',
    'mso',
    'mso.templates',
    'mso.services',
    'mso.session',
    'mso.directives',
    'toaster',
    'ui.bootstrap',
    'mso.interceptors'
]);

angular.module('mso', [])
    .config(['$provide', function( $provide )
    {
        $provide.decorator( '$log', ['$delegate', function( $delegate )
        {
            // Save the original $log.debug()
            var debugFn = $delegate.debug;

            $delegate.debug = function( )
            {
                var args    = [].slice.call(arguments),
                    now     = moment().format();

                // Prepend timestamp
                args[0] = "[" + now + "]" + " - " + args[0];

                // Call the original with the output prepended with formatted timestamp
                debugFn.apply(null, args)
            };

            var logFn = $delegate.log;

            $delegate.log = function( )
            {
                var args    = [].slice.call(arguments),
                    now     = moment().format();

                // Prepend timestamp
                args[0] = "[" + now + "]" + " - " + args[0];

                // Call the original with the output prepended with formatted timestamp
                logFn.apply(null, args)
            };

            var infoFn = $delegate.info;

            $delegate.info = function( )
            {
                var args    = [].slice.call(arguments),
                    now     = moment().format();

                // Prepend timestamp
                args[0] = "[" + now + "]" + " - " + args[0];

                // Call the original with the output prepended with formatted timestamp
                infoFn.apply(null, args)
            };

            var warnFn = $delegate.warn;

            $delegate.warn = function( )
            {
                var args    = [].slice.call(arguments),
                    now     = moment().format();

                // Prepend timestamp
                args[0] = "[" + now + "]" + " - " + args[0];

                // Call the original with the output prepended with formatted timestamp
                warnFn.apply(null, args)
            };

            var errFn = $delegate.error;

            $delegate.error = function( )
            {
                var args    = [].slice.call(arguments),
                    now     = moment().format();

                // Prepend timestamp
                args[0] = "[" + now + "]" + " - " + args[0];

                // Call the original with the output prepended with formatted timestamp
                errFn.apply(null, args)
            };





            return $delegate;
        }]);
    }]);

angular.module('mso').constant('CoreConfig', {
    spmApiBaseHref: '/salesprocessmanager/sales-process-manager/v1/api/',
    channel: 'Intermediary'
});
