
    angular.module('mso.authentication')
        .config(['$routeProvider', function ($routeProvider) {
        var routeResolvers = {
            user: {
                referenceData: ["$route", "ReferenceDataService", function ($route, ReferenceDataService) {
                    var currentView = $route.current.$$route.originalPath.replace(/\//, '');
                    return ReferenceDataService.getReferenceDataForView(currentView);
                }]
            },
            admin: {
                auth: function () {
                    return true;
                }
            }
        };

        $routeProvider
            .when('/', {
                template: require('./pages/login/login.html').default,
                controller: 'LoginCtrl',
                controllerAs: 'vm',
                resolve: routeResolvers.user
            })
            .when('/forgotten-user-name', {
                template: require('./pages/forgotten-user-name/forgotten-user-name.html').default,
                controller: 'ForgottenUserNameCtrl',
                controllerAs: 'vm',
                resolve: routeResolvers.user
            })
            .when('/forgotten-password', {
                template: require('./pages/forgotten-password/forgotten-password.html').default,
                controller: 'ForgottenPasswordCtrl',
                controllerAs: 'vm',
                resolve: routeResolvers.user
            })
            .when('/timeout', {
                template: require('./pages/timeout/timeout.html').default,
                resolve: routeResolvers.user
            })
            .when('/:token', {
                template: require('./pages/login/login.html').default,
                controller: 'LoginCtrl',
                controllerAs: 'vm',
                resolve: routeResolvers.user
            })
            .otherwise({
                redirectTo: '/'
            });
            }])
            .run(['$rootScope', function($rootScope){
                $rootScope.$on('$routeChangeError', function(event, current, previous, rejection){
                });
            }]);

angular.module('mso.authentication').constant('Config', {
    debug: true,
    apiBaseHref : '/authentication/api/msov1',
    umApiBaseHref : '/um-self-management/api/msov1',
    audience: 'http://mso.intermediary'
});
