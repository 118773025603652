// Registers a directive for input controls which ensures that if they receive focus while hidden under the header bar,
// then they will get scrolled into view
(function() {
    'use strict';
    angular.module('mso').directive('input', controlDirective);
    angular.module('mso').directive('a', controlDirective);
    angular.module('mso').directive('button', controlDirective);
    angular.module('mso').directive('select', controlDirective);

    function controlDirective() {
       return  {
            restrict: 'E',
            link: scrollLinkFunction
        };
    }

    function scrollLinkFunction(scope, el, attr) {
        var node = el[0];
        var withinHeader = false;
        while(true){
            node = node.parentNode;
            if(node === null){
                break;
            }
            if (node.id === 'ms-nav' || node.id === 'mso-context-nav') {
                withinHeader = true;
                break;
            }
        }
        if (!withinHeader) {
            el.bind('focus', function () {
                var msNav = document.getElementById('ms-nav');
                var contextNav = document.getElementById('mso-context-nav');
                var combinedHeaderHeight = (msNav ? msNav.clientHeight : 0) + (contextNav ? contextNav.clientHeight : 0);
                var elementOffset = el[0].getBoundingClientRect().top + window.pageYOffset - document.documentElement.clientTop;

                if (elementOffset - window.pageYOffset < combinedHeaderHeight) {
                    window.scrollTo(0, window.pageYOffset - combinedHeaderHeight - 15);
                }
            });
        }
    }
})();