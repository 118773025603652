
    angular.module('mso.authentication').factory('ForgottenUserNameService', ['$q', '$resource', 'Config',

    function ($q, $resource, Config) {
        var forgottenUserNameApi = $resource(Config.umApiBaseHref + "/forgotten-username");
        return {
            forgottenUserName: forgottenUserName
        };

        function forgottenUserName(emailAddress) {
            var deferred = $q.defer();

            forgottenUserNameApi.save('"' + emailAddress + '"').$promise.then(function (data) {
                deferred.resolve(data);
            }, function (err) {
                deferred.reject(err);
            });
            return deferred.promise;
        };

    }]);