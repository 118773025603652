angular.module('mso.directives')
    .directive('msoAdviceAndFeesPanel', function () {
        return {
            restrict: 'EA',
            controller: 'AdviceAndFeesController',
            template: require('/app/template/mso-advice-and-fees-panel.html').default,
            scope: {
                viewModel: '=',
                form: '='
            }
        }
    }
)
    .controller('AdviceAndFeesController', ['$scope', 'ReferenceDataService', 'noDecimalPlaceCurrencyFilter', 'enums'
        , function ($scope, ReferenceDataService, noDecimalPlaceCurrencyFilter, enums) {
            $scope.formMode = "Add";

            $scope.prepareDisplay = function () {
                $scope.display = {};
                $scope.display.levelOfService = ReferenceDataService.resolveValuesetValue($scope.viewModel.levelOfService, "rdLevelOfService");

                $scope.display.showValuationDetails = $scope.viewModel.captureValuationType;
                $scope.display.valuationType = ReferenceDataService.resolveValuesetValue($scope.viewModel.valuationType, "rdValuationType");

                $scope.display.Fee = $scope.viewModel.feeChargedToApplicant;

                if ($scope.display.Fee === 0) {
                    $scope.display.feeText = "No fee"
                }
                else {
                    switch ($scope.viewModel.isFeeRefundable) {
                        case 1:
                            $scope.display.feeText = "A non-refundable fee of " + noDecimalPlaceCurrencyFilter($scope.viewModel.feeChargedToApplicant) + " will be made payable to {0}."
                            break;
                        case 2:
                            $scope.display.feeText = "A refundable fee of " + noDecimalPlaceCurrencyFilter($scope.viewModel.feeChargedToApplicant) + " will be made payable to {0}."
                                + " The fee is to be paid " + ReferenceDataService.resolveValuesetValue($scope.viewModel.feePayableWhen, "rdFeePayableWhen").toLowerCase() + "."
                                + " A refund of " + noDecimalPlaceCurrencyFilter($scope.viewModel.refundableAmount) + " could be made.";
                            break;
                        case 3:
                            $scope.display.feeText = "A refundable fee of " + noDecimalPlaceCurrencyFilter($scope.viewModel.feeChargedToApplicant) + " will be made payable to {0}."
                                + " The fee is to be paid " + ReferenceDataService.resolveValuesetValue($scope.viewModel.feePayableWhen, "rdFeePayableWhen").toLowerCase() + "."
                                + " A refund of " + $scope.viewModel.refundablePercentage + "% could be made.";
                            break;
                    }
                    $scope.display.feeText = $scope.display.feeText.replace("{0}", $scope.viewModel.feePayableTo);
                }
            };

            $scope.toggleEditAdviceDetails = function(){
                $scope.adviceDetailsCaptured = false;

            };

            $scope.viewModel.valuationTypeIsValid = false;
            angular.forEach($scope.viewModel.rdValuationType, function(valuationType){
                if(valuationType.value === $scope.viewModel.valuationType) {
                    $scope.viewModel.valuationTypeIsValid = true;
                }
            });

            if($scope.viewModel.levelOfService){
                if($scope.viewModel.feeChargedToApplicant !== null && $scope.viewModel.feeChargedToApplicant !== undefined){
                    if($scope.viewModel.feeChargedToApplicant === 0 ||
                        $scope.viewModel.feePayableTo &&
                        $scope.viewModel.isFeeRefundable &&
                        ($scope.viewModel.isFeeRefundable === 1 ||
                        ($scope.viewModel.isFeeRefundable === 2 && $scope.viewModel.refundableAmount) ||
                        ($scope.viewModel.isFeeRefundable === 3 && $scope.viewModel.refundablePercentage))){
                        if($scope.viewModel.feePayableWhen) {
                            if (!$scope.viewModel.captureValuationType || ($scope.viewModel.captureValuationType && $scope.viewModel.valuationTypeIsValid)) {
                                $scope.adviceDetailsCaptured = true;
                                $scope.prepareDisplay();
                            }
                        }
                    }
                }
            }

            $scope.levelOfServiceOptions =  filterLevelOfServiceOptions();
            function filterLevelOfServiceOptions() {
                if (!$scope.viewModel.parameters.iP0001 || $scope.viewModel.parameters.iP0001 === '2.6') {
                    return $scope.viewModel.vs.rdLevelOfService;
                }
                else {
                    if (parseInt($scope.viewModel.propertyPurpose) === enums.RDPropertyPurposeTypeCode.buyToLet)
                 {
                        return  $scope.viewModel.vs.rdLevelOfService.filter(function (v) {
                            return v.value != enums.RDLevelOfService.executionOnlyRejectedAdvice &&
                                v.value != enums.RDLevelOfService.executionOnlyHNWCustomer &&
                                v.value != enums.RDLevelOfService.executionOnlyMortgageProfessional &&
                                v.value != enums.RDLevelOfService.executionOnlyNonInteractiveSale
                        });
                    }
                    else {
                        return $scope.viewModel.vs.rdLevelOfService.filter(function (v) {
                            return v.value != enums.RDLevelOfService.noAdviceGiven;
                        });
                    }
                }
            };

        }
    ]);
