angular.module('mso.services').factory('AccountValidationService', ['$resource', '$q', 'Config', function ($resource, $q, Config) {
    var AccountValidationApi = $resource(Config.apiBaseHref + '/services/bankaccount', {});

    return {
        validateAccount: function (sortCode, accountNumber) {
            var deferred = $q.defer();
            AccountValidationApi.get({ sortCode: sortCode, accountNumber: accountNumber, isArray: false}).$promise.then(function (data) {

                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }
    };
}]);