angular.module('mso.directives')
    .directive("msoLoadingSpinner", function () {
        'use strict';
        return {
            restrict: 'EA',
            template: '<div class="loadingDiv"><div class="ajax-loader"/><div class="ajax-loader2"/><div class="loader-text"/></div>',
            link: function ($scope, element, attrs) {
                $scope.$on("show_loading", function () {
                    return element.removeClass('hide');
                });
                $scope.$on("hide_loading", function () {
                    return element.addClass('hide');
                });
            }

        };
    });


