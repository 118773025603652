// Provides a non-visible (sr-only) form submit button which invokes the footerNav:click 'next' link for accessibility purposes
(function() {
    'use strict';
    angular.module('mso.directives')
        .directive('msoAccessibleSubmit', msoAccessibleSubmit);

    function msoAccessibleSubmit() {
        var directive = {
            restrict: 'E',
            controller: msoAccessibleSubmitCtrl,
            template : '<div class="sr-only"><button type="submit" tabindex="-1" data-ng-click="navigateNext()" id="mso-accessible-submit-button">submit</button></div>'
        };

        msoAccessibleSubmitCtrl.$inject = ['$scope', '$rootScope'];
        return directive;

        function msoAccessibleSubmitCtrl($scope, $rootScope) {

            $scope.navigateNext = navigateNext;

            function navigateNext() {
                // Check if the button was actually invoked, i.e. prevent 'enter' key submission from other parts of the form
                if (document.activeElement.id === 'mso-accessible-submit-button') {
                    if ($scope.$parent && $scope.$parent.next) {
                        $rootScope.$broadcast('footerNav:click', [$scope.$parent.next]);
                    }
                }
            }
        }
    }
})();