angular.module('mso.session').factory('activityInterceptor', ['$injector', function ($injector) {
    var minimumSecondsBetweenInterceptChecks = 0.1;
    var lastIntercepted;

    return {
        request: function (config) {
            var now = Date.now();

            // For performance reasons - only record activity a maximum of once per tenth of a second.
            if (typeof lastIntercepted === 'undefined' || now - lastIntercepted > minimumSecondsBetweenInterceptChecks * 1000) {
                if (config.url) {
                    lastIntercepted = Date.now();
                    var SessionExpiryService = $injector.get('SessionExpiryService');
                    SessionExpiryService.activity(config.url);
                }
            }

            return config;
        }
    };
}]).config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('activityInterceptor');
}]);

