'use strict';
angular.module('mso.directives').filter('sterling', function(){
  return function(data, comma){
      // use regex to add a ',' every 3 chars - http://stackoverflow.com/a/2901298
      if (comma) { data = data.replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
      return ('£' + data);
  }
});

angular.module('mso.directives').filter('range', function() {
    return function(input, min, max) {
        min = parseInt(min);
        max = parseInt(max);
        for (var i=min; i<=max; i++)
            input.push(i);
        return input;
    };
});

angular.module('mso.directives').filter('shortMonth', function(){
    return function(data){
        switch(data)
        {
            case 1:
            case '1':
                return 'Jan';
            case 2:
            case '2':
                return 'Feb';
            case 3:
            case '3':
                return 'Mar';
            case 4:
            case '4':
                return 'Apr';
            case 5:
            case '5':
                return 'May';
            case 6:
            case '6':
                return 'Jun';
            case 7:
            case '7':
                return 'Jul';
            case 8:
            case '8':
                return 'Aug';
            case 9:
            case '9':
                return 'Sept';
            case 10:
            case '10':
                return 'Oct';
            case 11:
            case '11':
                return 'Nov';
            case 12:
            case '12':
                return 'Dec';
        }

        return data;
    }
});
angular.module('mso.directives').filter('applicantName', function(){
    return function(applicantName){
        return (applicantName.forename + ' ' + applicantName.surname);
    }
});
angular.module('mso.directives').filter('yearsMonths', function(){
    return function(months, includeMonthsIfZero){
        var displayYears = (months >= 0 ) ? Math.floor(months / 12) : Math.ceil(months / 12);
        var displayMonths = (months >= 0 ) ? Math.floor(months % 12) : Math.ceil(months % 12);


        var formattedValue = displayYears + ((displayYears != 1) ? " years" : " year");
        if(displayMonths >0 || includeMonthsIfZero || displayYears ==0 ) {
        formattedValue += " " + displayMonths + ((displayMonths != 1) ? " months" : " month");
        }

        return formattedValue;
    }
});

angular.module('mso.directives').filter('valueSet',['ReferenceDataService', function(ReferenceDataService){
    var refDataService = ReferenceDataService;
    return function(value, valueSetName, defaultValue){

if(!valueSetName || valueSetName.length==0)
{
    return defaultValue || "unknown";
}
        var returnValue =  refDataService.resolveValuesetValue(value, valueSetName);
        if(returnValue == "#Unknown#") //until the directive use ends
        {
            returnValue = null;
        }
        return returnValue || defaultValue || "unknown";



    }
}]);

angular.module('mso.directives').filter('applySourceIndex', function () {
    return function (array, sourceindex) {
        if (!sourceindex)
            sourceindex = 'sourceIndex';
        for (var i = 0; i < array.length; ++i) {
            array[i][sourceindex] = i;
        }
        return array;
    };
});

angular.module('mso.directives').filter('filterWhiteSpace', function(){
    return function (value){
        return value.replace(/\s/g, '');
    }
});

/**
 * A replacement utility for internationalization very similar to sprintf.
 *
 * @param replace {mixed} The tokens to replace depends on type
 *  string: all instances of $0 will be replaced
 *  array: each instance of $0, $1, $2 etc. will be placed with each array item in corresponding order
 *  object: all attributes will be iterated through, with :key being replaced with its corresponding value
 * @return string
 *
 * @example: 'Hello :name, how are you :day'.format({ name:'John', day:'Today' })
 * @example: 'Records $0 to $1 out of $2 total'.format(['10', '20', '3000'])
 * @example: '$0 agrees to all mentions $0 makes in the event that $0 hits a tree while $0 is driving drunk'.format('Bob')
 */
angular.module('mso.directives').filter('format', ['$filter', function($filter){
    return function(value, replace) {
        var target = value;
        if (angular.isString(target) && replace !== undefined) {
            if (!angular.isArray(replace) && !angular.isObject(replace)) {
                replace = [replace];
            }
            if (angular.isArray(replace)) {
                var rlen = replace.length;
                var rfx = function (str, i) {
                    i = parseInt(i, 10);
                    return (i>=0 && i<rlen) ? replace[i] : str;
                };
                target = target.replace(/\$([0-9]+)/g, rfx);
            }
            else {
                if(replace.filterName)
                {
                    var filterName = replace.filterName;
                    delete replace.filterName;
                    angular.forEach(replace, function(value, key){
                        target = target.split(key.toUpperCase()).join($filter(filterName)(value));
                    });
                } else {
                    angular.forEach(replace, function(value, key){
                        target = target.split('{'+key.toUpperCase()+'}').join(value);
                    });
                }
            }
        }
        return target;
    };
}]);

//Reverse the contents of an array
angular.module('mso.directives').filter('reverseArray', function() {
    return function(array){
        if (array) {
            return array.slice().reverse();
        }
    }
});

//Boldens the text
angular.module('mso.directives').filter('strong', function() {
    return function(value){
        return '<strong>' + value + '</strong>';
    }
});

//Sets to locale currency & no decimal places
angular.module('mso.directives').filter('noDecimalPlaceCurrency',
  ['$filter', '$locale',
  function (filter, locale) {
      var currencyFilter = filter('currency');
      var formats = locale.NUMBER_FORMATS;
      return function (amount, currencySymbol) {
          if(angular.isUndefined(amount)){
              return amount;
          }
          var value = currencyFilter(amount, currencySymbol);
          var sep = value.indexOf(formats.DECIMAL_SEP);
          if (amount >= 0) {
              return value.substring(0, sep);
          }
          return value.substring(0, sep) + ')';
      };
  }]);

angular.module('mso').filter('shortDate', function() {
    return function(date) {
        return moment(date).format("DD/MM/YY HH:mm");
    }
});

angular.module('mso').filter('diaryDate', function() {
    return function(date) {
        return moment(date, "DD/MM/YYYY").format("Do MMM YYYY");
    }
});

angular.module('mso')    .filter('numberFixedLen', function () {
    return function (n, len) {
        var num = parseInt(n, 10);
        len = parseInt(len, 10);
        if (isNaN(num) || isNaN(len)) {
            return n;
        }
        num = ''+num;
        while (num.length < len) {
            num = '0'+num;
        }
        return num;
    };
});

// FF0021 Convert Decimal to Percentage
// Defaults to 2 decimal places
angular.module('mso').filter('decimalToPercentage', function(){
    return function(input, decimalPlaces){
        var float = parseFloat(input);
        decimalPlaces = decimalPlaces === parseInt(decimalPlaces, 10) ? decimalPlaces : 2;
        return isNaN(float) ? input : (float * 100).toFixed(decimalPlaces);
    };
});

angular.module('mso.directives').filter('msoJoin', function () {
    // coordinatingConjunction e.g. 'and' 'or'
    return function (arrayData, separator, serialComma, coordinatingConjunction) {
        var joinedData = '';
        if (arrayData && arrayData.length > 0) {
            angular.forEach(arrayData, function (value, index) {
                if (index > 0) {
                    joinedData += separator;
                    if (serialComma === true &&
                        index === (arrayData.length - 1))
                        joinedData += coordinatingConjunction + ' ';
                }
                joinedData += value;
            });
        }
        return joinedData;
    };
});
