(function () {
    'use strict';

    angular.module('mso.directives').controller('UploadDocumentV2Ctrl', UploadDocumentCtrl);

    UploadDocumentCtrl.$inject = ['$modalInstance', 'Upload', 'referenceData', 'requirements', 'enums', '$filter',
        'uploadDocumentResource', '$exceptionHandler', '$sce', '$timeout', 'applicants', 'ReferenceDataService',
        '$rootScope', '$document'];

    function UploadDocumentCtrl($modalInstance, Upload, referenceData, requirements, enums, $filter,
                                uploadDocumentResource, $exceptionHandler, $sce, $timeout, applicants,
                                ReferenceDataService, $rootScope, $document) {
        var uploadDocuments = this;
        uploadDocuments.selectedRequirements = [];
        uploadDocuments.allRequirementsSelected = false;
        uploadDocuments.onFileSelect = onFileSelect;
        uploadDocuments.requirementLabelId = requirementLabelId;
        uploadDocuments.editDocumentName = editDocumentName;
        uploadDocuments.upload = upload;
        uploadDocuments.cancelEditName = cancelEditName;
        uploadDocuments.completeEditName = completeEditName;
        uploadDocuments.cancelUpload = cancelUpload;
        uploadDocuments.uploadInProgress = uploadInProgress;
        uploadDocuments.removeDocument = removeDocument;
        uploadDocuments.selectAllRequirements = selectAllRequirements;
        uploadDocuments.requirementSelected = requirementSelected;
        uploadDocuments.uploadedDocumentNames = uploadedDocumentNames;
        uploadDocuments.inputsDisabled = inputsDisabled;
        uploadDocuments.maxNumberOfFiles = maxNumberOfFiles;
        uploadDocuments.showValidation = false;
        uploadDocuments.previewSupported = previewSupported;
        uploadDocuments.togglePreview = togglePreview;
        uploadDocuments.isImage = isImage;

        uploadDocuments.closeDialog = closeDialog;
        uploadDocuments.documents = [];

        var caseRequirementType = {
            certificateOfTitle: 20,
            valuationReport: 56
        };
        var imagePreviewTypes = ['jpg', 'jpeg'];
        var nonImagePreviewTypes = ['pdf'];
        var chromeDesktop = false;
        var ua = navigator.userAgent;

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
            chromeDesktop = false;
        } else if (/Chrome/i.test(ua)){
            chromeDesktop = true;
        }

        $document.on("keydown", function(e) {
            var KEY_ESCAPE = 27;
            if (e.keyCode === KEY_ESCAPE) {
                deRegisterRouteChangeListener();
            }
        });

        // Listener to close the modal if user navigates away
        // Adding an event listener also returns a function to de-register the listener
        var deRegisterRouteChangeListener = $rootScope.$on('$routeChangeStart', function(){
            uploadDocuments.closeDialog();
        });

        activate();

        function activate() {
            uploadDocuments.vs = referenceData.valuesets;
            uploadDocuments.parameters = referenceData.parameters;
            uploadDocuments.messages = referenceData.messages;
            uploadDocuments.maxTotalSizeInBytes = uploadDocuments.parameters.p0076 * 1024;
            uploadDocuments.maxTotalSizeInMbtext = $filter('number')(uploadDocuments.parameters.p0076 / 1024, 2);
            uploadDocuments.validFileExtensions = uploadDocuments.vs.rdValidDocUploadExts.map(function (o) {
                return '.' + o.caption.toLowerCase();
            });
            uploadDocuments.validFileExtensionsJoined = $filter('msoJoin')(uploadDocuments.validFileExtensions,
                ', ', true, 'and');
            uploadDocuments.submitAttempted = false;
            uploadDocuments.requirementsObject = {selectedRequirements: []};

            uploadDocuments.requirements = angular.copy(requirements);

            angular.forEach(uploadDocuments.requirements, function (requirement) {
                var caption = requirement.name;
                switch (requirement.appliesTo) {
                    case enums.RDCaseRequirementAppliesTo.applicant:
                        caption += " for " + getApplicantName(requirement.applicantIndex);
                        break;
                    case enums.RDCaseRequirementAppliesTo.application:
                        caption += " relating to " +
                            ReferenceDataService.resolveValuesetValue(enums.RDCaseRequirementAppliesTo.application, 'rdCaseRequirementAppliesTo');
                        break;
                    case enums.RDCaseRequirementAppliesTo.all:
                        caption += " for " + requirement.value.applicantName + "." +
                            " relating to " +
                            ReferenceDataService.resolveValuesetValue(enums.RDCaseRequirementAppliesTo.application, 'rdCaseRequirementAppliesTo');
                        break;
                }
                requirement.caption = caption;
            });
        }

        function getApplicantName(index) {
            var applicant = applicants.filter(function (applicant) {
                return applicant.index === index;
            })[0];

            return applicant.forename + ' ' + applicant.surname;
        }

        function isImage(document) {
            var extension = document.name.split('.').pop().toLowerCase();
            return imagePreviewTypes.indexOf(extension) !== -1;
        }

        function togglePreview(document) {
            document.preview = !document.preview;
            if (document.preview &&
                document.fileDataUrl === undefined &&
                !isImage(document))
                readDocumentAsDataUrl(document);
        }

        function previewSupported(document) {
            if (document.$error !== undefined)
                return false;

            var extension = document.name.split('.').pop().toLowerCase();
            if (imagePreviewTypes.indexOf(extension) !== -1)
                return true;

            return chromeDesktop && nonImagePreviewTypes.indexOf(extension) !== -1;
        }

        function readDocumentAsDataUrl(document) {
            var reader = new FileReader();

            reader.onload = function(e) {
                $timeout(function () {
                    document.fileDataUrl = $sce.trustAsResourceUrl(reader.result);
                    document.previewLoaded = true;
                });
            };

            reader.readAsDataURL(document);
        }

        function inputsDisabled() {
            return uploadInProgress() || uploadDocuments.uploadComplete;
        }

        function uploadedDocumentNames() {
            var names = [];
            angular.forEach(uploadDocuments.documents, function(document) {
                if (document.filename !== undefined){
                    names.push(document.filename);
                } else {
                    names.push(document.name);
                }
            });
            return names;
        }

        function selectAllRequirements() {
            angular.forEach(uploadDocuments.requirements, function(requirement) {
                requirement.selected = uploadDocuments.allRequirementsSelected;
            });
            updateSelectedRequirements();
            validateRequirementsAndDocuments();
        }

        function requirementSelected() {
            updateSelectedRequirements();
            updateSelectAllRequirements();
            validateRequirementsAndDocuments();
        }

        function updateSelectedRequirements() {
            uploadDocuments.selectedRequirements =
                $filter('filter')(uploadDocuments.requirements, {'selected': true});
        }

        function updateSelectAllRequirements() {
            var allTrue = uploadDocuments.selectedRequirements.length === uploadDocuments.requirements.length;
            var allFalse = uploadDocuments.selectedRequirements.length === 0;

            uploadDocuments.allRequirementsSelected = !allTrue && !allFalse ?
                undefined : allTrue;
        }

        function removeDocument(document) {
            uploadDocuments.documents.splice(uploadDocuments.documents.indexOf(document), 1);
            // have to do this to trigger the validation in ng file upload
            uploadDocuments.documents = uploadDocuments.documents.splice(0);
            validateRequirementsAndDocuments();
        }

        function uploadInProgress() {
            return Upload.isUploadInProgress();
        }

        function cancelUpload() {
            if (Upload.isUploadInProgress()){
                uploadDocuments.uploadPromise.abort();
                uploadDocuments.uploadCancelled = true;
            }
        }

        function completeEditName(document) {
            if (document.newFilename !== undefined &&
                document.newFilename.length > 0)
                uploadDocuments.documents[uploadDocuments.documents.indexOf(document)] = Upload.rename(document, document.newFilename);
            document.filename = document.newFilename;
            document.nameEditing = false;
        }

        function editDocumentName(document) {
            if (document.filename === undefined)
                document.newFilename = document.name;
            document.nameEditing = true;
        }

        function cancelEditName(document) {
            document.filename = document.previousName;
            document.nameEditing = false;
        }

        function requirementLabelId(requirement) {
            return 'requirementType' + requirement.id + (requirement.applicantIndex !== undefined ? 'Applicant' + requirement.applicantIndex : '');
        }

        function closeDialog() {
            cancelUpload();
            deRegisterRouteChangeListener();
            if (uploadDocuments.uploadComplete){
                $modalInstance.close();
            } else {
                $modalInstance.dismiss();
            }
        }

        function onFileSelect(files) {
            angular.forEach(files, function(file) {
                uploadDocuments.documents.push(file);
            });
        }

        function maxNumberOfFiles() {
            if (uploadDocuments.selectedRequirements.length > 1)
                return 1;

            if (uploadDocuments.selectedRequirements.length === 0)
                return uploadDocuments.parameters.p6372;

            if (uploadDocuments.selectedRequirements[0].type === caseRequirementType.certificateOfTitle ||
                uploadDocuments.selectedRequirements[0].type === caseRequirementType.valuationReport)
                return 1;

            return parseInt(uploadDocuments.parameters.p6372) - parseInt(uploadDocuments.selectedRequirements[0].attachedDocumentsTotal);
        }

        function validateRequirementsAndDocuments() {
            uploadDocuments.requirementsInvalid = false;
            uploadDocuments.documentsInvalid = false;
            uploadDocuments.multiRequirementMultiDocumentMsg = false;
            uploadDocuments.maxNumberOfFilesExceeded = false;
            if (!validateRequirements()){
                return false;
            }

            if (uploadDocuments.selectedRequirements.length > 1 &&
                uploadDocuments.documents.length > 1){
                uploadDocuments.requirementsInvalid = true;
                uploadDocuments.documentsInvalid = true;
                uploadDocuments.multiRequirementMultiDocumentMsg = true;
                return false;
            }

            if (uploadDocuments.documents.length > maxNumberOfFiles()){
                uploadDocuments.maxNumberOfFilesExceeded = true;
                uploadDocuments.documentsInvalid = true;
                return false;
            }

            return true;
        }

        function validateRequirements() {
            var valid = true;
            if (uploadDocuments.selectedRequirements.length === 0)
                valid = false;
            uploadDocuments.requirementsInvalid = !valid;
            return valid;
        }

        function upload() {
            var invalidFiles = uploadDocuments.documents.some(function (document) {
                return document.$error !== undefined;
            });
            if (invalidFiles ||
                !validateRequirementsAndDocuments() ||
                uploadDocuments.documents.length === 0){
                uploadDocuments.showValidation = true;
                return;
            }
            angular.forEach(uploadDocuments.documents, function (file) {
                if (file.nameEditing === true)
                    completeEditName(file);
            });
            uploadDocuments.showValidation = false;

            var associatedRequirements = uploadDocuments.selectedRequirements.map(function (requirement) {
                return { id: requirement.id, type: requirement.type };
            });

            uploadDocuments.uploadProgress = 0;
            uploadDocuments.uploadErrorOccurred = false;
            uploadDocuments.uploadCancelled = false;

            uploadDocuments.uploadPromise = Upload.upload({
                url: uploadDocumentResource.href,
                method: uploadDocumentResource.method,
                data: {
                    files: uploadDocuments.documents,
                    associatedRequirements: Upload.json(associatedRequirements)
                },
                hideLoading: true
            });

            uploadDocuments.uploadPromise.then(function () {
                uploadDocuments.uploadComplete = true;
            },
            function (error) {
                if (uploadDocuments.uploadCancelled)
                    return;

                uploadDocuments.uploadErrorOccurred = true;
                uploadDocuments.showValidation = true;

                $exceptionHandler(error);
            },
            function (event) {
                uploadDocuments.uploadProgress = parseInt(100.0 * event.loaded / event.total);
            });
        }
    }
})();