angular.module('mso.services').factory('AddressSearchService', ['$resource', '$q', 'Config', function ($resource, $q, Config) {
    var AddressFindApi = $resource(Config.addressesApiHref + '/searchengine/Find', {});
    var AddressFormatApi = $resource(Config.addressesApiHref + '/searchengine/Format', {});

    return {
        searchAddresses: function (postcode, buildingNameOrNumber) {
            var deferred = $q.defer();
            var queryParams = {postcode: postcode};
            if (buildingNameOrNumber != null && buildingNameOrNumber != '') {
                queryParams.buildingNameOrNumber = buildingNameOrNumber;
            }

            AddressFindApi.query(queryParams).$promise.then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    if (error.status === 404) {
                        deferred.resolve([]);
                    } else {
                        deferred.reject(error);
                    }
                });
            return deferred.promise;
        },

        formatAddress: function (addressId) {
            var deferred = $q.defer();
            AddressFormatApi.get({addressId: addressId}).$promise.then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }
    };
}]);