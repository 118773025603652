angular.module('mso.services').factory('SalesProcessManagerService', ['$resource', '$q', 'Config', '$http', function ($resource, $q, Config, $http) {

    var spmCaseTransitionApi = $resource('Spm/api/msov1/cases/:caseId/transitions/v2', null);
    var spmCaseStageApi = $resource('Spm/api/msov1/cases/:caseId/stage', null);
    var spmDeclineAppealsApi = $resource('Spm/api/msov1/cases/:caseId/transitions/decline-appeal-in-progress', null);

    return{
        getCaseTransition: function(caseId, fromModule, fromVersion, includeNavigation){
            return spmCaseTransitionApi.get({caseId: caseId, fromModule: fromModule, fromVersion: fromVersion, includeNavigation: includeNavigation}).$promise;
        },
        resolveCaseTransition: function(url){
            return $http.post(url);
        },
        getCaseStage: function(caseId){
            return spmCaseStageApi.get({caseId: caseId}).$promise;
        },
        getDeclineAppealStatus: function(caseId){
            return spmDeclineAppealsApi.get({caseId: caseId}).$promise;
        }
    }
}]);
