// Extends the angular togglable drop down with the following usability extensions:
// - Navigate the list using up / down arrow keys
// - Close the list using tab or escape key
// Use in html by applying to the <div class="dropdown" ...> element ,
// with mso-dropdown-toggle-extension="{{dropdownId}}" where 'dropdownId' is an identifier for the togglable drop down

angular.module('mso.directives').directive('msoDropdownToggleExtension', ['$timeout', function($timeout) {

    return {
        link: function( scope, elem, attrs ) {
            elem.bind('keydown keypress', function( event ) {
                var KEY_UP = 38;
                var KEY_DOWN = 40;
                var KEY_ESCAPE = 27;
                var KEY_TAB = 9;

                var keycode = event.keyCode || event.which;
                var dropdownId = attrs.msoDropdownToggleExtension;
                var toggleElement = angular.element(document.querySelector('#' + dropdownId + 'toggle'));

                if (keycode === KEY_TAB || keycode === KEY_ESCAPE) {
                    // close menu
                    closeMenu();
                }

                if (keycode === KEY_UP || keycode === KEY_DOWN) {
                    event.preventDefault();
                    if (keycode === KEY_DOWN && toggleElement && toggleElement.parent() && !toggleElement.parent().hasClass("open")) {
                        // Open the menu on key-down
                        $timeout(function(){
                            toggleElement.triggerHandler('click');
                        },0);

                    }
                    // default the target element to the first item in the list
                    var targetElement = document.getElementById(dropdownId + '0');
                    
                    // if we're already in the list, work out which item to focus next
                    var activeElementId = document.activeElement.id;
                    if (activeElementId.indexOf(dropdownId) > -1) {
                        // Move to next item
                        var index = parseInt(activeElementId.substring(dropdownId.length));
                        if (!isNaN(index)) {
                            if (keycode === KEY_UP) {
                                if (index === 0) {
                                    closeMenu();
                                    return;
                                } else {
                                    index--;
                                }
                            } else if (keycode === KEY_DOWN) {
                                index++;
                            }
                            targetElement = document.getElementById(dropdownId + index);
                        }
                    }
                    if(targetElement) {
                        $timeout(function(){
                            targetElement.focus();
                        },0);
                    }
                }

                function closeMenu() {
                    if (toggleElement && toggleElement.parent() && toggleElement.parent().hasClass("open")) {
                        $timeout(function(){
                            toggleElement.triggerHandler('click');
                        },0);
                        $timeout(function(){
                            document.getElementById(dropdownId + 'toggle').focus();
                        },0);
                    }
                }
            });
        }
    };
}]);