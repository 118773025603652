(function () {
    'use strict';

    angular.module('mso.services').factory('SpmV2Service', SpmService);

    SpmService.$inject = ['CoreConfig', '$resource', '$http'];

    function SpmService(coreConfig, $resource, $http) {
        var createJourneyResource = $resource(coreConfig.spmApiBaseHref + 'create-journey/:name/:caseId',
            {'caseId':'@caseId', 'name':'@name'});

        var getJourneyResource = $resource(coreConfig.spmApiBaseHref + 'journey/:caseId',
            { 'caseId': '@caseId'});

        return {
            createJourney: createJourney,
            prepareInitialisationPayload: prepareInitialisationPayload,
            getJourneyForCase:getJourneyForCase,
            fireTransitionURL: fireTransitionURL,
            doesTransitionExist: doesTransitionExist,
            temporaryTransition: temporaryTransition,
            extractTransitions: extractTransitions,
            getDipTransitionNames : getDipTransitionNames
        };

        function createJourney(initialisationPayload) {
            return createJourneyResource.save(initialisationPayload);
        }

        function prepareInitialisationPayload(caseId, friendlyId, createCaseData){
            return {
                caseId: caseId,
                name: coreConfig.channel.toLowerCase(),
                metadata: {
                    friendlyId: friendlyId,
                    channel: coreConfig.channel,
                    brand: coreConfig.brand,
                    Id: caseId
                },
                applicants: createCaseData.applicants,
                intermediary: createCaseData.intermediary,
                firm: createCaseData.firm,
                loanRequirement: createCaseData.loanRequirement
            };
        }

        //Resolution of the journey transitions should be a UI concern. But whilst the buttons are still defined by old
        //SPM do all this under the covers.
        function temporaryTransition (caseId, targetModule){
            return getJourneyResource.get({caseId:caseId}).$promise.then(function(journey){
                var triggers = journey.triggers;

                var filteredTriggers = triggers.filter(function(trigger){
                    return trigger.name.toLowerCase() === targetModule.toLowerCase();
                });

                if(filteredTriggers.length > 0){
                    return fireTransitionURL(filteredTriggers[0]);
                }

                return undefined;
            });
        }

        function getJourneyForCase(caseId){
            return getJourneyResource.get({caseId:caseId}).$promise.then(function(journey){

                return journey;
            });
        }

        function fireTransitionURL(transition){
            return $http.post(transition.url, {});
        }

        function doesTransitionExist(transitions, displayPriority, transitionName) {
            var transitionExtracted = transitions.some(function (transition) {
                return transition.displayPriority.toLowerCase() === displayPriority.toLowerCase() &&
                transition.name.toLowerCase() === transitionName.toLowerCase()
            });

            return transitionExtracted;
        };

        function extractTransitions(transitions, transitionNames) {

            if(transitionNames && transitionNames.length > 0) {
                transitionNames.map(function (transitionName) {
                    return transitionName.toLowerCase();
                });
            }

            var transitionsExtracted = transitions.filter(function (transition) {
                return transitionNames.indexOf(transition.name.toLowerCase()) != -1;
            });

            return transitionsExtracted;
        };

        function getDipTransitionNames(){
            return ["backtodip", "dip", "reenterdip", "remodeldip", "resumedip"];
        };
    }
})();
