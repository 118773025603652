angular.module('mso.directives')
    .directive('msoAddressDisplay', function () {
        return {
            restrict: 'EA',
            controller: 'AddressDisplayController',
            template: require('/app/template/mso-address-display.html').default,
            scope :{
                addressDisplayViewModel:'=displayViewModel'
            },
            link: function (scope, el, attr) {


            },
            replace: true
        };
    })
    .controller('AddressDisplayController', ['$scope'
        , function ($scope) {

            $scope.addressDisplayTypes = { "uk" : "1",
                "bfpo" : "2",
                "overseas" : "3"};








        }]);
